import { create } from "zustand";
import { examResults } from "../types/search";

interface ModalStore {
    highlightRow: string;
    setHighlightRow: (source: string) => void;
    showCreateNewProjectModal: boolean;
    setShowCreateNewProjectModal: (show: boolean) => void;
    showExpandedThumbnailModal: boolean;
    setShowExpandedThumbnailModal: (show: boolean) => void;
    expandedThumbnailModalSource: string;
    setExpandedThumbnailModalSource: (source: string) => void;
    expandedThumbnailFiletype: 'video' | 'image';
    setExpandedThumbnailFiletype: (filetype: 'video' | 'image') => void;
    showOrderConfirmationModal: boolean;
    setShowOrderConfirmationModal: (show: boolean) => void;
    showWipeConfirmationModal: boolean,
    setShowWipeConfirmationModal: (show: boolean) => void;
    showPatientStudiesModal: boolean;
    setShowPatientStudiesModal: (show: boolean) => void;
    patientStudies: any[];
    setPatientStudies: (patientStudies: any) => void;
    showLoadingPatientStudiesModal: boolean;
    setShowLoadingPatientStudiesModal: (show: boolean) => void;
    showEditProjectNameModal: boolean;
    setShowEditProjectNameModal: (show: boolean) => void;
    showDeleteProjectModal: boolean;
    setShowDeleteProjectModal: (show: boolean) => void;
    patientExamsResults: examResults;
    setPatientExamsResults: (patientExamsResults: examResults)=>void;
    showCustomModal: boolean;
    setShowCustomModal: (show: boolean) => void;
}

export const useModalStore = create<ModalStore>()((set) => ({
    highlightRow: "",
    setHighlightRow: (row: string) => set({highlightRow: row}),
    showCreateNewProjectModal: false,
    setShowCreateNewProjectModal: (show: boolean) => set({ showCreateNewProjectModal: show }),
    showExpandedThumbnailModal: false,
    setShowExpandedThumbnailModal: (show: boolean) => set({ showExpandedThumbnailModal: show }),
    expandedThumbnailModalSource: "",
    setExpandedThumbnailModalSource: (source: string) => set({ expandedThumbnailModalSource: source }),
    expandedThumbnailFiletype: 'video',
    setExpandedThumbnailFiletype: (filetype: 'video' | 'image') => set({ expandedThumbnailFiletype: filetype }),
    showOrderConfirmationModal: false,
    setShowOrderConfirmationModal: (show: boolean) => set({ showOrderConfirmationModal: show }),
    showWipeConfirmationModal: false,
    setShowWipeConfirmationModal: (show: boolean) => set({ showWipeConfirmationModal: show }),
    showPatientStudiesModal: false,
    setShowPatientStudiesModal: (show: boolean) => set({ showPatientStudiesModal: show }),
    showLoadingPatientStudiesModal: false,
    setShowLoadingPatientStudiesModal: (show: boolean) => set({ showLoadingPatientStudiesModal: show }),
    patientStudies: [],
    setPatientStudies: (patientStudies: any) => set((state) => {
        // get all dates from patient studies into an array
        if (patientStudies.length > 0) {
            // Order Patient Studies by date from oldest to newest
            patientStudies.sort((a: any, b: any) => {
                const dateA = new Date(a.study_metadata.StudyDate);
                const dateB = new Date(b.study_metadata.StudyDate);
                return dateA.getTime() - dateB.getTime();
            })
            return { patientStudies: patientStudies }
        } else {
            return { patientStudies: [] }
        }
    }),
    showTermsOfServiceModal: true,
    showEditProjectNameModal: false,
    setShowEditProjectNameModal: (show: boolean) => set({ showEditProjectNameModal: show }),
    showDeleteProjectModal:false,
    setShowDeleteProjectModal: (show: boolean) => set({ showDeleteProjectModal: show }),
    patientExamsResults:{
        exam1_row_ids:[],
        exam2_row_ids:[],
    },
    setPatientExamsResults : (patientExamsResults: examResults) => set({ patientExamsResults }),
    showCustomModal: false,
    setShowCustomModal: (show: boolean) => set({ showCustomModal: show }),
}))
