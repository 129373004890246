import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useFilterStore } from '../../../store/filterStore'
import { usePatientStore } from '../../../store/patientStore'
import { useFilterDropDownStore } from '../../../store/filterDropDownStore'
import {crossDataFiltering} from '../../../utils/cross_filtering_in_dropdowns'
import { useSearchStore } from '../../../store/searchStore'
import { baseDropDowns, alldropdowns } from '../../filterControls/dropdownList'

type ModalityOption = {
  value: string;
  label: string;
  checked: boolean;
  count_of_field?: number; // Optional because it's not initially present
}

type ModalityFilterProps = {
    id?: number;
}

export default function ModalityFilter({id} : ModalityFilterProps) {
    const modalitySelections = useFilterStore(state => state.modalitySelections);
    const setModalitySelections = useFilterStore(state => state.setModalitySelections);
    const setPatientModalitySelections = usePatientStore(state => state.setModalitySelections);
    const clearButtonClicked = useFilterDropDownStore(state => state.clearButtonClicked);
    const [isOpen, setIsOpen] = useState(false);
    const patientFilters = usePatientStore(state => state.filters);
    const { crossFilterObjectFiltering } = crossDataFiltering();
    const filters = useFilterStore(state => state.filters);
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    const crossFilterSelection = useFilterDropDownStore(
      (state) => state.crossFilterSelection
    );
    const setCrossFilterSelection = useFilterDropDownStore(
      (state) => state.setCrossFilterSelection
    );
    const setFilterDropdowns = useFilterDropDownStore(
      (state) => state.setFilterDropdowns
    );
    const tempCrossFilterData = crossFilterSelection;
    const filterOptions = [
      { value: 'MR', label: 'Magnetic Resonance', checked: true },
      { value: 'CT', label: 'Computed Tomography', checked: true },
      { value: 'CR', label: 'Computed Radiography', checked: true },
      { value: 'DX', label: 'Digital X-Ray', checked: true },
      { value: 'DR', label: 'Digital Radiography', checked: true },
      { value: 'US', label: 'Ultrasound', checked: true },
      { value: 'MG', label: 'Mammography', checked: true },
      { value: 'XA', label: 'X-Ray Angiography', checked: true },
      { value: 'NM', label: 'Nuclear Medicine', checked: true },
      { value: 'PT', label: 'PET', checked: true }, 
      { value: "IVUS", label: "Intravascular Ultrasound", checked: true},
      { value: "DG", label: " Diaphanography", checked: true},
      { value: "DO", label: "DEXA", checked: true},
      { value: "RF", label: "Radio Fluoroscopy", checked: true},
      { value: "ECG", label: "Electrocardiography", checked: true},
      { value: "RG", label: "Radiographic imaging", checked: true},
      { value: "IO", label: "Intra-Oral Radiography", checked: true},
      { value: "OT", label: "Other", checked: true},
      { value: "AU", label: "Audio ECG", checked: true},
      { value: "KO", label: "Key Object Selection", checked: true},
    ];
    const listData = useFilterDropDownStore(state => state.listAllData.find(filter=> filter.groupingField === "modality")?.listData)??[];
    const [ modalityOptionState, setModalityOptionState ] = useState<ModalityOption[]>(filterOptions);
    const [ modalityOptionStateInit, setModalityOptionStateInit ] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [modalityWithCount, setModalityWithCount] = useState<ModalityOption[]>(filterOptions);

    const isPatientFilterActive = id !== null && id !== undefined;
    const isModalityFullySelected = isPatientFilterActive
      ? patientFilters[id].filters.modality.length === modalityWithCount.length
      : modalitySelections.length === modalityWithCount.length;

    const isAnyModalitySelected = isPatientFilterActive
      ? patientFilters[id].filters.modality.length > 0
      : modalitySelections.length > 0;

    const textClass = isModalityFullySelected
      ? "text-gray-400"
      : isAnyModalitySelected
      ? "text-gray-900"
      : "text-gray-400";

    useEffect(()=>{
      const newModalityOptionState = [...modalityOptionState];
        const updatedFilterOptions = newModalityOptionState.map(option => {
          const matchingData: any = listData.find((data: any) => data.grouping_field === option.value);
            return {
              ...option,
              count_of_field: matchingData ? matchingData.count_of_field : 0
            };
        
        }).sort((a,b)=>b.count_of_field - a.count_of_field);
        setModalityWithCount(updatedFilterOptions);
    },[listData,modalityOptionState])
  
    useEffect(() => {
      setModalityOptionState(filterOptions)
  }, [clearButtonClicked]);

    useEffect(() => {
      function handleMouseDown(event: MouseEvent) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleMouseDown);

      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      }
    })

    useEffect(() => {
      (id != null && id != undefined) ? setPatientModalitySelections(modalityOptionState.filter((option) => option.checked).map((option) => option.value), id) : setModalitySelections(modalityOptionState.filter((option) => option.checked).map((option) => option.value));
    }, [modalityOptionState])

    useEffect(() => {
      if (modalityOptionStateInit) {
        return;
      }
      setModalityOptionStateInit(true);
      (id != null && id != undefined) ? setModalityOptionState(modalityOptionState.map((option) => {
        option.checked = patientFilters[id].filters.modality.includes(option.value);
        return option;
      })) : setModalityOptionState(modalityOptionState.map((option) => {
        option.checked = modalitySelections.includes(option.value);
        return option;
      }));
    }, [modalitySelections, patientFilters[0].filters.modality, patientFilters[1].filters.modality])
  

    function toggleOption(option: ModalityOption) {
      const newModalityOptionState = [...modalityOptionState];
      const index = newModalityOptionState.findIndex((el) => el.value === option.value);
      newModalityOptionState[index].checked = newModalityOptionState[index].checked ? false : true;
      setModalityOptionState(newModalityOptionState);
    }
  
    const handleSelectAll = () => {
      const newModalityOptionState = [...modalityOptionState];
      newModalityOptionState.forEach((option) => option.checked = true);
      setModalityOptionState(newModalityOptionState);
    }

    const handleUnselectAll = () => {
      const newModalityOptionState = [...modalityOptionState];
      newModalityOptionState.forEach((option) => option.checked = false);
      setModalityOptionState(newModalityOptionState);
    }

  useEffect(() => {
    const selectedValues = modalityOptionState
      .filter((option) => option.checked)
      .map((option) => option.value);
    if (selectedValues.length > 0) {
      setFilterDropdowns(alldropdowns.filter((value) => value !== "modality"));
      tempCrossFilterData["modality"] = selectedValues;
      setCrossFilterSelection(tempCrossFilterData);
    } else {
      if (baseDropDowns.includes("modality")) {
        const updatedCrossFilter = crossFilterObjectFiltering("modality");
        setFilterDropdowns(alldropdowns.filter((value) => value !== "modality"));
        setCrossFilterSelection(updatedCrossFilter);
      }
    }
  }, [modalityOptionState]);

  return (
    <div className='flex flex-row justify-between py-4'>
        <div className='w-full' ref={dropdownRef}>
        <Listbox
          as="div"
          className="space-y-1 w-full"
          disabled={filters.cross_filter  ? isRetrievingStudies : false}
        >
          {() => (
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    className="text-sm cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:ring-inset focus:border focus:ring-blue-600 focus:ring-1 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className={`block truncate ${textClass}`}>
                      {/* Multi-select here */}
                      {
                        (id != null && id != undefined) ?  
                          (patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length === filterOptions.length  ? 
                              "Select Modality" : (
                                patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length > 0 ? patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").join(", ") : "Select modalities"
                              )
                          ) : 
                          (
                            modalitySelections.length === modalityWithCount.length  ? 
                              "Select Modality" : (
                                modalitySelections.length > 0 ? modalitySelections.join(", ") : "Select Modality"
                              )
                          )}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                >
                  <Listbox.Options
                    static
                    className="text-base max-h-60 rounded-md py-1 leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                  >
                    <Listbox.Option value='all'>
                    {({ active }) => (
                        <div
                            className={`${
                                active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900"
                            } cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-4 flex flex-row items-center justify-start`}
                            onClick={() =>
                                ( (id != null && id != undefined) ? patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length : modalitySelections.length) === filterOptions.length
                                    ? handleUnselectAll()
                                    : handleSelectAll()
                            }
                        >
                            <input
                                type="checkbox"
                                id={"selectAll" + id}
                                checked={((id != null && id != undefined) ? patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length : modalitySelections.length)  === filterOptions.length}
                                className="h-4 w-4 rounded-sm mr-2"
                                readOnly
                            />
                            <span className="block truncate">
                                {((id != null && id != undefined) ? patientFilters[id].filters.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length : modalitySelections.length) === filterOptions.length ? 'Unselect All' : 'Select All'}
                            </span>
                        </div>
                    )}
                </Listbox.Option>
                    {modalityWithCount.map((modality, idx) => {
                      return (
                        <Listbox.Option key={idx + "" + id} value={modality.value}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white bg-blue-600"
                                  : "text-gray-900"
                              } cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-4 flex flex-row items-center justify-start`}
                              onClick={() => toggleOption(modality)}
                            >
                                <input
                                    id={`custom-checkbox-${idx + "" + id}`}
                                    name={modality.value}
                                    type="checkbox"
                                    checked={(id != null && id != undefined) ? patientFilters[id].filters.modality.includes(modality.value) : modalitySelections.includes(modality.value)}
                                    className="h-4 w-4 rounded-sm mr-2"
                                    readOnly
                                />
                                <div
                                  className="flex flex-row w-full justify-between items-center"
                                  style={{
                                    width: "280px",
                                    maxWidth: "280px",
                                    minWidth: "280px",
                                  }}
                                >
                                  <span
                                      className={`${
                                      modality.checked ? "font-semibold" : "font-normal"
                                      } block truncate`}
                                  >
                                      {modality.label}
                                  </span>
                                  <span className="text-right ml-3 text-sm">
                                        {modality?.count_of_field && modality?.count_of_field > 1000 ? (
                                          !isNaN(modality?.count_of_field) &&
                                          (modality?.count_of_field / 1000).toFixed(
                                            1
                                          ) + "k"
                                        ) : (
                                          modality.count_of_field
                                        )}
                                  </span>
                                </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
          )}
        </Listbox>
        </div>
    </div>
  );
}
