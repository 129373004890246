export const manufacturerOptions = [
  { value: ["Philips", "Volcano"], label: 'Philips', checked: true },
  { value: ["Siemens", "ACUSON", "Varian", "InfiMed"], label: 'Siemens', checked: true },
  { value: ["Hitachi"], label: 'Hitachi', checked: true },
  { value: ["HOLOGIC", "R2 Technology", "FAXITRON"], label: 'Hologic', checked: true },
  { value: ["GE", "G.E.", "OEC Medical Systems"], label: 'GE', checked: true },
  { value: ["FUJI"], label: 'Fuji', checked: true },
  { value: ["Carestream"], label: 'Carestream', checked: true },
  { value: ["Agfa"], label: 'Agfa', checked: true },
  { value: ["KODAK"], label: 'Kodak', checked: true },
  { value: ["TOSHIBA"], label: 'Toshiba', checked: true },
  { value: ["Pixeon"], label: 'Pixeon', checked: true },
  { value: ["Canon", "Vital"], label: 'Canon', checked: true },
  { value: ["Konica", "Konica Minolta"], label: 'Konica Minolta', checked: true },
  { value: ["ESAOTE"], label: 'Esaote', checked: true },
  { value: ["PNMS"], label: 'PNMS', checked: true },
  { value: ["DRTECH"], label: 'DRTECH', checked: true },
  { value: ["SOREDEX"], label: 'SOREDEX', checked: true },
  { value: ["Instrumentarium dental", "Instrumentarium"], label: 'Instrumentarium Dental', checked: true },
  { value: ["Imex"], label: 'Imex', checked: true },
  { value: ["iRay Technology Company Limited"], label: 'iRay', checked: true },
  { value: ["LOTUS"], label: 'Lotus', checked: true },
  { value: ["VIEWORKS"], label: 'VIEWORKS', checked: true },
  { value: ["Imaging Dynamics Company Ltd."], label: 'Imaging Dynamics Company Ltd.', checked: true },
  { value: ["IIS"], label: 'IIS', checked: true },
  // { value: ["Vatech Company Limited"], label: 'Vatect', checked: true },
  { value: ["Oehm und Rehbein GmbH"], label: 'Oehm und Rehbein GmbH', checked: true },
  { value: ["Lonwin Medical"], label: 'Lonwin Medical', checked: true },
  { value: ["Marconi"], label: 'Marconi', checked: true },
  { value: ["Planmeca"], label: 'Planmeca', checked: true },
  { value: ["Dabi Atlante"], label: 'Dabi Atlante', checked: true },
  { value: ["DicomVCL"], label: 'DicomVCL', checked: true },
  { value: ["Sirona"], label: 'Sirona', checked: true },
  { value: ["iCRco"], label: 'iCRco', checked: true },
  { value: ["ONI"], label: 'ONI', checked: true },
  { value: ["ISCHEMAVIEW"], label: 'IschemaView', checked: true },
  { value: ["NMS"], label: 'NMS', checked: true },
  { value: ["Imaging Sciences International"], label: 'Imaging Sciences International', checked: true },
  { value: ["Vatech Company Limited"], label: 'VATECH', checked: true },
  { value: ["J.Morita.Mfg.Corp."], label: 'J.Morita.Mfg.Corp.', checked: true },
  { value: ["Neusoft Medical Systems", "NMS"], label: 'Neusoft Medical Systems', checked: true },
  { value: ["Ramsoft"], label: 'Ramsoft', checked: true },
  { value: ["Shimadzu"], label: 'Shimadzu', checked: true},
  { value: ["MXR"], label: 'MXR', checked: true},
  { value: ["Mediso"], label: 'Mediso', checked: true},
  { value: ["ADAC"], label: 'ADAC', checked: true},
  { value: ["Spectrum-Dynamics"], label: 'Spectrum-Dynamics', checked: true},
  { value: ["DDD"], label: 'DDD Diagnostics', checked: true},
  { value: ["Fischer"], label: 'Fischer Imaging', checked: true},
  { value: ["Bayer"], label: 'Bayer', checked: true},
  { value: ["Mevis"], label: 'Mevis', checked: true},
  { value: ["Bioptics"], label: 'Bioptics', checked: true},
  { value: ["LORAD"], label: 'LORAD', checked: true},
  { value: ["VMI"], label: 'VMI', checked: true},
  { value: ["Ziehm"], label: 'Ziehm', checked: true},
  { value: ["Samsung", "Neurologica", "SONOACE"], label: 'Samsung', checked: true},
  { value: ["Careview"], label: 'Careview', checked: true},
  { value: ["CMT"], label: 'CMT Medical', checked: true},
  { value: ["Blackford"], label: 'Blackford Analysis', checked: true},
  { value: ["Sonoscape"], label: 'Sonoscape', checked: true},
  { value: ["Sonosite"], label: 'Sonosite', checked: true},
  { value: ["Segami"], label: 'Segami', checked: true},
  { value: ["Orthoscan"], label: 'Orthoscan Inc.', checked: true},
  { value: ["BRACCO"], label: 'Bracco', checked: true},
  { value: ["RF System Lab"], label: 'Viewtech', checked: true},
  { value: ["Trophy"], label: 'Trophy', checked: true},
  { value: ["Osteosys"], label: 'Osteosys', checked: true},
  { value: ["Elscint"], label: 'Elscint', checked: true},
  { value: ["Sedecal"], label: 'Sedecal', checked: true},
  { value: ["Kretztechnik"], label: 'Kretztechnik', checked: true},
  { value: ["ATL"], label: 'ATL', checked: true},
  { value: ["Invivo"], label: 'Invivo', checked: true},
  { value: ["Capintec"], label: 'Captintec', checked: true},
  { value: ["Matakina"], label: 'Matakina', checked: true},
  { value: ["BK Medical"], label: 'BK Medical', checked: true},
  { value: ["Mobius", "Stryker"], label: 'Stryker', checked: true},
  { value: ["iCAD"], label: 'iCAD', checked: true},
  { value: ["Perceptive Software"], label: 'Perceptive Software', checked: true},
  { value: ["PaloDEx Group"], label: 'PaloDEx Group', checked: true},
  { value: ["Digirad"], label: 'Digirad', checked: true},
  { value: ["Orex"], label: 'OREX', checked: true},
  { value: ["Terarecon"], label: 'TERARECON', checked: true},
  { value: ["Aurora Imaging Technology"], label: 'Aurora Imaging Technology', checked: true},
  { value: ["WDM"], label: 'WDM', checked: true},
  { value: ["DEL MEDICAL"], label: 'DEL MEDICAL', checked: true},
  { value: ["Zonare"], label: 'Zonare', checked: true},
  { value: ["Fonar"], label: 'Fonar', checked: true},
  { value: ["Medlink"], label: 'Medlink Imaging', checked: true},
  { value: ["Varex"], label: 'Varex', checked: true},
  { value: ["iRay"], label: 'iRay', checked: true},
  { value: ["North American Imaging"], label: 'North American Imaging', checked: true},
  { value: [" NMD"], label: 'NMD', checked: true},
  { value: ["Lexmark"], label: 'Lexmark', checked: true},
  { value: ["BARD"], label: 'BARD', checked: true},
  { value: ["AS Software"], label: 'AS Software', checked: true},
  { value: ["E-COM"], label: 'E-COM', checked: true}
];

export const manufacturerSelectionsDefault = manufacturerOptions.reduce((result, option) => {
  return result.concat(option.value);
}, [] as string[]);

export const manufacturerSelectionsDefaultLength = manufacturerSelectionsDefault.length;