import { Switch } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import cn from "classnames";
import { useFilterStore } from "../../../store/filterStore";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export default function CrossFilter() {
  // Filter Store
  const crossFilterOnly = useFilterStore((state) => state.crossFilterOnly);
  const setCrossFilterOnly = useFilterStore(
    (state) => state.setCrossFilterOnly
  );

  const tooltipContent = (
    <div className="w-80">
      <span>
        Refreshes filter numbers dynamically with each search modification.
      </span>
    </div>
  );

  return (
    <div className="flex flex-row justify-between py-4">
      <div className="flex items-center gap-1">
      <p className=" flex items-center text-base ">Cross Filter</p>
      <span className=" flex justify-end items-start ">
          <Tippy
            content={tooltipContent}
            arrow={true}
            delay={500}
            interactive={true}
            placement="top"
            maxWidth="none"
          >
            <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
              <InformationCircleIcon className="h-5 w-5 text-blue-600 cursor-pointer" />
            </svg>
          </Tippy>
        </span>
        </div>
      <div className="flex  items-center">
        <Switch
          checked={crossFilterOnly}
          onChange={() => setCrossFilterOnly(!crossFilterOnly)}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md bg-slate-100"
          />
          <span
            aria-hidden="true"
            className={cn(
              crossFilterOnly ? "bg-blue-600" : "bg-gray-200",
              "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
            )}
          />
          <span
            aria-hidden="true"
            className={cn(
              crossFilterOnly ? "translate-x-5" : "translate-x-0",
              "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
