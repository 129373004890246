import { useEffect, useState } from "react";
import { useAuthStore } from "../../../store/authStore";
import { createSaveSearchName } from "../../../utils/request_save_searches";
import { usePatientStore } from "../../../store/patientStore";
import { useSearchTypeStore } from "../../../store/searchTypeStore";
import { useSearchStore } from "../../../store/searchStore";
import { useFilterStore } from "../../../store/filterStore";
import { useAdvancedFilterStore } from "../../../store/advancedFiltersStore";
import { formatSearchQueryForLucene } from "../../../utils/lucene_helpers";
import { usePatient1AdvancedFiltersStore } from "../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../store/patient2AdvancedFiltersStore";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { WarningMessage } from "../../../types/warningMessage";
import { savedSearchNameLengthExceedingMessage } from "../../common/Constants";

interface CreateSearchModalProps {
    setShowNewSearchModal: (value: boolean) => void;
}
export default function CreateNewSearch(props: Readonly<CreateSearchModalProps>) {
    const {setShowNewSearchModal}= props;
    // Auth State
    const token = useAuthStore(state => state.token);
    // Patient Sate
    const patientFilters = usePatientStore(state => state.filters);
    const timeBetween = usePatientStore(state => state.timeBetween);
    //search type state
    const searchType = useSearchTypeStore(state => state.searchType);
    //search store
    const searchRequest = useSearchStore(state => state.searchRequest);
    // Filter state
    const searchTerms = useFilterStore(state => state.searchTerms);
    //advanced filter state
    const filterGroups = useAdvancedFilterStore(state => state.filterGroups);
    // Patient 1 Advanced filter state
    const advancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const advancedFilters1 = usePatient1AdvancedFiltersStore(state => state.advancedFilters);
    const filterGroups1 = usePatient1AdvancedFiltersStore(state => state.filterGroups);
    // Patient 2 Advanced filter state
    const advancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const advancedFilters2 = usePatient2AdvancedFiltersStore(state => state.advancedFilters);
    const filterGroups2 = usePatient2AdvancedFiltersStore(state => state.filterGroups);
    //local state
    const [searchName, setSearchName] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [warningMessage, setWarningMessage] = useState<WarningMessage>({ show: false, warningMessage: '' });

    const handleCreateNewSearch = async () => {
        if(searchName.length>80){
            setWarningMessage(savedSearchNameLengthExceedingMessage);
            return;
        }
      if (token) {
            try {
                setIsLoading(true);
                let searchParams = {};
                let path;
                let tempFilters = patientFilters;
                tempFilters[0].search_summary = "";
                tempFilters[1].search_summary = "";
                tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms);
                tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms);
                if (advancedFiltersEnabled1) {
                    tempFilters[0].advancedFilters = advancedFilters1;
                }
                if (advancedFiltersEnabled2) {
                    tempFilters[1].advancedFilters = advancedFilters2;
                }
                if (searchType === "study") {
                    searchParams = {
                        search_request: searchRequest,
                        search_terms: searchTerms,
                        ...(filterGroups.length > 0 && {
                            filter_groups: filterGroups,
                        }),
                        
                    }
                    path = "studies"
                } else {
                    path = "longitudinal";
                    searchParams = {
                        search_request: tempFilters,
                        ...(filterGroups1.length > 0 && {
                            filter_groups1: filterGroups1,
                        }),
                        ...(filterGroups2.length > 0 && {
                            filter_groups2: filterGroups2,
                        }),
                        timeBetween: timeBetween,
                    };
                }
                const response = await createSaveSearchName(searchParams, path, searchName, token);
                if (response.status === 200) {
                    if(response?.data?.error){
                    setWarningMessage({ show: true, warningMessage: response?.data?.error });
                    setIsLoading(false);
                    return;
                    }
                    setShowNewSearchModal(false);
                    
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
            setShowNewSearchModal(false);           }
    }

    useEffect(() => {
        if (warningMessage.show) {
            const timeout = setTimeout(() => {
                setWarningMessage({ show: false, warningMessage: '' });
            }, 3000);
            return () => clearTimeout(timeout);
        }
    }, [warningMessage])

    return (
        <><div className="px-4 py-5 sm:p-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Save a new search</h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Enter new search name</p>
            </div>
            <form className="mt-1 sm:flex sm:items-center flex-col" onSubmit={handleCreateNewSearch}>
                <div className="w-full sm:max-w-xs">
                    <input
                        disabled={isLoading}
                        type="text"
                        name="savedSearchName"
                        id="savedSearchName"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="Name Your Search"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)} />
                </div>
                <div className="flex w-full mt-3">
                    <button
                        disabled={searchName?.trim()?.length === 0}
                        type='button'
                        onClick={handleCreateNewSearch}
                        className={`mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-0 sm:mt-0 sm:w-auto disabled:bg-gray-500 disabled:cursor-not-allowed ${isLoading ? "pointer-events-none" : ""}`}
                    >
                        {!isLoading ?
                            "Save search" :
                            "Saving..."}
                    </button>
                    <button
                        type='button'
                        onClick={() =>setShowNewSearchModal(false)}
                        className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-auto"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div><div className="pointer-events-none flex items-center">
                {warningMessage?.show && (<><ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    <p className="text-sm text-red-600 ml-2" id="email-error">
                        {warningMessage?.warningMessage}
                    </p></>)}
            </div></>
    )
}