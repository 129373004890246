import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useFilterStore } from "../../store/filterStore";
import { SearchTerm } from "../../types/search";
import classNames from "classnames";
import { usePatientStore } from '../../store/patientStore'
import { useFilterDropDownStore } from "../../store/filterDropDownStore";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; // optional for styling
import { InformationCircleIcon } from "@heroicons/react/20/solid";

type SearchBarProps = {
    id?: number;
    searchOperator: '+' | '-' | 'OR';
    setSearchOperator: React.Dispatch<React.SetStateAction<'+' | '-' | 'OR'>>;
}

export default function SearchBar({id,searchOperator,setSearchOperator}: SearchBarProps) {
    // Filter State
    const searchTerms = useFilterStore(state => state.searchTerms);
    const setSearchTerms = useFilterStore(state => state.setSearchTerms);
    const filtersDisabled = useFilterStore(state => state.filtersDisabled);
    const setPatientSearchTerms = usePatientStore(state => state.setSearchTerms);
    const searchFilters = useFilterStore(state => state.filters);
    const patientFilters = usePatientStore(state => state.filters);
    const clearButtonClicked = useFilterDropDownStore(state => state.clearButtonClicked);

    const [searchTerm, setSearchTerm] = useState<string>('');
 

    useEffect(() => {
        setSearchTerm('');
        setSearchOperator('+');
    }, [clearButtonClicked]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newSearchTerm: SearchTerm = {
            term: searchTerm,
            operator: searchOperator
        }
        id != null ? setPatientSearchTerms([...patientFilters[id].filters.searchTerms, newSearchTerm], id) : setSearchTerms([...searchFilters?.searchTerms || searchTerms, newSearchTerm]);
        setSearchTerm('');
    }
    const tooltipContent = (
        <div className="w-80 z-50">
            <p>You can search for multiple key words in the medical report</p>
        </div>
      );
    return (
            <form className="" onSubmit={(e) => handleSubmit(e)}>
                <div>       
                    <div className="w-full relative -mt-2 mb-2 rounded-md block sticky">   
                        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <Tippy
                                content={tooltipContent}
                                arrow={true}
                                delay={500}
                                interactive={true}
                                placement="top"
                                maxWidth="none"
                            >
                                <svg className="h-4 w-4 fill-slate-300" viewBox="0 0 20 20">
                                    <InformationCircleIcon className="h-4 w-4 text-blue-600 cursor-pointer" />
                                </svg>
                            </Tippy>
                        {( id != null ? patientFilters[id].filters.searchTerms : searchTerms)  &&
                        <div className="h-full">                               
                            <label htmlFor="operator" className="sr-only">
                                Operator
                            </label>
                            <select
                                id="operator"
                                name="operator"
                                value={searchOperator}
                                disabled={filtersDisabled}
                                onChange={(e) => setSearchOperator(e.target.value as '+' | '-' | 'OR')}
                                className="h-full rounded-md border-0 bg-transparent py-0 pr-[1.6rem] text-gray-500 font-semibold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm disabled:cursor-not-allowed"
                            >
                                <option value="+">AND</option>
                                <option value="OR">OR</option>
                                {(  id != null ? patientFilters[id].filters.searchTerms.length : searchFilters?.searchTerms?.length ) >= 1 && <option value="-">NOT</option>}
                            </select>
                        </div>
                        }
                        </div>                    
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            name="search"
                            id="search"
                            disabled={filtersDisabled}
                            className={classNames(
                                "shadow-sm block w-full rounded-md border-0 py-1.5 pl-[5.7rem] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-400 disabled:cursor-not-allowed",                            
                            )}
                                placeholder="Search Keywords"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div> 
            </form>
    );
}