import { useState } from "react";
import { useModalStore } from "../../../store/modalStore";
import { useUserStore } from "../../../store/userStore";
import OrderConfirmationModal from "./OrderModal";


export function ExportButton() {

    // Projects Store
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser);

    // Modal State
    const setShowOrderConfirmationModal = useModalStore(state => state.setShowOrderConfirmationModal);
    const showOrderConfirmationModal = useModalStore(state => state.showOrderConfirmationModal);

    //local store
    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);

    const handleExport = () => {
        setShowOrderConfirmationModal(true);
    }

    const handleDropDown = async (value: boolean) => {
        if(!isProjectBelongsToUser){
            setSelectVolumeOpen(value);
        }
      };

    return (
        <><div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
            {/* Button should not work if studies.length === 0 */}
            <button
                onClick={handleExport}
                disabled={downloadStatus !== "OPEN" || !isProjectBelongsToUser}
                className="whitespace-nowrap rounded-lg px-3 py-2 text-sm font-semibold shadow-sm disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed bg-blue-500 border-blue-700 text-white hover:bg-blue-500">
                {downloadStatus === "OPEN" ? "Export" : downloadStatus === "INITIATED" ? "Export Initiated" : "Exported"}
            </button>
            {showOrderConfirmationModal && <OrderConfirmationModal />}
            </div><div
            id="dropdownHover"
            className={`absolute ${selectVolumeOpen ? "" : "hidden"} rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-12 whitespace-nowrap w-auto left-auto ml-16`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                    <li className="text-white px-1">
                        Action cannot be performed for a teammate.
                    </li>
                </ul>
            </div></>
    )
}