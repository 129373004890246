import { create } from "zustand";
import { useSearchStore } from "./searchStore";
import { DateRange, SearchFilters, SearchTerm } from "../types/search";
import { formatSearchQueryForLucene } from "../utils/lucene_helpers";
import { manufacturerSelectionsDefault } from "../components/filterControls/manufacturerFilter/manufacturerOptions";
import { modalityOptionsDefault } from "../components/filterControls/modalityFilter/modalityOptions";

interface FilterStore {
    filters: SearchFilters;
    // setFilters: (filters: SearchFilters) => void;
    searchTerms: SearchTerm[];
    setSearchTerms: (searchTerms: SearchTerm[]) => void;
    sexSelections: string[];
    setSexSelections: (sexSelections: string[]) => void;
    usOnly: boolean;
    setUsOnly: (usOnly: boolean) => void;
    modalitySelections: string[];
    setModalitySelections: (modalitySelections: string[]) => void;
    manufacturerSelections: string[];
    setManufacturerSelections: (manufacturerSelections: string[]) => void;
    ageSelections: number[];
    setAgeSelections: (ageSelections: number[]) => void;
    sliceThicknessSelections: number[];
    setSliceThicknessSelections: (sliceThicknessSelections: number[]) => void;
    isFiltersPanelOpen: boolean;
    setIsFiltersPanelOpen: (isFiltersPanelOpen: boolean) => void;
    isReset: boolean;
    setIsReset: (isReset: boolean) => void;
    handleFiltersReset: () => void;
    filtersDisabled: boolean;
    setFiltersDisabled: (filtersDisabled: boolean) => void;
    selectedOptions: Array<{groupingField:string,selectedItems:string[]}>;
    setSelectedOptions:  (groupingField:string | any,selectedOptions: string[] | any)=>void;
    studyDateRange: DateRange;
    setStudyDateRange: (studyDateRange: DateRange) => void;
    studyAddedDateRange: DateRange;
    setStudyAddedDateRange: (studyAddedDateRange: DateRange) => void;
    handleResetFilters: () => void;
    crossFilterOnly: boolean;
    setCrossFilterOnly: (crossFilterOnly: boolean) => void;
    typedText: Array<{groupingField:string,typedItems:string[]}>;
    setTypedText:  (groupingField:string | any,typedText: string[] | any)=>void;
}

export const useFilterStore = create<FilterStore>()((set) => ({
    filters: {
        us_only: false,
        cross_filter:false,
        age: {
            min: 0,
            max: 90
        },
        sex: ["M", "F", "O"],
        slice_thickness: {
            min: 0,
            max: 10
        },
    },
    searchTerms: [],
    setSearchTerms: (searchTerms: SearchTerm[]) => {
        useSearchStore.getState().updateSearchQuery(formatSearchQueryForLucene(searchTerms));
        set(state => {
            return {
                filters: {
                    ...state.filters,
                    searchTerms
                },
                searchTerms
            };
        });
    },
    usOnly: false,
    setUsOnly: (usOnly: boolean) => set(state => {
        return {
            filters: {
                ...state.filters,
                us_only: usOnly
            },
            usOnly
        }
    }),
    sexSelections: ["M", "F", "O"],
    setSexSelections: (sexSelections: string[]) => set(state => {
        return {
            filters: {
                ...state.filters,
                sex: sexSelections
            },
            sexSelections
        }
    }),
    modalitySelections: modalityOptionsDefault,
    setModalitySelections: (modalitySelections: string[]) => set(state => {
        return {
            filters: {
                ...state.filters,
                modality: modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
            },
            modalitySelections : modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
        }
    }),

    manufacturerSelections: manufacturerSelectionsDefault,
    setManufacturerSelections: (manufacturerSelections: string[]) => set(state => {
        return {
            filters: {
                ...state.filters,
                manufacturer: manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
            },
            manufacturerSelections : manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
        }
    }),
    ageSelections: [0, 90],
    setAgeSelections: (ageSelections: number[]) => set(state => {
        return {
            filters: {
                ...state.filters,
                age: {
                    min: ageSelections[0],
                    max: ageSelections[1]
                }
            },
            ageSelections
        }
    }),

    sliceThicknessSelections: [0, 10],
    setSliceThicknessSelections: (sliceThicknessSelections: number[]) => set(state => {
        return {
            filters: {
                ...state.filters,
                slice_thickness: {
                    min: sliceThicknessSelections[0],
                    max: sliceThicknessSelections[1]
                }
            },
            sliceThicknessSelections
        }
    }),
    isFiltersPanelOpen: false,
    setIsFiltersPanelOpen: (isFiltersPanelOpen: boolean) => set({ isFiltersPanelOpen }),
    isReset: false,
    setIsReset: (isReset: boolean) => set({ isReset }),
    handleFiltersReset: () => set({
        filters: {
            modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            us_only: false,
            cross_filter:false,
            age: {
                min: 0,
                max: 90
            },
            sex: ["M", "F", "O"],
            manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            slice_thickness: {
                min: 0,
                max: 10
            },
        },
        sexSelections: ["M", "F", "O"],
        modalitySelections: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        manufacturerSelections: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        ageSelections: [0, 90],
        sliceThicknessSelections: [0, 10],
        searchTerms: [],
        isReset: true
    }),
    handleResetFilters: () => set({
        filters: {
            us_only: false,
            cross_filter:false,
            age: {
                min: 0,
                max: 90
            },
            sex: ["M", "F", "O"],
            slice_thickness: {
                min: 0,
                max: 10
            },
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        },
        usOnly:false,
        sexSelections: ["M", "F", "O"],
        ageSelections: [0, 90],
        sliceThicknessSelections: [0, 10],
        searchTerms: [],
    }),
    filtersDisabled: true,
    setFiltersDisabled: (filtersDisabled: boolean) => set({ filtersDisabled }),
    selectedOptions:[],
    setSelectedOptions:(groupingField:string | any,selectedItems:string[])=>
    set(state=>{
    let filter = state.filters;
        const newFilter:any = {...filter};
        if(selectedItems.length === 0){
            delete newFilter[groupingField]
        } 
         return  { 
            filters: {
                ...newFilter,
                ...(selectedItems.length > 0 && { [groupingField]: selectedItems }),
            },
            selectedItems
        }
    }),
    studyDateRange: { startDate: "", endDate: "" },
    setStudyDateRange: (studyDateRange: DateRange) =>
    set((state) => {
      const dateFilter =
      studyDateRange.startDate && studyDateRange.endDate
          ? studyDateRange
          : undefined;
      return {
        filters: {
          ...state.filters,
          date: dateFilter
        },
        studyDateRange
      };
    }),
  studyAddedDateRange: { startDate: "", endDate: "" },
  setStudyAddedDateRange: (studyAddedDateRange: DateRange) =>
    set((state) => {
      const dateAddedFilter =
        studyAddedDateRange.startDate && studyAddedDateRange.endDate
          ? studyAddedDateRange
          : undefined;
      return {
        filters: {
          ...state.filters,
          date_added: dateAddedFilter
        },
        studyAddedDateRange
      };
    }),
    crossFilterOnly: false,
    setCrossFilterOnly: (crossFilterOnly: boolean) => set(state => {
        return {
            filters: {
                ...state.filters,
                cross_filter: crossFilterOnly
            },
            crossFilterOnly
        }
    }),
    typedText:[],
    setTypedText:(groupingField:string | any,typedItems:string[])=>
    set(state=>{
    let filter = state.filters;
        const newFilter:any = {...filter};
        if(typedItems.length === 0){
            delete newFilter[groupingField]
        } 
         return  { 
            filters: {
                ...newFilter,
                ...(typedItems.length > 0 && { [groupingField]: typedItems }),
            },
            typedItems
        }
    }),
}));