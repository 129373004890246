import React from 'react';
import PieChart from './common/PieChart';
import { useChartsStore } from '../../../../../store/chartsStore';
import { whichStateIsSource } from '../../../../../utils/data_formatting';

export default function UsStatesChart() {
    const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
    const { institution: usStatesData } = cohortsChartsData;

  const filteredUsStateChartData = () => {
    if (!usStatesData) return {};
    let ranges: { [key: string]: number } = {};

    Object.keys(usStatesData).forEach((key) => {
      const state = whichStateIsSource(key);
      if (ranges[state]) {
        ranges[state] += Number(usStatesData[key]);
      } else {
        ranges[state] = Number(usStatesData[key]);
      }
    });
    return ranges;
  };

  const usStateChartdata = filteredUsStateChartData()

  return (
    <PieChart
      data={usStateChartdata}
      colors={["#A78BFA", "#F472B6", "#10B981", "#6366F1","#0000FF"]}
      chartId="us-pie-chart"
    />
  );
};