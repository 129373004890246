import React from 'react';
import PieChart from './common/PieChart';
import { useChartsStore } from '../../../../../store/chartsStore';

export default function AgePieChart() {
  // Charts Store
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { parsed_patient_age: ageData } = cohortsChartsData;

  const formattedAgeData: { [key: string]: number } = {};

  if (ageData) {
    Object.entries(ageData).forEach(([key, value]) => {
      formattedAgeData[key] = Number(value);
    });
  }

  return (
    <PieChart
      data={formattedAgeData}
      colors={["#3B82F6", "#A78BFA", "#8B5CF6", "#6366F1", "#14B8A6"]}
      chartId="age-pie-chart"
    />
  );
}
