import { create } from 'zustand';

export interface ToastMessage {
    headerMessage: string;
    bodyMessage?: string;
    type: 'warning' | 'info' | 'success';
}
interface ToastStore {
    showSuccessfulOrderToast: boolean;
    setShowSuccessfulOrderToast: (show: boolean) => void;
    showSuccessfulWipeToast: boolean;
    setShowSuccessfulWipeToast: (show: boolean) => void;
    showCustomToast: boolean;
    setShowCustomToast: (show: boolean) => void;
    showSuccessfulInvitationToast: boolean;
    setShowSuccessfulInvitationToast: (show: boolean) => void;
    showCopiedToast: boolean;
    setShowCopiedToast: (show: boolean) => void;
    copiedToastMessage : string,
    setCopiedToastMessage : (copiedToastMessage:string)=>void;
    ToasterCreationMessage:ToastMessage
    setToasterCreationMessage:(ToasterCreationMessage:ToastMessage)=>void
}


export const useToastStore = create<ToastStore>()((set) => ({
    showSuccessfulOrderToast: false,
    setShowSuccessfulOrderToast: (show: boolean) => set({ showSuccessfulOrderToast: show }),
    showSuccessfulWipeToast: false,
    setShowSuccessfulWipeToast: (show: boolean) => set({ showSuccessfulWipeToast: show }),
    showCustomToast: false,
    setShowCustomToast: (show: boolean) => set({ showCustomToast: show }),
    showSuccessfulInvitationToast: false,
    setShowSuccessfulInvitationToast: (show: boolean) => set({ showSuccessfulInvitationToast: show }),
    showCopiedToast: false,
    setShowCopiedToast: (show: boolean) => set({ showCopiedToast: show }),
    copiedToastMessage:"",
    setCopiedToastMessage: (copiedToastMessage:string)=>set({copiedToastMessage}),
    ToasterCreationMessage:{
        headerMessage:'',
        bodyMessage:'',
        type: 'info',
    },
    setToasterCreationMessage:(ToasterCreationMessage:ToastMessage)=>set({ToasterCreationMessage})
}));