import Search from "../search/search";
import SexFilter from "./sexFilter/sexFilter";
import AgeFilter from "./ageFilter/ageFilter";
import SliceThicknessFilter from "./SliceThicknessFilter/SliceThicknessFilter";
import ModalityFilter from "./modalityFilter/ModalityFilter";
import AdvancedFiltersMain from "./advancedFilters/AdvancedFiltersMain";
import ManufacturerFilter from "./manufacturerFilter/ManufacturerFilter";
// import { AdvancedFilterSearchRequest } from "../../types/advancedFilters";
// import { useFilterStore } from "../../store/filterStore";
import USOnlyFilter from "./US_Filter/USOnlyFilter";
import AiSummaryToggle from "./AISummaryToggle";
import TimeBetweenFilter from "./timeBetweenFilter/timeBetweenFilter";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";
import ClearAllFiltersButton from "./ClearAllFiltersButton";
import SaveSearchButton from "./SaveSearchButton";
export default function SearchByPatient() {

    // const setStudies = useSearchStore(state => state.setStudies);
    // const handleFiltersReset = useFilterStore(state => state.handleFiltersReset);

    // const handleReset = () => {
    //     // handleFiltersReset();
    //     // setStudies([]);
    //     // TODO: Short-term solution to reset filters
    //     window.location.reload();
    // }
    return (
        <div className="flex flex-col">
            <div>
                <div className="flex flex-row justify-between items-center">
                    <h3 className="text-lg font-medium text-gray-700">Patient filters</h3>
                </div>
                <div className="flex flex-col">
                    <USOnlyFilter/>
                    <SexFilter/>     
                    <AgeFilter/>
                </div>
                <h3 className="text-lg font-medium text-gray-700 border-t pt-4 pb-4 border-gray-300 border-solid">Study 1</h3>
                <div className="flex flex-col">
                    <Search id={0} />
                    <ModalityFilter id={0} />
                    <ManufacturerFilter id={0} />
                    <SliceThicknessFilter id={0} />
                    <AdvancedFiltersMain id={0}/>
                </div>
            </div>
            <div className="border-t border-b pt-4 pb-4 border-gray-300 border-solid">
                <TimeBetweenFilter/>
            </div>
            <div className=" pt-3">
                <div className="flex flex-row justify-between items-center">
                </div>
                <h3 className="text-lg font-medium pb-4 text-gray-700">Study 2</h3>
                <div className="flex flex-col">
                    <Search id={1}/>
                    <ModalityFilter id={1} />
                    <ManufacturerFilter id={1} />
                    <SliceThicknessFilter id={1} />
                    <AdvancedFiltersMain id={1}/>
                </div>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-300"></hr>
                <div className="flex flex-col">
                <ClearAllFiltersButton/>
                <SaveSearchButton />
                </div>
            </div>
        </div>
    )
}