import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSearchStore } from '../../store/searchStore';
import { usePatientStore } from '../../store/patientStore';
import { useProjectsStore } from '../../store/projectsStore';
import { useSearchTypeStore } from '../../store/searchTypeStore';
import CustomSelect from '../common/CustomSelect';


export default function Pagination() {

    // Search state
    const paginationOffset = useSearchStore(state => state.searchRequestPageOffset);
    const patientPagination = usePatientStore(state => state.pagination);
    const setPaginationOffset = useSearchStore(state => state.setSearchRequestPageOffset);
    const setPatientPaginationOffset = usePatientStore(state => state.setPatientPaginationOffset);
    const selectedPageSize = useSearchStore(state => state.selectedPageSize);
    const selectedPatientPageSize = usePatientStore(state => state.pagination);
    const numberOfStudies = useSearchStore(state => state.numberOfStudies);
    const studiesCountInCohort = useProjectsStore(state => state.studiesCountInCohort);
    const searchType = useSearchTypeStore((state) => state.searchType);
    const pageOffset =  searchType ==="study" ? paginationOffset : patientPagination.offset;

    const [ pageOptions, setPageOptions ] = useState<number[]>();
    const [ currentPage, setCurrentPage ] = useState<number>(pageOffset + 1);
    const [ maxPageLimit, setMaxPageLimit ] = useState<number>(0);
    const isStudyOrPatient = searchType === "study" || searchType === "patient";
    const count = isStudyOrPatient ? numberOfStudies : studiesCountInCohort;
    
    // Account for MAX PAGE LIMIT based on number of studies
    useEffect(() => {
        const pageSize =  searchType === "study" ? selectedPageSize : selectedPatientPageSize.pageSize; 
        if (count) {
            setMaxPageLimit(Math.ceil(count / pageSize));
        }
    }, [numberOfStudies,studiesCountInCohort,searchType,selectedPageSize,selectedPatientPageSize.pageSize])

    const handleNextPage = () => {
        if (currentPage < maxPageLimit) {
            setPaginationOffset(paginationOffset + 1);
            setPatientPaginationOffset(paginationOffset + 1);
            setPageOptions(pageOptions?.map(page => page + 1));
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePreviousPage = () => {
        if(pageOffset>0) {
            setPaginationOffset(pageOffset - 1);
            setPatientPaginationOffset(pageOffset - 1);
            setCurrentPage(currentPage - 1);
        }
    }

    const handlePageNumber = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPage = Number(event.target.value);
        setPaginationOffset(selectedPage - 1);
        setPatientPaginationOffset(selectedPage - 1);
         setCurrentPage(selectedPage);
    }

    useEffect(() => {
        setCurrentPage(pageOffset + 1);
    }, [pageOffset]);

    const options = Array.from({ length: count && count > 0 ? maxPageLimit : 0}, (_, index) => index + 1);
    return (
        <div className="sticky bottom-0 right-0 ml-auto">
            <nav aria-label="Page navigation example">
                <ul className="flex items-center -space-x-px h-8 text-base gap-1">
                    <li>
                        <button 
                            onClick={handlePreviousPage}
                            disabled={pageOffset === 0 || count === null}
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">
                            <span className="sr-only">Previous</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                            </svg>
                        </button>
                    </li>
                      <CustomSelect 
                            options={options} 
                            currentPage={currentPage} 
                            handleChange={handlePageNumber} />
                    <li>
                        <button 
                            onClick={handleNextPage}
                            disabled={pageOffset === maxPageLimit || count === null}
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">
                            <span className="sr-only">Next</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>

        </div>
    )
}