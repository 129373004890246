import React from 'react';
import { Project } from '../../../types/project';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling

interface ProgressBarProps {
    label?: string;
    usage: number;
    quota: number;
    project: Project | null;
  }

export function ProgressBar({ label, usage, quota, project }: ProgressBarProps) {

    let actual_usage = usage;
    const project_usage = project?.cohort?.status === "OPEN" ? project.cohort.studies.length : 0;
    
    if (project) {
        actual_usage += project_usage;
    }

    const progressPercentage = Math.min(100, (actual_usage / quota) * 100);
    const tooltipContent = (
        <div>
            <strong>{label} usage:</strong> {usage} {project_usage !== 0 ? `+ ${project_usage}` : ''} /{quota} ( {Math.ceil(progressPercentage)}% )<br />
        </div>
    );

    let barColor = 'bg-green-700/50'; // standard is a green bar
    if (progressPercentage > 90) {
        barColor = 'bg-red-700/40'; // 90% makes a red bar
    } else if (progressPercentage > 80) {
        barColor = 'bg-yellow-500/30'; // 80% makes a yellow bar
    }
    

    return (
        <div className="h-2.5" style={{ width: '150px' }}>
            <Tippy content={tooltipContent} arrow={false} delay={[0, 200]}>
                <div className="w-full bg-gray-100 rounded-full h-2.5 mb-4">
                    <div
                        className={`${barColor} h-full rounded-full transition-all ease-in-out duration-300`}
                        style={{ width: `${progressPercentage}%` }}
                    ></div>
                </div>
            </Tippy>
        </div>
    );
}
