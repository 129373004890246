import { SearchTerm } from "../types/search";

export function formatSearchQueryForLucene(query: SearchTerm[]): string {
    if (query.length === 0) return '';
    const specialCharactersRegex = /[+!@#$'%^&*~(),.?":{}|<>]/;

    const formatTerm = (term: string): string => {
        return term.includes(' ') || (term.startsWith('(') && term.endsWith(')')) || specialCharactersRegex.test(term) ? `"${term}"` : term;
    };
    
    if (query.length === 1) {
        return formatTerm(query[0].term);
    } else {
        const formattedQuery: string[] = query.map((searchTerm, index) =>  {
            if (index === 0) {
                return formatTerm(searchTerm.term);
            } else {
                return searchTerm.operator + ' ' + formatTerm(searchTerm.term);
            }
        });

        return formattedQuery.join(' ');
    }
}


export {}
