import { useAdvancedFilterStore } from "../../../../../store/advancedFiltersStore"
import { usePatient1AdvancedFiltersStore } from "../../../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../../../store/patient2AdvancedFiltersStore";

type AddNewGroupDialogProps = {
    groupId: number;
    id?: number;
}

export default function AddNewFilterButton({
    groupId,
    id
}: AddNewGroupDialogProps) {
    const addFilter = useAdvancedFilterStore(state => state.addFilter);
    const addFilter1 = usePatient1AdvancedFiltersStore(state => state.addFilter);
    const addFilter2 = usePatient2AdvancedFiltersStore(state => state.addFilter);

    const newFilter = (groupId: number) => {
        console.log("Adding new filter", groupId, id)
        if ( id == null || id == undefined ){
            addFilter(groupId);
        } else if (id == 0) {
            addFilter1(groupId);
        } else if (id == 1) {
            addFilter2(groupId);
        }
    }

    return (
        <div className="">
            <div className="relative">
                <button
                    type="button"
                    onClick={() => newFilter(groupId)}
                    className="mt-2 rounded bg-white px-4 py-1 text-sm font-semibold text-blue-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-left"
                >
                    + Add Filter
                </button>
                
            </div>
        </div>
    )
}