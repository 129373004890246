import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import cn from 'classnames';
import { useAdvancedFilterStore } from '../../../../../store/advancedFiltersStore';
import { usePatient1AdvancedFiltersStore } from "../../../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../../../store/patient2AdvancedFiltersStore";

const publishingOptions = [
  { title: 'All', description: 'Every single filter must match', current: true, logic_operator: "AND" },
  { title: 'Any', description: 'One or more filters must match', current: false, logic_operator: "OR" },
]

type AllOrAnySelectProps = {
  groupId?: number;
  id?: number
}

export default function AllOrAnySelectFilters({
  groupId,
  id
}: AllOrAnySelectProps) {
  const updateLogic = useAdvancedFilterStore(state => state.updateLogicOperatorForGroup);
  const filterGroups = useAdvancedFilterStore(state => state.filterGroups);

  // patient1
  const updateLogic1 = usePatient1AdvancedFiltersStore(state => state.updateLogicOperatorForGroup);
  const filterGroups1 = usePatient1AdvancedFiltersStore(state => state.filterGroups);

  // patient2
  const updateLogic2 = usePatient2AdvancedFiltersStore(state => state.updateLogicOperatorForGroup);
  const filterGroups2 = usePatient2AdvancedFiltersStore(state => state.filterGroups);

  const updateLogicOperator = (option: any) => {
      if (groupId) {
        if (id == null || id == undefined) {
          updateLogic(groupId, option.logic_operator)
        } else if (id === 0) {
          updateLogic1(groupId, option.logic_operator);
        } else if (id === 1) {
          updateLogic2(groupId, option.logic_operator);
        }
      }
    }

    const currentGroup = (id == null || id == undefined) ? filterGroups.find((group) => group.group_id === groupId) :
      (id === 0 ? filterGroups1.find((group) => group.group_id === groupId) : 
      (id === 1 ? filterGroups2.find((group) => group.group_id === groupId) : null));

    const selectedOption = publishingOptions.find((item) => item.logic_operator === currentGroup?.logic_operator) || publishingOptions[0];

    return (
    <Listbox value={selectedOption} onChange={(e) => updateLogicOperator(e)}>
      {({ open }) => (
        <>
          <div className="flex flex-col">
                <Listbox.Button className="relative w-16 cursor-default rounded-md bg-white py-0.5 p-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">{selectedOption ?.title}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute left-20 z-10 mt-10 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-row">
                          <p className="font-bold">{option.title}</p>
                        <p className={cn(active ? 'text-blue-200' : 'text-gray-500', 'ml-2')}>
                          {option.description}
                        </p>
                        {selectedOption ? (
                            <span className={active ? 'text-white' : 'text-blue-600'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
