import { useAdvancedFilterStore } from "../store/advancedFiltersStore";
import { useFilterDropDownStore } from "../store/filterDropDownStore";
import { useFilterStore } from "../store/filterStore";
import { useSearchStore } from "../store/searchStore";
import { useSortStore } from "../store/sortStore";
import { usePatient1AdvancedFiltersStore } from "../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../store/patient2AdvancedFiltersStore";
import { usePatientStore } from "../store/patientStore";
import { useSearchTypeStore } from "../store/searchTypeStore";

export function useClearAllFilters() {
  const setAdvancedFiltersEnabled = useAdvancedFilterStore(state => state.setAdvancedFiltersEnabled);
  const updateSearchRequestFilters = useSearchStore(state => state.updateSearchRequestFilters);
  const filters = useFilterStore(state => state.filters);
  const setSelectedOptions = useFilterStore(state => state.setSelectedOptions);
  const setAdvancedFilters = useAdvancedFilterStore(state => state.setAdvancedFilters);
  const baseFilters =["modality", "manufacturer","institution"];
  const setClearButtonClicked = useFilterDropDownStore(state=>state.setClearButtonClicked)
  const clearButtonClicked =  useFilterDropDownStore(state=>state.clearButtonClicked)
  const setCrossFilterSelection =useFilterDropDownStore(state => state.setCrossFilterSelection);
  const setFilterDropdowns = useFilterDropDownStore(state=>state.setFilterDropdowns);
  const setStudyDateRange = useFilterStore((state) => state.setStudyDateRange);
  const setStudyAddedDateRange = useFilterStore((state) => state.setStudyAddedDateRange);
  const handleResetFilters = useFilterStore((state) => state.handleResetFilters);
  const newStudySortArray = useSortStore((state) => state.newStudySortArray);
  const newPatientSortArray = useSortStore((state) => state.newPatientSortArray);
  const setCrossFilterOnly = useFilterStore(
    (state) => state.setCrossFilterOnly
  );
  const searchType = useSearchTypeStore(state => state.searchType);
  const handlePatientResetFilters = usePatientStore(state => state.handlePatientResetFilters);
  const setManufacturerSelections = useFilterStore(state => state.setManufacturerSelections);
  const setSearchTerms = useFilterStore((state) => state.setSearchTerms);


  // Advanced Filter State
  const setFilterGroups1 = usePatient1AdvancedFiltersStore(state => state.setFilterGroups);
  const setFilterGroups2 = usePatient2AdvancedFiltersStore(state => state.setFilterGroups);
  const setAdvancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);
  const setAdvancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);
  const updateSearchQuery = useSearchStore(state => state.updateSearchQuery);
  const setFilterGroups= useAdvancedFilterStore(state => state.setFilterGroups);


  const clearFilter = () => {
    if (searchType === "study") {
      setClearButtonClicked(!clearButtonClicked);
      setAdvancedFiltersEnabled(false);
      baseFilters.map((filterItem) => setSelectedOptions(filterItem, []));
      updateSearchRequestFilters(filters);
      setAdvancedFilters(null);
      setCrossFilterSelection({});
      setFilterDropdowns([
        "modality",
        "body_part_examined",
        "study_description",
        "series_description",
        "manufacturer",
        "manufacturer_model_name",
        "institution",
        "imputed_ethnicity",
        "imputed_race"
      ]);
      setStudyDateRange({ startDate: "", endDate: "" });
      setStudyAddedDateRange({ startDate: "", endDate: "" });
      handleResetFilters();
      newStudySortArray([
        { 
          sort_field: "positive_entities",
          sort_method: "ASC"
        }
        ]);
      setCrossFilterOnly(false);
    } else {
      handlePatientResetFilters();
      setFilterGroups2([]);
      setAdvancedFiltersEnabled2(false);
      setFilterGroups1([]);
      setAdvancedFiltersEnabled1(false);
      setManufacturerSelections([]);
      setClearButtonClicked(!clearButtonClicked);
      newPatientSortArray([
        { 
          sort_field: "positive_entities",
          sort_method: "ASC"
        }
      ]);
    }
    updateSearchQuery("");
    setFilterGroups([]);
    setSearchTerms([]);
  };
  return { clearFilter };
}
