import { XMarkIcon } from "@heroicons/react/24/outline"
import { SearchTerm } from "../../types/search";

type SearchBadgeProps = {
    searchTerm: SearchTerm;
    onRemove: (searchTerm: SearchTerm) => void;
    badgeNumber: number
}

enum ReadableOperator {
    '-' = 'NOT',
    '+' = 'AND',
    'OR' = 'OR'
}

export default function SearchBadge({ searchTerm, onRemove, badgeNumber }: SearchBadgeProps) {
    
    const handleRemoval = () => {
        onRemove(searchTerm);
    }
    
    return (
        <span className="inline-flex items-center gap-x-1.5 rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
            <XMarkIcon className="h-3 w-3 hover:cursor-pointer" onClick={() => handleRemoval()}/>
                {
                    badgeNumber === 0 ? 
                    `${searchTerm.term}` :
                    `${ReadableOperator[searchTerm.operator]}: ${searchTerm.term}`
                }
        </span>
    )
}