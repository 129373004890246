import { create } from "zustand";

interface PolicyTermsModalStore {
    showPolicyTermsModal: boolean;
    setShowPolicyTermsModal: (show: boolean) => void;
}

export const usePolicyTermsModalStore = create<PolicyTermsModalStore>()((set) => ({
    showPolicyTermsModal: false,
    setShowPolicyTermsModal: (show: boolean) => set({ showPolicyTermsModal: show })
}))