import { expandModalityName } from "../../utils/data_formatting";
import VideoComponent from "./VideoComponent";
import { useModalStore } from "../../store/modalStore";
import { useEffect, useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";

type SeriesTableProps = {
  series_metadata: any[];
}

export default function SeriesTable({
  series_metadata,
}: SeriesTableProps) {
  
    const [ cleanedSeriesMetadata, setCleanedSeriesMetadata ] = useState<any[]>([]);

    const setShowExpandedThumbnailModal = useModalStore(state => state.setShowExpandedThumbnailModal);
    const setExpandedThumbnailModalSource = useModalStore(state => state.setExpandedThumbnailModalSource)
    const setExpandedThumbnailFiletype = useModalStore(state => state.setExpandedThumbnailFiletype)

    

    useEffect(() => {
      const tempSeriesMetadata = series_metadata.filter(series => series.series_instance_uid !== null);
      setCleanedSeriesMetadata(tempSeriesMetadata);
    }, series_metadata)

    const handleShowExpandedThumbnailModal = (video: string, type: 'video' | 'image') => {
        setShowExpandedThumbnailModal(true);
        setExpandedThumbnailModalSource(video);
        setExpandedThumbnailFiletype(type);
    };


    return (
        <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-1 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Scan Preview
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Modality
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Series Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Slice Thickness (mm)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      # Slices
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {cleanedSeriesMetadata.map((series, index) => (
                    <tr>
                      <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-8">
                        { 
                          (series.thumbnail && series.thumbnail.includes('.mp4')) ? 
                            <div onClick={() => handleShowExpandedThumbnailModal(series.thumbnail, 'video')} className="hover:cursor-pointer">
                              <VideoComponent video={series.thumbnail} />
                            </div> : 
                            series.thumbnail ?
                            <div className="relative hover:cursor-pointer" onClick={() => handleShowExpandedThumbnailModal(series.thumbnail, 'image')}>
                              <img width="100" height="100" alt='placeholder'src={series.thumbnail} />
                                  <div className="absolute bottom-0 right-0 w-full h-full">
                                      <PhotoIcon  className="h-5 w-5 text-gray-300"/>
                                  </div>
                              </div>
                              :
                              <img width="100" height="100" alt='placeholder' src="https://via.placeholder.com/100"/>
                        }
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{expandModalityName(series.modality)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{series.series_description}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{series.slice_thickness}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{series.number_of_frames}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
}