import AgePieChart from "./chartComponents/AgePieChart";
import BodyPartChart from "./chartComponents/BodyPartChart";
import InstitutionPieChart from "./chartComponents/InstitutionPieChart";
import ManufacturerPieChart from "./chartComponents/ManufacturerPieChart";
import SeriesDescriptionChart from "./chartComponents/SeriesDescriptionChart";
import SexPieChart from "./chartComponents/SexPieChart";
import SourcePieChart from "./chartComponents/SourcePieChart";
import StudyDescriptionChart from "./chartComponents/StudyDescriptionChart";
import UsStatesChart from "./chartComponents/UsStatesChart";


export enum ChartTitles {
  SEX = "Sex",
  AGE = "Age",
  MANUFACTURER = "Manufacturer",
  SOURCE = "Source",
  INSTITUTION = "Institution",
  US_STATES = "US States",
  BODY_PART = "Body Part",
  STUDY_DESCRIPTION = "Study description",
  SERIES_DESCRIPTION = "Series description",
}

export const chartsData = [
  { title: ChartTitles.SEX, chart_field: "patient_sex" },
  { title: ChartTitles.AGE, chart_field: "parsed_patient_age" },
  { title: ChartTitles.MANUFACTURER, chart_field: "manufacturer" },
  { title: ChartTitles.SOURCE, chart_field: "institution" },
  { title: ChartTitles.INSTITUTION, chart_field: "institution" },
  { title: ChartTitles.US_STATES, chart_field: "institution" },
  { title: ChartTitles.BODY_PART, chart_field: "body_part_examined" },
  { title: ChartTitles.STUDY_DESCRIPTION, chart_field: "study_description" },
  { title: ChartTitles.SERIES_DESCRIPTION, chart_field: "series_description" },
];

export function getChartComponent(title: ChartTitles) {
  switch (title) {
    case ChartTitles.SEX:
      return <SexPieChart />;
    case ChartTitles.AGE:
      return <AgePieChart />;
    case ChartTitles.MANUFACTURER:
      return <ManufacturerPieChart />;
    case ChartTitles.SOURCE:
      return <SourcePieChart />;
    case ChartTitles.INSTITUTION:
      return <InstitutionPieChart />;
    case ChartTitles.US_STATES:
      return <UsStatesChart />;
    case ChartTitles.BODY_PART:
      return <BodyPartChart />;
    case ChartTitles.STUDY_DESCRIPTION:
      return <StudyDescriptionChart />;
    case ChartTitles.SERIES_DESCRIPTION:
      return <SeriesDescriptionChart />;
    default:
      return null;
  }
}