import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../../store/authStore";
import { updateSaveSearchName } from "../../../utils/request_save_searches";
import { useSavedSearchStore } from "../../../store/savedSeachStore";
import { savedSearchNameLengthExceedingMessage } from "../../common/Constants";

interface WarningMessage {
  show: boolean;
  warningMessage: string;
}

export default function EditSavedSearchName(props: any) {
  const { currentSelectedItem, setShowEditSearchModal, setShowDeleteSearchModal } = props;
  // Auth State
  const token = useAuthStore((state) => state.token);
  const setSavedSearchList = useSavedSearchStore((state) => state.setSavedSearchList);
  const savedSearchList = useSavedSearchStore((state) => state.savedSearchList);
  //local state
  const [isLoading, setIsLoading] = useState(false);
  const [searchName, setSearchName] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<WarningMessage>({ show: false, warningMessage: "" });

  const handleEditSearchName = async () => {
    if (searchName.length > 80) {
      setWarningMessage(savedSearchNameLengthExceedingMessage);
      return;
    }
    if (token) {
      try {
        setIsLoading(true);
        const response = await updateSaveSearchName(currentSelectedItem.id, searchName, token);
        if (response.status === 200) {
          if (response?.data?.error) {
            setWarningMessage({ show: true, warningMessage: response?.data?.error });
            setIsLoading(false);
          } else {
            const updatedSearchList = savedSearchList.map((item: any) => {
                if (item.id === currentSelectedItem.id) {
                  return { ...response?.data, id: currentSelectedItem.id };
                }
                return item;
              });
            setSavedSearchList(updatedSearchList);
            setIsLoading(false);
            setShowEditSearchModal(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        setShowEditSearchModal(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setSearchName(currentSelectedItem?.search_name);
  }, [currentSelectedItem]);

  const handleDeleteSearch = () => {
    setShowEditSearchModal(false);
    setShowDeleteSearchModal(true);
  };

  useEffect(() => {
    if (warningMessage.show) {
      const timeout = setTimeout(() => {
        setWarningMessage({ show: false, warningMessage: "" });
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [warningMessage]);

  return (
    <>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Rename Search</h3>
        <form className="mt-5 sm:flex sm:items-center" onSubmit={handleEditSearchName}>
          <div className="w-full sm:max-w-xs">
            <input
              type="text"
              disabled={isLoading}
              name="searchName"
              id="searchName"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:text-gray-500 disabled:bg-gray-100 disabled:border-gray-200 disabled:shadow-none"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <button
            // disabled={isLoading}
            type="button"
            onClick={handleEditSearchName}
            className={`mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-auto  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed ${
              isLoading ? "bg-blue-600 px-3 py-2 text-sm font-semibold text-white pointer-events-none cursor-not-allowed" : ""
            }`}>
            {!isLoading ? "Update" : "Updating..."}
          </button>
        </form>
      </div>
      <div className="pointer-events-none flex items-center">
        {warningMessage?.show && (
          <>
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            <p className="text-sm text-red-600 ml-2" id="email-error">
              {warningMessage?.warningMessage}
            </p>
          </>
        )}
      </div>
      <div className="flex items-center px-4">
        <p className="ml-2">
          <a href="#" className="text-gray-600 underline text-sm" onClick={handleDeleteSearch}>
            Click here to delete search
          </a>
        </p>
      </div>
    </>
  );
}
