export const alldropdowns = [
  "modality",
  "body_part_examined",
  "study_description",
  "series_description",
  "manufacturer",
  "manufacturer_model_name",
  "institution",
  "imputed_ethnicity",
  "imputed_race",
];

export const baseDropDowns = ["modality", "manufacturer"];

export const advancedFilterDropdowns = [
  "body_part_examined",
  "study_description",
  "series_description",
  "manufacturer_model_name",
  "institution",
  "imputed_ethnicity",
  "imputed_race",
];
