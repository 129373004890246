import { create } from 'zustand';

interface SearchTypeStore {
    searchType: string;
    setSearchType: (serchType: string) => void;
}

export const useSearchTypeStore = create<SearchTypeStore>()((set) => ({
    searchType: "study",
    setSearchType: (searchType) => set((state) => {
        return {
            ...state,
            "searchType": searchType
        }
    })
}))