import { useEffect } from "react"
import {
    AdvancedFilterSearchRequest,
    AdvancedFilterGroupComponent
} from "../../../types/advancedFilters";

import AddAdvancedFilterGroup from "./FilterGroup/AndFilterButton"
import AdvancedFilterGroupsContainer from "./FilterGroup/AFGroupsContainer"
import AllOrAnySelectGroups from "./FilterGroup/AllOrAny/AllOrAnySelectGroups";
import { filterOptions } from "./FilterGroup/Filter/FilterBy/FilterByOptions";
import { convertToAdvancedFilterSearchRequest } from "../../../utils/data_formatting";
import { useAdvancedFilterStore } from "../../../store/advancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../store/patient2AdvancedFiltersStore";

const defaultGroups: AdvancedFilterGroupComponent[] = [
    {
        group_id: 1,
        logic_operator: "AND",
        filters: [
            {
                filter_id: 1,
                field: filterOptions[0].value,
                operator: filterOptions[0].operatorOptions[0],
                value: ""
            }
        ]
    }
]

type AdvancedFilterProps = {
    id?: number;
}

export default function AdvancedFiltersMain({id}: AdvancedFilterProps) {

    // Advanced filter state
    const advancedFiltersEnabled = useAdvancedFilterStore(state => state.advancedFiltersEnabled);
    const setAdvancedFiltersEnabled = useAdvancedFilterStore(state => state.setAdvancedFiltersEnabled);
    const filterGroups = useAdvancedFilterStore(state => state.filterGroups);
    const setFilterGroups = useAdvancedFilterStore(state => state.setFilterGroups);
    const groupsMatch = useAdvancedFilterStore(state => state.groupsMatch);
    const setAdvancedFilters = useAdvancedFilterStore(state => state.setAdvancedFilters);

    // Advanced filter 1 state
    const advancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const setAdvancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);
    const filterGroups1 = usePatient1AdvancedFiltersStore(state => state.filterGroups);
    const setFilterGroups1 = usePatient1AdvancedFiltersStore(state => state.setFilterGroups);
    const groupsMatch1 = usePatient1AdvancedFiltersStore(state => state.groupsMatch);
    const setAdvancedFilters1 = usePatient1AdvancedFiltersStore(state => state.setAdvancedFilters);

    // Advanced filter 2 state
    const advancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const setAdvancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);
    const filterGroups2 = usePatient2AdvancedFiltersStore(state => state.filterGroups);
    const setFilterGroups2 = usePatient2AdvancedFiltersStore(state => state.setFilterGroups);
    const groupsMatch2 = usePatient2AdvancedFiltersStore(state => state.groupsMatch);
    const setAdvancedFilters2 = usePatient2AdvancedFiltersStore(state => state.setAdvancedFilters);

    useEffect(() => {
        if ( filterGroups.length === 0 && (id == null || id == undefined )){
            setAdvancedFiltersEnabled(false);
            setAdvancedFilters(null);
        } else if (filterGroups1.length === 0 && id == 0) {
            setAdvancedFiltersEnabled1(false);
            setAdvancedFilters1(null);
        } else if (filterGroups2.length === 0 && id == 1) {
            setAdvancedFiltersEnabled2(false);
            setAdvancedFilters2(null);
        } else {
            if ( id == null || id == undefined ){
                const advancedFilters: AdvancedFilterSearchRequest = convertToAdvancedFilterSearchRequest(filterGroups, groupsMatch);
                setAdvancedFilters(advancedFilters);
            } else if (id == 0) {
                const advancedFilters: AdvancedFilterSearchRequest = convertToAdvancedFilterSearchRequest(filterGroups1, groupsMatch1);
                setAdvancedFilters1(advancedFilters);
            } else if (id == 1) {
                const advancedFilters: AdvancedFilterSearchRequest = convertToAdvancedFilterSearchRequest(filterGroups2, groupsMatch2);
                setAdvancedFilters2(advancedFilters);
            }
        }
    }, [filterGroups, filterGroups1, filterGroups2, groupsMatch, groupsMatch1, groupsMatch2]);

    const handleUseAdvancedFilters = () => {
        if ( id == null || id == undefined ){
            setFilterGroups(defaultGroups);
            setAdvancedFiltersEnabled(true);
        } else if (id == 0) {
            setFilterGroups1(defaultGroups);
            setAdvancedFiltersEnabled1(true);
        } else if (id == 1) {
            setFilterGroups2(defaultGroups);
            setAdvancedFiltersEnabled2(true);
        }
        console.log(advancedFiltersEnabled, advancedFiltersEnabled1, advancedFiltersEnabled2)
    }

    return (
        <div className="py-2">
            {( 
                    (
                        (id == null || id == undefined) && !advancedFiltersEnabled
                    ) || 
                    (id == 0 && !advancedFiltersEnabled1) || 
                    (id == 1 && !advancedFiltersEnabled2)
             ) ? (
                <button 
                    onClick={handleUseAdvancedFilters}
                    className="text-blue-600 hover:text-blue-800 hover:underline font-semibold text-sm"
                >
                    Use Advanced Filters
                </button>
            ) : (
                <div className="flex flex-col">
                    {
                        (
                            ((id == null || id == undefined) && filterGroups.length > 1)  || 
                            (id == 0 && filterGroups1.length > 1) || 
                            (id == 1 && filterGroups2.length > 1)
                        ) ? 
                        <div className="flex flex-row mb-2">
                            <p className='font-medium text-sm text-gray-900 mt-1 mr-2'>Match</p>
                                <AllOrAnySelectGroups id={id} />
                            <p className='font-medium text-sm text-gray-900 mt-1 ml-2'>groups</p>
                        </div>
                    : null }
                    <AdvancedFilterGroupsContainer id={id} />
                    <AddAdvancedFilterGroup id={id} />
                </div>
            )}
            
        </div>
    )
}