import axios from 'axios';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;

export async function getNewsContent(jwt_token: string){
    const response = await axios.get(`${server_url}/news`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response;
}