import { create } from 'zustand';
interface Project {
    id: number;
    project_name: string;
}
interface ProjectsStore {
    studiesInProject: any[];
    setStudiesInProject: (studies: any[]) => void;
    prevStudiesRef: React.MutableRefObject<any[]>;
    setPrevStudiesRef: (studies: any[]) => void;
    prevIdRef: React.MutableRefObject<number>;
    setPrevIdRef: (id: number) => void;
    selectedProjectForEditName:Project;
    setSelectedProjectForEditName: (selectedProjectForEditName: Project) => void;
    studiesCountInCohort: number | null;
    setStudiesCountInCohort: (count: number | null) => void;
    projectPaginationOffset: any;
    setProjectPaginationOffset: (offset: number) => void;
}

export const useProjectsStore = create<ProjectsStore>()((set) => ({
  prevIdRef: { current: 0 },
  setPrevIdRef: (selectedId) => set({ prevIdRef: { current: selectedId } }),
  studiesInProject: [],
  setStudiesInProject: (studies) => set({ studiesInProject: studies }),
  prevStudiesRef: { current: [] },
  setPrevStudiesRef: (studies) => set({ prevStudiesRef: { current: studies } }),
    selectedProjectForEditName: {id:0, project_name:''},
    setSelectedProjectForEditName: (selectedProjectForEditName) => set({ selectedProjectForEditName }),
  studiesCountInCohort: null,
  setStudiesCountInCohort: (count) => set({ studiesCountInCohort: count }),
  projectPaginationOffset: {
    offset: 0,
    pageSize: 10
  },
  setProjectPaginationOffset: (offset: number) =>
    set((state) => {
      return {
        ...state,
        projectPaginationOffset: {
          ...state.projectPaginationOffset,
          offset: offset
        }
      };
    }),
}));

