import { useAdvancedFilterStore } from "../../../../store/advancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../../store/patient2AdvancedFiltersStore";

type AndFilterButtonProps = {
    id?: number;
}

export default function AndFilterButton({id}: AndFilterButtonProps) {

    const addFilterGroup = useAdvancedFilterStore(state => state.addFilterGroup);
    const addFilterGroup1 = usePatient1AdvancedFiltersStore(state => state.addFilterGroup);
    const addFilterGroup2 = usePatient2AdvancedFiltersStore(state => state.addFilterGroup);

    const newFilterGroup = () => {
        if ( id == null || id == undefined ){
            addFilterGroup();
        } else if (id == 0) {
            addFilterGroup1();
        } else if (id == 1) {
            addFilterGroup2();
        }
    }

    return(
      <div className="flex flex-row mt-4">
        <div className="relative">
          <button
            onClick={newFilterGroup}
              type="button"
              className="w-full rounded bg-blue-600 px-4 py-1 text-sm font-semibold text-white shadow-md hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            + Add Group
          </button>
          
      </div>
      </div>
    )
}