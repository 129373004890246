import EmptyCatalogState from "./DisplayStates/emptyCatalog";
import Pagination from "./Pagination";
import NoResultsCatalog from "./DisplayStates/NoResultsCatalog";
import { useSearchStore } from "../../store/searchStore";
import { useAuthStore } from '../../store/authStore'
import { useUserStore } from '../../store/userStore'
import { useAdvancedFilterStore } from '../../store/advancedFiltersStore';
import { usePatientStore } from '../../store/patientStore';
import { useSearchTypeStore } from '../../store/searchTypeStore';
import { SearchResult, SearchRequest, AdvancedSearchRequest } from '../../types/search';
import { getStudies, getBulkLongitudinalStudies } from '../../utils/request_studies';
import { addToCohort, bulkAddToCohort } from '../../utils/request_cohort';
import StudyCard  from "./StudyCard";
import { LoadingState } from "./DisplayStates/LoadingState";
import { useState, useEffect } from 'react';
import { LoadingSpinner } from '../common/loadingSpinner';
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";

const DEFAULT_PAGE_SIZE = 10;
export default function BulkAdd() {

    // Search state
    const searchRequestPageOffset = useSearchStore(state => state.searchRequestPageOffset)
    const searchRequest = useSearchStore(state => state.searchRequest);

    // Auth state
    const token = useAuthStore(state => state.token);

    // Project state
    const selectedProject = useUserStore(state => state.selectedProject)
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser)
    const updateCohort = useUserStore(state => state.updateCohort)

    const advancedFiltersEnabled = useAdvancedFilterStore(state => state.advancedFiltersEnabled);
    const advancedFilters = useAdvancedFilterStore(state => state.advancedFilters);

    const searchType = useSearchTypeStore(state => state.searchType);

    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ shouldTrigger, setShouldTrigger ] = useState(false);
    const [isShowToaster, setIsShowToaster] = useState(false);
    const patientFilters = usePatientStore(state => state.filters);
    const patientPagination = usePatientStore(state => state.pagination);

    const handleDropDown = (value: boolean) => {
        if(!isProjectBelongsToUser){
            setSelectVolumeOpen(false);
            setIsShowToaster(value);
            return
        }
        if (!isLoading) {
            setSelectVolumeOpen(value);
        }
    };

    const selectVolume = (volume: number) => {
        if (searchType == "study" && volume && selectedProject !== null) {
            const retrieveVolume = async () => {
                setIsLoading(true);
                setSelectVolumeOpen(false);

                let fetchedStudies: SearchResult;
                let tempSearchRequest: SearchRequest | AdvancedSearchRequest;

                if (advancedFilters && advancedFiltersEnabled) {
                    tempSearchRequest = {
                        ...searchRequest,
                        advancedFilters
                    }
                } else {
                    tempSearchRequest = searchRequest;
                }

                tempSearchRequest = {
                    ...tempSearchRequest,
                    pagination: {
                        pageSize: volume,
                        offset: tempSearchRequest.pagination.offset,
                        start_index: tempSearchRequest.pagination.offset * DEFAULT_PAGE_SIZE,
                    }
                }

                fetchedStudies = await getStudies(tempSearchRequest, token);
                let allAdds = fetchedStudies.results.map((study) => {
                    return {
                        institution: study.institution,
                        row_id: study.row_id
                    }
                });
                let alreadyAccepted = selectedProject.cohort.studies.map((study) => study.row_id)
                allAdds = allAdds.filter((add) => { return !alreadyAccepted.includes(add.row_id) });
                const updatedProjects = await bulkAddToCohort(selectedProject.cohort.id, selectedProject.id, allAdds, token);
                updateCohort(updatedProjects)
                setIsLoading(false);
            }

            retrieveVolume()
        } else if (searchType == "patient" && volume && selectedProject !== null) {
            const retrieveVolume = async () => {
                setIsLoading(true);
                setSelectVolumeOpen(false);

                let fetchedStudies: SearchResult;
                let tempFilters = patientFilters;

                tempFilters[0].search_summary = "";
                tempFilters[1].search_summary = "";
                tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms)
                tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms)
                fetchedStudies = await getBulkLongitudinalStudies(tempFilters, patientPagination.offset, volume, token);

                let allAdds = fetchedStudies.results.map((study) => {
                    const isIncludedInProject = selectedProject?.cohort?.studies.some(s => s.row_id === study.row_id);
                    if (isIncludedInProject) {
                        return null;
                    }
                    return addToCohort(selectedProject?.cohort?.id, selectedProject?.id, study.row_id, study.institution, token);
                }).filter((promise) => {return promise});

                let projectPromises = await Promise.all(allAdds);

                // Get project with most studies to update
                let latestCohort = projectPromises.reduce(function(prev, current) { return (prev && prev.studies.length > current.studies.length) ? prev : current });
                updateCohort(latestCohort);
                setIsLoading(false);
            }

            retrieveVolume()
        }
    }

    return (
        <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)} >
            <button disabled={isLoading} className={`bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ${!isProjectBelongsToUser ? 'bg-gray-200': ''}`} type="button">
                Add
                {isLoading ? <span className="px-1"> <LoadingSpinner message="" size="sm" flex="row"/></span> : <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg> }
            </button>
            <div id="dropdownHover" className={`${
                selectVolumeOpen ? "absolute" : "hidden"
            }`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2" aria-labelledby="dropdownHoverButton">
                  <li onClick={() => selectVolume(50)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 50</a>
                  </li>
                  <li onClick={() => selectVolume(100)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 100</a>
                  </li>
                  <li onClick={() => selectVolume(300)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 300</a>
                  </li>
                </ul>
            </div>
            <div id="meassge" className={`${isShowToaster ? "absolute" : "hidden"} whitespace-nowrap w-auto right-1 left-auto`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2" aria-labelledby="dropdownHoverButton">
                <li className="text-white px-2">Action cannot be performed for a teammate.</li>
                </ul>
            </div>
        </div>
    )
}