const Labels = {
    agreed1:
      "I agree that Gradient is granting me permission to use Atlas for free for up to seven business days for personal, non-commercial research purposes only.",
    agreed2: "I agree that I have read and understood the terms and conditions of the Atlas Terms of Service.",
    agreed3: "I agree that I shall not reproduce, distribute, publish, sell, sublicense, or otherwise provide the data to anyone else.",
    agreed4: "I agree that Gradient provides the data AS-IS and makes no representations or warranties of any kind.",
    agreed5:
      "I agree that I shall not attempt to discover any PHI removed from the data; and, if I do discover PHI in the data I will report it to Gradient immediately.",
    agreed6: "I understand the data is pseudoanonymized where real names are replaced with fake names.",
  };

  export const AgreementLists =[
    {
      id: "agreed1",
      label: Labels.agreed1,
      checked: false
    },
    {
      id: "agreed2",
      label: Labels.agreed2,
      checked: false
    },
    {
      id: "agreed3",
      label: Labels.agreed3,
      checked: false
    },
    {
      id: "agreed4",
      label: Labels.agreed4,
      checked: false
    },
    {
      id: "agreed5",
      label: Labels.agreed5,
      checked: false
    },
    {
      id: "agreed6",
      label: Labels.agreed6,
      checked: false
    }
  ]