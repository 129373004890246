import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import cn from 'classnames';
import { useAdvancedFilterStore } from '../../../../../store/advancedFiltersStore';
import { usePatient1AdvancedFiltersStore } from "../../../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../../../store/patient2AdvancedFiltersStore";

const publishingOptions = [
  { title: 'All', logic: 'AND',description: 'Every single filter must match', current: true },
  { title: 'Any', logic: 'OR', description: 'One or more filters must match', current: false },
]


type AllOrAnySelectGroupsProps = {
  id?: number
}

export default function AllOrAnySelectGroups({id}: AllOrAnySelectGroupsProps) {
  // Filter state
  const groupsMatch = useAdvancedFilterStore(state => state.groupsMatch);
  const setGroupsMatch = useAdvancedFilterStore(state => state.setGroupsMatch);
  const [selected, setSelected] = useState(publishingOptions[0]);

  // patient1
  const groupsMatch1 = usePatient1AdvancedFiltersStore(state => state.groupsMatch);
  const setGroupsMatch1 = usePatient1AdvancedFiltersStore(state => state.setGroupsMatch);

  // patient2
  const groupsMatch2 = usePatient2AdvancedFiltersStore(state => state.groupsMatch);
  const setGroupsMatch2 = usePatient2AdvancedFiltersStore(state => state.setGroupsMatch);

  useEffect(() => {
    // find the option that is equivalent to the groupsMatch
    const selectedOption = publishingOptions.find(option => option.logic === groupsMatch);
    const selectedOption1 = publishingOptions.find(option => option.logic === groupsMatch1);
    const selectedOption2 = publishingOptions.find(option => option.logic === groupsMatch2);
    if ((id == null || id == undefined) && selectedOption) {
      setSelected(selectedOption);
    } else if (id == 0 && selectedOption1) {
      setSelected(selectedOption1);
    } else if (id == 1 && selectedOption2) {
      setSelected(selectedOption2);
    }
  }, [groupsMatch, groupsMatch1, groupsMatch2]);

  const handleSelect = (e: any) => {
    setSelected(e);
    if (id == null || id == undefined) {
      setGroupsMatch(e.logic);
    } else if (id == 0) {
      setGroupsMatch1(e.logic);
    } else if (id == 1) {
      setGroupsMatch2(e.logic);
    }
  }

  return (
    <Listbox value={selected} onChange={(e) => handleSelect(e)}>
      {({ open }) => (
        <>
          <div className="flex flex-col">
                <Listbox.Button className="relative w-16 cursor-default rounded-md bg-white py-0.5 p-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">{selected.title}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute left-20 z-10 mt-10 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-row">
                        <div className="flex justify-between">
                          <p className="font-bold">{option.title}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-blue-600'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={cn(active ? 'text-blue-200' : 'text-gray-500', 'ml-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
