import axios from "axios";
const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;
export async function deleteProject(
    project_id: number,
    cohort_id: number,
    jwt_token: string) {
    const response = await axios.delete(`${server_url}/project`, {
        params: {
            project_id: project_id,
            cohort_id: cohort_id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response;
}
