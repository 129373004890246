import { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { useChartsStore } from "../../../../../store/chartsStore";
import { chartsData, ChartTitles } from "../ChartOptions";

interface StatCheckedItems {
  [value: string]: boolean;
}

export function EditStats() {
  // Charts Store
  const statCheckedItems = useChartsStore((state) => state.statCheckedItems);
  const setStatCheckedItems = useChartsStore((state) => state.setStatCheckedItems);

  const initialCheckedTitles = [
    ChartTitles.SEX,
    ChartTitles.AGE,
    ChartTitles.MANUFACTURER,
    ChartTitles.SOURCE,
    ChartTitles.INSTITUTION
  ];

  useEffect(() => {
    const initialCheckedItems: StatCheckedItems = {};
    chartsData?.forEach((item) => {
      initialCheckedItems[item.title] = initialCheckedTitles.includes(item.title);
    });
    setStatCheckedItems(initialCheckedItems);
  }, []);

  const handleChange = (title: string) => {
    const newCheckedItems = {
      ...statCheckedItems,
      [title]: !statCheckedItems[title]
    };
    setStatCheckedItems(newCheckedItems);
  };

  return (
    <div className="rounded bg-white px-0.5 mt-2 ml-3 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
      <Menu as="div" className="relative inline-block text-left z-30">
        <Menu.Button>
          <SquaresPlusIcon
            className="h-5 w-5 mt-1 p-0 text-gray-500 hover:text-gray-400 whitespace-nowrap"
            aria-hidden="true"
            title="select stats to display"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <div className="w-56 absolute top-0 left-8 px-3 pt-2 pb-4 rounded-sm bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
            <div className="pb-1 pl-1">
              <h2 className="font-bold">Show Distribution</h2>
            </div>
            <Menu.Items>
              {chartsData?.map((item) => (
                <div className="px-1" key={item.title}>
                  <div className="cursor-pointer flex items-center" onClick={() => handleChange(item.title)}>
                    <input
                      type="checkbox"
                      checked={statCheckedItems[item.title] || false}
                      readOnly
                      className="h-4 w-4 rounded-sm mr-3 cursor-pointer"
                    />
                    <span className="py-1 text-gray-800 font-semibold hover:text-gray-950">{item.title}</span>
                  </div>
                </div>
              ))}
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    </div>
  );
}
