import { create } from "zustand";

interface FilterDropDownStore {
  startIndex: number;
  setStartIndex: (startIndex: number)=>void;
  endIndex:number;
  setEndIndex: (endIndex: number)=>void;
  allData: Array<{groupingField: any,allDataList:string[]}>;
  setAllData: (groupingField:string,allData: string[])=>void;
  allFilterData: Array<{groupingField: any,listFilterData:string[]}>;
  setAllFilterData: (groupingField:string,allFilterData: string[])=>void;
  listAllData: Array<{groupingField: any,listData:string[]}>;
  setAllListData: (groupingField:string,listData: string[] | any)=>void;
  selectedData: Array<{groupingField:string,selectedItems:string[]}>;
  setSelectedData:  (groupingField:string,selectedData: string[] | any)=>void;
  clearButtonClicked: boolean;
  setClearButtonClicked: (clearButtonClicked: boolean)=>void;
  crossFilterSelection: any;
  setCrossFilterSelection: (crossFilterSelection: any) => void;
  filterDropdowns: string[];
  setFilterDropdowns:(filterDropdowns: string[])=> void;

}

export const useFilterDropDownStore = create<FilterDropDownStore>()((set) => ({
  filterDropdowns: [
    "modality",
    "body_part_examined",
    "study_description",
    "series_description",
    "manufacturer",
    "manufacturer_model_name",
    "institution",
    "imputed_ethnicity",
    "imputed_race"
  ],
  setFilterDropdowns:(filterDropdowns:string[])=>set({filterDropdowns}),
  crossFilterSelection: {},
  setCrossFilterSelection: (crossFilterSelection: any) =>
    set({ crossFilterSelection }),
  clearButtonClicked: false,
  setClearButtonClicked: (clearButtonClicked:boolean)=> set({clearButtonClicked}),
  startIndex: 0,
  setStartIndex:(startIndex:number) => set({startIndex}),
  endIndex: 499,
  setEndIndex:(endIndex:number) => set({endIndex}),
  allData: [],
  setAllData:(groupingField:string,allDataList:string[])=>
  set(state=>{
    const fieldIndex = state.allData.findIndex(item=> item.groupingField === groupingField);
    const allDropDownDataList = [...state.allData];
    if(fieldIndex === -1){
      allDropDownDataList.push({
        groupingField: groupingField,
        allDataList:allDataList
      })
    }else{
      allDropDownDataList.splice(fieldIndex,1);
      allDropDownDataList.push({
        groupingField: groupingField,
        allDataList:allDataList
      })
    }
   return  { 
    ...state,
    allData:allDropDownDataList
  }
}),
  allFilterData: [],
  setAllFilterData:(groupingField:string,listFilterData:string[])=>
  set(state=>{
      const fieldIndex = state.allFilterData.findIndex(item=> item.groupingField === groupingField);
      const allDropDownFilterData = [...state.allFilterData];
      if(fieldIndex === -1){
        allDropDownFilterData.push({
          groupingField: groupingField,
          listFilterData:listFilterData
        })
      }else{
        allDropDownFilterData.splice(fieldIndex,1);
        allDropDownFilterData.push({
          groupingField: groupingField,
          listFilterData:listFilterData
        })
      }
     return  { 
      ...state,
      allFilterData:allDropDownFilterData
    }
  }),
  listAllData:[],
  setAllListData:(groupingField:string,listData:string[])=>
  set(state=>{
      const fieldIndex = state.listAllData.findIndex(item=> item.groupingField === groupingField);
      const allDropDownData = [...state.listAllData];
      if(fieldIndex === -1){
        allDropDownData.push({
          groupingField: groupingField,
          listData:listData
        })
      }else{
        allDropDownData.splice(fieldIndex,1);
        allDropDownData.push({
          groupingField: groupingField,
          listData:listData
        })
      }
     return  { 
      ...state,
      listAllData:allDropDownData
    }
  }),
  selectedData:[],
  setSelectedData:(groupingField:string,selectedItems:string[])=>
  set(state=>{
      const fieldIndex = state.selectedData.findIndex(item=> item.groupingField === groupingField);

      const allSelectedData = [...state.selectedData];
      if(fieldIndex === -1){
        allSelectedData.push({
          groupingField: groupingField,
          selectedItems:selectedItems
        })
      }else{
        allSelectedData.splice(fieldIndex,1);
        allSelectedData.push({
          groupingField: groupingField,
          selectedItems:selectedItems
        })
      }
     return  { 
      ...state,
      selectedData:allSelectedData
    }
  }),
}))
