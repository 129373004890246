import SearchByStudy from "./SearchByStudy";
import SearchByPatient from "./SearchByPatient";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import SaveSearches from "./SaveSearches";
import { useSavedSearchStore } from "../../store/savedSeachStore";
import { useSearchStore } from "../../store/searchStore";

export default function FiltersControlPanel() {
    const searchType = useSearchTypeStore(state => state.searchType);
    const setSearchType = useSearchTypeStore(state => state.setSearchType);
    const setShouldFetchNumberOfStudies = useSearchStore(state => state.setShouldFetchNumberOfStudies);
    const setIsFetchingNumberOfStudies = useSearchStore(state => state.setIsFetchingNumberOfStudies)
    const setNumberOfStudies = useSearchStore(state => state.setNumberOfStudies);

    const showSavedSearchTab = useSavedSearchStore(state => state.showSavedSearchTab);
    const setShowSavedSearchTab = useSavedSearchStore(state => state.setShowSavedSearchTab);

    const handleSearchType = (type: string) => {
        setSearchType(type);
        setShowSavedSearchTab(false);
    }
    return (
        <div className="flex flex-col">
            <div className="mb-6">
                <ul className="flex flex-row -mb-px text-lg text-center text-gray-500 dark:text-gray-400 whitespace-nowrap">
                    <li className="me-2 cursor-default" onClick={() => {
                        setNumberOfStudies(null);
                        handleSearchType("study");
                        setShouldFetchNumberOfStudies(true);
                        setIsFetchingNumberOfStudies(true);
                    }}>
                        <a className={`${searchType == "study" && !showSavedSearchTab ? "active border-b-2 text-blue-500 border-blue-500" : "border-transparent"} flex items-center justify-center rounded-t-lg hover:text-blue-500 hover:border-blue-500 dark:hover:text-blue-500 group`}>
                            <svg className={`w-4 h-4 ${searchType == "study" && !showSavedSearchTab ? "active text-blue-500" : "text-gray-400"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16.5A2.493 2.493 0 0 1 6.51 18H6.5a2.468 2.468 0 0 1-2.4-3.154 2.98 2.98 0 0 1-.85-5.274 2.468 2.468 0 0 1 .921-3.182 2.477 2.477 0 0 1 1.875-3.344 2.5 2.5 0 0 1 3.41-1.856A2.5 2.5 0 0 1 11 3.5m0 13v-13m0 13a2.492 2.492 0 0 0 4.49 1.5h.01a2.467 2.467 0 0 0 2.403-3.154 2.98 2.98 0 0 0 .847-5.274 2.468 2.468 0 0 0-.921-3.182 2.479 2.479 0 0 0-1.875-3.344A2.5 2.5 0 0 0 13.5 1 2.5 2.5 0 0 0 11 3.5m-8 5a2.5 2.5 0 0 1 3.48-2.3m-.28 8.551a3 3 0 0 1-2.953-5.185M19 8.5a2.5 2.5 0 0 0-3.481-2.3m.28 8.551a3 3 0 0 0 2.954-5.185"></path>
                            </svg><span className="mx-2">Studies</span>
                        </a>
                    </li>
                    <li className="me-2 cursor-default" onClick={() => handleSearchType("patient")}>
                        <a className={`${searchType == "patient" && !showSavedSearchTab ? "active border-b-2 text-blue-500 border-blue-500" : "border-transparent"} flex items-center justify-center rounded-t-lg hover:text-blue-500 hover:border-blue-500 dark:hover:text-blue-500 group`}>
                            <svg className={`w-4 h-4 ${searchType == "patient" && !showSavedSearchTab ? "active text-blue-500" : "text-gray-400"}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                            </svg><span className="mx-2">Patients</span>
                        </a>
                    </li>
                    <li className="me-2 cursor-default" onClick={() => setShowSavedSearchTab(true)}>
                        <a className={`${showSavedSearchTab ? "active border-b-2 text-blue-500 border-blue-500" : "border-transparent"} flex items-center justify-center rounded-t-lg hover:text-blue-500 hover:border-blue-500 dark:hover:text-blue-500 group`}>
                            <svg className={`w-4 h-4 ${showSavedSearchTab ? "active text-blue-500" : "text-gray-400"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg><span className="mx-2">Saved Searches</span>
                        </a>
                    </li>
                </ul>
            </div>
            {searchType === "study" && !showSavedSearchTab ? <SearchByStudy /> : null}
            {searchType === "patient" && !showSavedSearchTab ? <SearchByPatient /> : null}
            {showSavedSearchTab ? <SaveSearches /> : null}
        </div>
    )
}