import SuccessfulOrderToast from "../pages/Projects/components/SuccessfulOrder"
import Sidebar from "./sidebar/Sidebar"
import TopNavigation from "./top-nav/TopNavigation"
import { useEffect, useState } from "react"
import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react"
import { useAuthStore } from "../store/authStore"
import PatientStudiesModal from "./modals/PatientStudiesModal"
import ExpandedThumbnailModal from "./catalogDisplay/ExpandedThumbnailModal";
import { usePostHog } from "posthog-js/react"
import LoadingPatientStudiesModal from "./modals/LoadingPatientStudiesModal"
import CopiedToast from "./toasts/CopiedToast"
import GeneralLoader from "./common/GeneralLoader"
import { useSearchStore } from "../store/searchStore"
import { useFilterStore } from "../store/filterStore"
import { usePolicyTermsModalStore } from "../store/policyTermsStore"
import PolicyTermsModal from "./modals/PolicyTerms/PolicyTermsModal"
import ProjectCreationToast from "./toasts/ProjectCreationToast"

const Layout = ({ children }: any) => {
    const { isSignedIn, getToken } = useAuth();
    const posthog = usePostHog();
    const { user, isLoaded: isUserLoaded } = useUser();

    //filter state
    const filters = useFilterStore(state => state.filters);
    
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    // JWT
    const setToken = useAuthStore(state => state.setToken);

    // Local State
    const [ orgInitiated, setOrgInitiated ] = useState(false);

    const { isLoaded: isOrgLoaded, userMemberships, setActive } = useOrganizationList({
        userMemberships: {
            infinite: true,
        }
    });

  
    useEffect(() => {
      if (user && isUserLoaded) {
        posthog?.identify(user.id, {
          email: user.primaryEmailAddress?.emailAddress
        })
      }
    }, [posthog, user?.id, user?.primaryEmailAddress?.emailAddress, isUserLoaded])


    useEffect(() => {
        if (isSignedIn) {
            const getAccessToken = async () => {
                const token = await getToken({
                    template: 'long_lived_token' // Default is 60 seconds, we made a 24 hours token template
                });
                if (token) {
                    setToken(token);
                }
            }
            getAccessToken();
        }
    }, [isSignedIn]);

    useEffect(() => {
        if(isOrgLoaded && userMemberships.data.length > 0 && !orgInitiated) {
            setActive({
                organization: userMemberships.data[0].organization
            })
            setOrgInitiated(true);
        }
    }, [isOrgLoaded, userMemberships])


    return (
      <>
        {filters.cross_filter && isRetrievingStudies && (
          <div className="fixed w-full" style={{ zIndex: 99 }}>
            <GeneralLoader />
          </div>
        )}
        <div>
          <Sidebar />
          <div className="lg:pl-20">
            <TopNavigation />
            <main>
                {children}
            </main>
          </div>
          <PatientStudiesModal />
          <LoadingPatientStudiesModal />
          <ExpandedThumbnailModal />
          <SuccessfulOrderToast />
          <ProjectCreationToast />
          <CopiedToast />
          <PolicyTermsModal/>
        </div>
      </>
    );
}


export default Layout;