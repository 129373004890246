import { create } from "zustand";

interface AgreementModalStore {
    showTermsOfServiceModal: boolean;
    setShowTermsOfServiceModal: (show: boolean) => void;
    showAgreementModal: boolean;
    setShowAgreementModal: (show: boolean) => void;
}

export const useAgreementModalStore = create<AgreementModalStore>()((set) => ({
    showTermsOfServiceModal: false,
    setShowTermsOfServiceModal: (show: boolean) => set({ showTermsOfServiceModal: show }),
    showAgreementModal: false,
    setShowAgreementModal: (show: boolean) => set({ showAgreementModal: show })
}))