import { useEffect, useState, useRef } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import EditSavedSearchName from "../modals/modalContents/EditSavedSearchName";
import CustomModal from "../modals/CustomModal";
import { useSavedSearchStore } from "../../store/savedSeachStore";
import dateFormatting from "../common/DateFormate";
import { useAuthStore } from "../../store/authStore";
import { getSaveSearchResult } from "../../utils/request_save_searches";
import SavedSearchPagination from "../SavedSearchPagination";
import DeleteSavedSearch from "../modals/modalContents/DeleteSavedSearch";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { usePatientStore } from "../../store/patientStore";
import { useFilterStore } from "../../store/filterStore";
import { useAdvancedFilterStore } from "../../store/advancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../store/patient2AdvancedFiltersStore";
import {useUpdateFilter}  from '../../utils/updateFilter';
import {useClearAllFilters}  from '../../utils/clearFilter';
import { Listbox, Transition } from '@headlessui/react'

type optionItem = {
  value: string;
  label: string;
}

export default function SaveSearches() {
  // Auth state
  const token = useAuthStore((state) => state.token);
  //saved search state
  const setShowSavedSearchTab = useSavedSearchStore((state) => state.setShowSavedSearchTab);
  const setSavedSearchPaginationOffset = useSavedSearchStore((state) => state.setSavedSearchPaginationOffset);
  const savedSearchPaginationOffset = useSavedSearchStore((state) => state.savedSearchPaginationOffset);
  const setLoader = useSavedSearchStore((state) => state.setLoader);
  const loader = useSavedSearchStore((state) => state.loader);
  const savedSearchList = useSavedSearchStore((state) => state.savedSearchList);
  const setSavedSearchList = useSavedSearchStore((state) => state.setSavedSearchList);
  // Search type state
  const setSearchType = useSearchTypeStore((state) => state.setSearchType);
  // Patient state
  const setPatientFilters = usePatientStore((state) => state.setPatientFilters);
  const setTimeBetween = usePatientStore((state) => state.setTimeBetween);
  //filter state
  const setSearchTerms = useFilterStore((state) => state.setSearchTerms);
  //advanced filter state
  const setAdvancedFiltersEnabled = useAdvancedFilterStore((state) => state.setAdvancedFiltersEnabled);
  const setFilterGroups = useAdvancedFilterStore((state) => state.setFilterGroups);
  //advanced patient 1 filter state
  const setAdvancedFilters1 = usePatient1AdvancedFiltersStore((state) => state.setAdvancedFilters);
  const setFilterGroups1 = usePatient1AdvancedFiltersStore((state) => state.setFilterGroups);
  const setAdvancedFiltersEnabled1 = usePatient1AdvancedFiltersStore((state) => state.setAdvancedFiltersEnabled);
  //advanced patient 2 filter state
  const setAdvancedFilters2 = usePatient2AdvancedFiltersStore((state) => state.setAdvancedFilters);
  const setFilterGroups2 = usePatient2AdvancedFiltersStore((state) => state.setFilterGroups);
  const setAdvancedFiltersEnabled2 = usePatient2AdvancedFiltersStore((state) => state.setAdvancedFiltersEnabled);
  //local state
  const [currentSelectedSearchName, setCurrentSelectedSearchName] = useState<string>("");
  const [showEditSearchModal, setShowEditSearchModal] = useState<boolean>(false);
  const [showDeleteSearchModal, setShowDeleteSearchModal] = useState<boolean>(false);

  const {updateFilter} = useUpdateFilter();
  const {clearFilter} = useClearAllFilters();
  
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const showOptions = [
    { value: "studies", label: "Study searches" },
    { value: "longitudinal", label: " Patient searches" },
  ];
  const [isSelected, setIsSelected] = useState<optionItem>(showOptions[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (optionItem : optionItem) => {
    setIsSelected(optionItem);
    setSavedSearchPaginationOffset(0);
    setIsOpen(!isOpen)
  };

  const getSearchResult = async (token: string) => {
    setLoader(true);
    setSavedSearchList([]);
    try {
      if (token) {
        const fetchSearchResult = await getSaveSearchResult(savedSearchPaginationOffset, isSelected.value, token);
        if (fetchSearchResult.status === 200) {
          const savedSearchData = fetchSearchResult?.data;
          setSavedSearchList(savedSearchData);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getSearchResult(token);
  }, [token, isSelected, savedSearchPaginationOffset]);

  const handleToRenameSearch = (item: any) => {
    setCurrentSelectedSearchName(item);
    setShowEditSearchModal(true);
  };


  const handleShowSearchResult = (item: any) => {
    setShowSavedSearchTab(false);
    clearFilter();

    if (!item) return;

    const { search_params, path } = item;

    if (search_params.search_terms) {
        setSearchTerms(search_params.search_terms);
    }

    if (path === "studies") {
        handleStudiesPath(search_params);
    } else if (path === "longitudinal") {
        handleLongitudinalPath(search_params);
    }
};

const handleStudiesPath = (search_params: any) => {
    setSearchType("study");

    if (search_params.filter_groups && search_params.filter_groups.length > 0) {
        setAdvancedFiltersEnabled(true);
        setFilterGroups(search_params.filter_groups);
    } else {
        setAdvancedFiltersEnabled(false);
        setFilterGroups([]);
    }
    setTimeout(() => {
      updateFilter(search_params.search_request.filters);
    }, 3000)
};

const handleLongitudinalPath = (search_params: any) => {
    setSearchType("patient");

    const {
        filter_groups1,
        filter_groups2,
        timeBetween,
        search_request
    } = search_params;
    setTimeout(() => {
      if (filter_groups2) {
          setAdvancedFiltersEnabled(true);
          setAdvancedFilters2(filter_groups2);
          setFilterGroups2(filter_groups2);
          setAdvancedFiltersEnabled2(true);
      } else {
          setAdvancedFiltersEnabled(false);
          setAdvancedFiltersEnabled2(false);
          setFilterGroups2([]);
      }

      if (filter_groups1) {
          setAdvancedFiltersEnabled(true);
          setAdvancedFilters1(filter_groups1);
          setFilterGroups1(filter_groups1);
          setAdvancedFiltersEnabled1(true);
      } else {
          setAdvancedFiltersEnabled(false);
          setAdvancedFiltersEnabled1(false);
          setFilterGroups1([]);
      }

      setTimeBetween(timeBetween);
      setPatientFilters(search_request);
  }, 3000)
};

useEffect(() => {
  function handleMouseDown(event: MouseEvent) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }
  document.addEventListener("mousedown", handleMouseDown);

  return () => {
    document.removeEventListener("mousedown", handleMouseDown);
  };
});

  return (
    <>
      <div className="flex flex-row rounded-md gap-2.5">
        <p className="mt-[7px] text-base">Show</p>
        <div className='w-3/5' ref={dropdownRef}>
        <Listbox
          as="div"
          className="space-y-1"
        >
          {() => (
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    className="text-sm cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:ring-inset focus:border focus:ring-blue-600 focus:ring-1 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className={`block truncate text-gray-900`}>
                      {isSelected.label}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>
                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                >
                  <Listbox.Options
                    static
                    className="text-base max-h-60 rounded-md py-1 leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {showOptions.map((optionItem, idx) => {
                      return (
                        <Listbox.Option key={idx} value={isSelected.value}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white bg-blue-600"
                                  : "text-gray-900"
                              } cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-4 flex flex-row items-center justify-start`}
                              onClick={()=>handleSelect(optionItem)}
                            >
                                <div className="flex flex-row w-full justify-between items-center">
                                  <span className="font-normal block truncate">
                                    {optionItem.label}
                                  </span>
                                </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
          )}
        </Listbox>
        </div>
      </div>
      <div className="w-full flex border-gray-300 border mt-10 h-96 shadow-[0px_0px_10px_1px_rgba(235,235,235,1)]">
        <div className="flex flex-col w-full shadow-[0px_0px_10px_1px_rgba(235,235,235,1)] ">
          <div className="flex border-b border-gray-300 text-sm">
            <span className="w-1/2 ml-1">Name</span>
            <span className="w-1/2 justify-center ml-11">Date Created</span>
          </div>
          {savedSearchList?.length > 0 &&
            !loader &&
            savedSearchList?.map((item: any) => {
              return (
                <div
                  className="flex justify-between border-b border-gray-300 text-sm font-semibold h-10 hover:bg-gray-200 group/item"
                  key={item?.id}>
                  <span className="w-1/2 ml-1 mt-2 truncate cursor-pointer" onClick={() => handleShowSearchResult(item)} title={item?.search_name}>
                    {item?.search_name}
                  </span>
                  <span className="mt-2 text-sm cursor-pointer " onClick={() => handleShowSearchResult(item)}>
                    {dateFormatting(item?.created_date)}
                  </span>
                  <span className="mt-2 group/edit invisible hover:bg-slate-200 group-hover/item:visible mr-3">
                    <PencilIcon
                      className="size-6 h-5 ml-4 mt-1 cursor-pointer text-grey-700 w-2/5 group-hover/edit:text-gray-700"
                      onClick={() => handleToRenameSearch(item)}
                    />
                  </span>
                </div>
              );
            })}
          {loader && <div className="flex justify-center mt-2 text-sm">Loading.....</div>}
          {savedSearchList?.length === 0 && !loader && <div className="flex justify-center mt-2 text-sm">No saved searches to show</div>}
          {savedSearchList?.message && !loader && <div className="flex justify-center mt-2 text-sm">{savedSearchList?.message}</div>}
        </div>
      </div>
      <SavedSearchPagination />
      {showEditSearchModal && (
        <CustomModal showCustomModal={showEditSearchModal} setCustomModal={setShowEditSearchModal}>
          <EditSavedSearchName
            currentSelectedItem={currentSelectedSearchName}
            setShowEditSearchModal={setShowEditSearchModal}
            setShowDeleteSearchModal={setShowDeleteSearchModal}
          />
        </CustomModal>
      )}
      {showDeleteSearchModal && (
        <CustomModal showCustomModal={showDeleteSearchModal} setCustomModal={setShowDeleteSearchModal}>
          <DeleteSavedSearch
            currentSelectedSearchName={currentSelectedSearchName}
            setShowDeleteSearchModal={setShowDeleteSearchModal}
            isSelected={isSelected.value}
          />
        </CustomModal>
      )}
    </>
  );
}
