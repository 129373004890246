import { useOrganization } from "@clerk/clerk-react"
import SuccessfulInvitationToast from "./SuccessfulInviteToast";

export default function InvitationsAndMembersTable() {
    const { invitations, memberships,  } = useOrganization({
        memberships: true,
        invitations: true,
    });


    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Role
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Membership Status
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span className="sr-only">Edit</span>
                    </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {memberships ?
                        memberships.data?.map((member) => (
                    <tr key={member.id} className="even:bg-gray-50">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {member.publicUserData.firstName} {member.publicUserData.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.publicUserData.identifier}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.role}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-600/20">
                                Active Member
                            </span>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button onClick={member.destroy} disabled={true} className="text-red-600 hover:text-red-900 disabled:hover:cursor-not-allowed">
                            Remove User<span className="sr-only">, {member.publicUserData.firstName}</span>
                        </button>
                        </td>
                    </tr>
                    )): null}
                    {invitations ? 
                        invitations.data?.map((invitation) => (
                            <tr key={invitation.id} className="even:bg-gray-50">
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    --
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitation.emailAddress}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitation.role}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                    Invitation Sent
                                </span>
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                <button onClick={invitation.revoke} className="text-indigo-600 hover:text-indigo-900">
                                    Revoke<span className="sr-only"/>
                                </button>
                                </td>
                            </tr>
                        )) : null}
                </tbody>
                </table>
            </div>
            </div>
            <SuccessfulInvitationToast/>
        </div>
    )
}