import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom";


export default function NoStudiesInProject() {
    return (
        <div className="flex flex-col items-center justify-center h-full mt-32">
            <InformationCircleIcon className="h-12 w-12 mx-auto text-gray-700" />
            <h2 className="text-xl font-semibold text-gray-700 mb-4">No studies found</h2>
            <p className="text-gray-500">It looks like there are no studies added to this project. You can search and add them&nbsp;
                <Link to="/" className="text-blue-500 hover:underline">here</Link>.
            </p>
        </div>
    )
}

