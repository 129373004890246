import axios from 'axios';


const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;

export async function createSearchParams(params: any , path :string, jwt_token: any) {
    const response = await axios.post(`${server_url}/search_params`, {
      params:params,
      path: path
    },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })


    return response.data;
    
}

export async function getSearchParams(id: number, jwt_token: any) {
  const response = await axios.get(`${server_url}/search_params`, {
      params: {
        id: id,
      }, 
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${jwt_token}`
      }
  })

  return response.data;
}