import { debounce } from '../utils/debounce'
import { useAuthStore } from '../store/authStore'
import { useEffect, Fragment, useRef, useState } from 'react'
import { useSearchStore } from '../store/searchStore';
import { useFilterStore } from '../store/filterStore';
import { Dialog, Transition } from '@headlessui/react'
import CatalogDisplayList from '../components/catalogDisplay/catalog'
import { allValueFieldsAreFilled } from '../utils/data_formatting';
import FiltersControlPanel from '../components/filterControls/FilterControlPanel'
import { AdvancedFilterSearchRequest } from '../types/advancedFilters';
import { SearchResult, SearchRequest, AdvancedSearchRequest } from '../types/search';
import { getStudies, getLongitudinalStudies, getStudyCount, getPatientStudyCount} from '../utils/request_studies'
import { updateSettings } from '../utils/request_user'
import { formatSearchQueryForLucene } from "../utils/lucene_helpers";
import { useAdvancedFilterStore } from '../store/advancedFiltersStore';
import { usePatient1AdvancedFiltersStore } from '../store/patient1AdvancedFiltersStore';
import { usePatient2AdvancedFiltersStore } from '../store/patient2AdvancedFiltersStore';
import { useSearchTypeStore } from "../store/searchTypeStore";
import { usePatientStore } from "../store/patientStore";
import { useSearchParamsStore } from "../store/searchParamsStore";
import axios, { CancelTokenSource } from 'axios';
import { fetchAllStudyByGroup } from '../utils/fetch_all_study_by_group';
import { useFilterDropDownStore } from '../store/filterDropDownStore';
import {crossDataFiltering} from '../utils/cross_filtering_in_dropdowns'
import { useSortStore } from '../store/sortStore';
import TermsOfServiceModal from '../components/modals/TermsOfService/TermsOfServiceModal';
import { useAgreementModalStore } from '../store/agreementModalStore';
import { createSearchParams } from '../utils/search_params';
import { useToastStore } from '../store/toastStore';
import { requestStudiesCountInCohort } from '../utils/request_cohort';
import { useUserStore } from '../store/userStore';
import { useProjectsStore } from '../store/projectsStore';
import { modalityOptionsDefault, modalityOptionsDefaultLength } from '../components/filterControls/modalityFilter/modalityOptions';
import { manufacturerSelectionsDefault, manufacturerSelectionsDefaultLength } from '../components/filterControls/manufacturerFilter/manufacturerOptions';
import { alldropdowns, advancedFilterDropdowns } from '../components/filterControls/dropdownList'

const DOMAIN_URL = window.location.origin;    

export default function Main() {

    const setCopiedToastMessage = useToastStore(state => state.setCopiedToastMessage);

    // Auth state
    const token = useAuthStore(state => state.token);

    // Search Params State
    const searchParamsLoaded = useSearchParamsStore(state => state.searchParamsLoaded)

    // Filter state
    const searchTerms = useFilterStore(state => state.searchTerms);
    const filters = useFilterStore(state => state.filters);
    const isFiltersPanelOpen = useFilterStore(state => state.isFiltersPanelOpen);
    const setIsFiltersPanelOpen = useFilterStore(state => state.setIsFiltersPanelOpen);

    // Search state
    const setStudies = useSearchStore(state => state.setStudies);
    const studies = useSearchStore(state => state.studies);
    const setActiveStudies = useSearchStore(state => state.setActiveStudies);
    const searchRequest = useSearchStore(state => state.searchRequest);
    const updateSearchRequestFilters = useSearchStore(state => state.updateSearchRequestFilters);
    const updateSortBy = useSearchStore(state => state.updateSortBy);
    const setShouldFetchStudies = useSearchStore(state => state.setShouldFetchStudies);
    const setIsRetrievingStudies = useSearchStore(state => state.setIsRetrievingStudies);
    const setNoResultsFound = useSearchStore(state => state.setNoResultsFound);
    const setNumberOfStudies = useSearchStore(state => state.setNumberOfStudies);
    const setSearchRequestPageOffset = useSearchStore(state => state.setSearchRequestPageOffset);
    const shouldFetchNumberOfStudies = useSearchStore(state => state.shouldFetchNumberOfStudies);
    const setShouldFetchNumberOfStudies = useSearchStore(state => state.setShouldFetchNumberOfStudies);
    const setIsFetchingNumberOfStudies = useSearchStore(state => state.setIsFetchingNumberOfStudies);
    const selectedPageSize = useSearchStore(state => state.selectedPageSize);

    // Advanced filter state
    const advancedFiltersEnabled = useAdvancedFilterStore(state => state.advancedFiltersEnabled);
    const advancedFilters = useAdvancedFilterStore(state => state.advancedFilters);
    const filterGroups = useAdvancedFilterStore(state => state.filterGroups);

    // Patient 1 Advanced filter state
    const advancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const advancedFilters1 = usePatient1AdvancedFiltersStore(state => state.advancedFilters);
    const filterGroups1 = usePatient1AdvancedFiltersStore(state => state.filterGroups);

    // Patient 2 Advanced filter state
    const advancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.advancedFiltersEnabled);
    const advancedFilters2 = usePatient2AdvancedFiltersStore(state => state.advancedFilters);
    const filterGroups2 = usePatient2AdvancedFiltersStore(state => state.filterGroups);

    const studySortArray = useSortStore((state) => state.studySortArray);
    const patientSortArray = useSortStore((state) => state.patientSortArray);


    const prevSearchRequestRef = useRef<SearchRequest>(searchRequest);
    const prevAdvancedFiltersRef = useRef<AdvancedFilterSearchRequest | null>(advancedFilters);
    const prevAdvancedFilters1Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters1);
    const prevAdvancedFilters2Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters2);
    const axiosSource = useRef<CancelTokenSource | null>(null);
    const axiosCountSource = useRef<CancelTokenSource | null>(null);
    const axiosSettingsSource = useRef<CancelTokenSource | null>(null);
    const axiosCountStudy = useRef<CancelTokenSource | null>(null);

    const searchType = useSearchTypeStore(state => state.searchType);
    const patientFilters = usePatientStore(state => state.filters);
    const patientPagination = usePatientStore(state => state.pagination);
    const timeBetween = usePatientStore(state => state.timeBetween);
    const prevTimeBetweenRef = useRef<any[]>(timeBetween);
    const setPatientPaginationOffset = usePatientStore(state => state.setPatientPaginationOffset)
    const selectedPatientPageSize = usePatientStore(state => state.pagination);
    const prevPatientFiltersRef = useRef<any[]>(patientFilters);
    const prevPatientPaginationRef = useRef<any>(patientPagination);

    const prevSortByRef = useRef<any[]>(patientSortArray);

    const { getDropdownListData } = fetchAllStudyByGroup();
    const crossFilterSelection = useFilterDropDownStore(state => state.crossFilterSelection);
    const setCrossFilterSelection =useFilterDropDownStore(state => state.setCrossFilterSelection);
    const {crossFilterObjectFiltering } = crossDataFiltering();
    const filterDropdowns = useFilterDropDownStore(state=>state.filterDropdowns);
    const setFilterDropdowns = useFilterDropDownStore(state=>state.setFilterDropdowns);
    let advancedCrossFilter = crossFilterSelection;
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    const [params,setParams] = useState<any>();
    const [searchPath , setsearchPath]= useState("")
    const [copied, setCopied] = useState(false);
    const [copiedSearchUrl , setCopiedSearchUrl] =useState("")

    // Terms and condition Modal store
    const showTermsOfServiceModal = useAgreementModalStore(state => state.showTermsOfServiceModal)

      // Toast Store
      const setShowCopied = useToastStore(state => state.setShowCopiedToast);
      const selectedProject = useUserStore(state => state.selectedProject);
      const setStudiesCountInCohort = useProjectsStore(state => state.setStudiesCountInCohort);

    // user store
      const selectedProjectOwnerEmail = useUserStore(state => state.selectedProjectOwnerEmail);
      
      useEffect(() => {
          const fetchCohortsCounts = async () => {
              if(selectedProject && selectedProjectOwnerEmail !== "") {
                  const cohortCountResult = await requestStudiesCountInCohort(selectedProjectOwnerEmail,selectedProject.id, selectedProject.cohort.id, token)
                  setStudiesCountInCohort(cohortCountResult?.count);       
              } 
          }
          fetchCohortsCounts();
      }, [selectedProject, token]);
  
    // Debounced version of setShouldFetchStudies
    const debouncedSetShouldFetchStudies = debounce(() => {
        retrieveSearchedStudies();
    }, 700);

    // Debounced version of setShouldFetchLongitudinalStudies
    const debouncedSetShouldFetchLongitudinalStudies = debounce(() => {
        retrieveLongitudinalSearchedStudies();
    }, 700);

    // Debounce verion of fetchDropdownListData
    const debouncedSetShouldFetchDropdownListData = debounce(()=>{
        fetchDropdownListData();
    },700);

    useEffect(() => {
            updateSearchRequestFilters(filters);
    }, [filters,studySortArray])

    useEffect(() => {
        if (searchType == "study") {
            updateSortBy(studySortArray);
        } 
    }, [studySortArray]);



    // Save the user's search state whenever it changes
    useEffect(() => {
        if (!token) {
            return
        }
        
        if (axiosSettingsSource.current) {
            axiosSettingsSource.current.cancel('Canceling previous request');
        }

        axiosSettingsSource.current = axios.CancelToken.source();
        if (searchType === "study") {
            updateSettings({
                search_request: searchRequest,
                search_terms: searchTerms,
                filter_groups: filterGroups,
            }, token, { cancelToken: axiosSettingsSource.current.token })
        }else{
            updateSettings({
                search_request: patientFilters,
                filter_groups1: filterGroups1,
                filter_groups2: filterGroups2,
                sort: patientSortArray,
            }, token, { cancelToken: axiosSettingsSource.current.token })
        }
    }, [searchRequest, searchTerms, filterGroups,patientFilters,filterGroups1,filterGroups2,patientSortArray])

    useEffect(() => {
        if (!token) {
            return
        }
        if (searchType == "study") {
            // What we want to do is check whether the offset has changed, and if it has, we want to fetch the studies without resetting studies
            const prevPaginationOffset = prevSearchRequestRef.current.pagination.offset;
            const currentPaginationOffset = searchRequest.pagination.offset;
            const offsetChanged = prevPaginationOffset !== currentPaginationOffset
            
            // But, if new changes were introduced then we want to reset the studies
            const filtersChangedExcludingPagination = (
                JSON.stringify({ ...prevSearchRequestRef.current, pagination: null }) !== JSON.stringify({ ...searchRequest, pagination: null }) ||
                JSON.stringify(prevAdvancedFiltersRef.current) !== JSON.stringify(advancedFilters)
            )


            if (filtersChangedExcludingPagination) {
                

                const shouldFetch = (
                    (advancedFilters && advancedFiltersEnabled && allValueFieldsAreFilled(advancedFilters)) ||
                    (!advancedFiltersEnabled)
                )
                

                if(shouldFetch) {
                    // Cancel the prev existing request
                    if (axiosCountSource.current) {
                        axiosCountSource.current.cancel('Canceling previous count request');
                        setShouldFetchNumberOfStudies(false);
                    }
                    setNumberOfStudies(null);
                    setSearchRequestPageOffset(0);
                    !filters.cross_filter && setActiveStudies([]);
                    setStudies([]);
                    debouncedSetShouldFetchStudies();
                }
            
            } else if (offsetChanged) {
                // Check to see pageOffset is in studies
                if (studies[currentPaginationOffset] !== undefined) {
                    setActiveStudies(studies[currentPaginationOffset]);
                } else {
                    debouncedSetShouldFetchStudies();
                }
            }
            

            prevSearchRequestRef.current = searchRequest;
            prevAdvancedFiltersRef.current = advancedFilters;
        }
    }, [searchRequest, advancedFilters, advancedFiltersEnabled, searchTerms, token])

    useEffect(() => {
        if (!token) {
            return
        }
        if (searchType == "patient") {
            // What we want to do is check whether the offset has changed, and if it has, we want to fetch the studies without resetting studies
            const prevPaginationOffset = prevPatientPaginationRef.current.offset;
            const currentPaginationOffset = patientPagination.offset;
            const offsetChanged = prevPaginationOffset !== currentPaginationOffset;
            

            const filtersChangedExcludingPagination = (
                JSON.stringify({ ...prevPatientFiltersRef.current, pagination: null }) !== JSON.stringify({ ...patientFilters, pagination: null }) ||
                JSON.stringify(prevAdvancedFilters1Ref.current) !== JSON.stringify(advancedFilters1) ||
                JSON.stringify(prevAdvancedFilters2Ref.current) !== JSON.stringify(advancedFilters2) ||
                JSON.stringify(prevTimeBetweenRef.current) !== JSON.stringify(timeBetween)||
                JSON.stringify(prevSortByRef.current) !== JSON.stringify(patientSortArray)
            );

            //setStudies([]);

            if (filtersChangedExcludingPagination) {
                // Cancel the prev existing request
                if (axiosCountSource.current) {
                    axiosCountSource.current.cancel('Canceling previous count request');
                    setShouldFetchNumberOfStudies(false);
                }
                setShouldFetchNumberOfStudies(true);
                setNumberOfStudies(null);
                setPatientPaginationOffset(0);
                !filters.cross_filter && setActiveStudies([]);
                setStudies([]);
                debouncedSetShouldFetchLongitudinalStudies();
            } else if (offsetChanged) {
                // Check to see pageOffset is in studies
                if (studies[currentPaginationOffset] !== undefined) {
                    setActiveStudies(studies[currentPaginationOffset]);
                } else {
                    debouncedSetShouldFetchLongitudinalStudies();
                }
            }
            
            prevPatientFiltersRef.current = patientFilters;
            prevPatientPaginationRef.current = patientPagination;
            prevAdvancedFilters1Ref.current = advancedFilters1;
            prevAdvancedFilters2Ref.current = advancedFilters2;
            prevTimeBetweenRef.current = timeBetween;
            prevSortByRef.current = patientSortArray;

        }
    }, [ patientFilters[0], patientFilters[1], timeBetween, advancedFiltersEnabled1, advancedFilters1, advancedFiltersEnabled2, advancedFilters2, patientPagination, token, patientSortArray ])   

    const fetchStudiesCount = async (searchRequest: any) => {
        if (shouldFetchNumberOfStudies && token) {
            setIsFetchingNumberOfStudies(true);
            searchRequest = nullCheckingInFilters(searchRequest);
            const numberOfStudies = await getStudyCount(searchRequest, token, { cancelToken: axiosCountSource?.current?.token });
            if (numberOfStudies && numberOfStudies.count) {
                setNumberOfStudies(numberOfStudies.count);
            }
            setShouldFetchNumberOfStudies(false);
            setIsFetchingNumberOfStudies(false);
        }
    };

    const fetchPatientStudiesCount = async (tempFilters: any) => {
        if (shouldFetchNumberOfStudies && token) {
            setIsFetchingNumberOfStudies(true);
            tempFilters = [nullCheckingInFilters(tempFilters[0]), nullCheckingInFilters(tempFilters[1])];
            const numberOfStudies = await getPatientStudyCount(tempFilters, patientPagination.offset, timeBetween, token, { cancelToken: axiosCountSource?.current?.token });
            if (numberOfStudies && numberOfStudies.count) {
                setNumberOfStudies(numberOfStudies.count);
            }
            setShouldFetchNumberOfStudies(false);
            setIsFetchingNumberOfStudies(false);
        }
    }

    const retrieveLongitudinalSearchedStudies = async () => {
        // Cancel the prev existing request
        if (axiosSource.current) {
            axiosSource.current.cancel('Canceling previous request');
        }

        // Create a new CancelTokenSource for the current request
        axiosSource.current = axios.CancelToken.source();

        try {
            if(selectedProject) {
            setIsRetrievingStudies(true);
            setShouldFetchNumberOfStudies(true);

            let fetchedStudies: SearchResult;
            let tempFilters =  patientFilters;

            tempFilters[0].search_summary = "";
            tempFilters[1].search_summary = "";
            tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms);
            tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms);
            if (advancedFiltersEnabled1) {
                if (advancedFilters1 && advancedFilters1.value?.length > 0 && advancedFilters1.value[0].value?.length > 0) {
                    tempFilters[0].advancedFilters = advancedFilters1;
                }
                else {
                    delete tempFilters[0]?.advancedFilters;
                }
            }
            else {
                delete tempFilters[0]?.advancedFilters;
            }
            if (advancedFiltersEnabled2) {
                if (advancedFilters2 && advancedFilters2.value?.length > 0 && advancedFilters2.value[0].value?.length > 0) {
                    tempFilters[1].advancedFilters = advancedFilters2;
                }
                else {
                    delete tempFilters[1]?.advancedFilters;
                }
            }
            else {
                delete tempFilters[1]?.advancedFilters;
            }
            tempFilters = [nullCheckingInFilters(tempFilters[0]), nullCheckingInFilters(tempFilters[1])];
            setParams(tempFilters);
            fetchedStudies = await getLongitudinalStudies(tempFilters,selectedPatientPageSize.pageSize, patientPagination.offset,timeBetween,patientSortArray,selectedProject?.id,token, { cancelToken: axiosSource.current.token });
            if (fetchedStudies.results) {
                setStudies({
                    ...studies,
                    [patientPagination.offset]: fetchedStudies.results
                });

                setActiveStudies(fetchedStudies.results);

                // If studies is empty, set no results found to true
                setNoResultsFound(fetchedStudies.results.length === 0);
            } else {
                console.log('Something went wrong fetching studies.')
            }

            setIsRetrievingStudies(false);
            setShouldFetchStudies(false);
        }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error(error);
            }
        }
    }
    function nullCheckingInFilters(
        tempSearchRequest: SearchRequest
    ): SearchRequest {

        const { filters } = tempSearchRequest;

      // Add is_null: true if conditions are met for age
        if (filters.age.min === 0 && filters.age.max === 90) {
            filters.age.is_null = true;
        }

      // Add is_null: true if conditions are met for slice_thickness
        if (
            filters.slice_thickness.min === 0 &&
            filters.slice_thickness.max === 10
        ) {
            filters.slice_thickness.is_null = true;
        }

        // Update modality filter if it matches the default length
        if (filters.modality?.length === modalityOptionsDefaultLength) {
            filters.modality = [...filters.modality, "null", "UNKNOWN"];
        }

        // Update manufacturer filter if it matches the default length
        if (
            filters.manufacturer?.length === manufacturerSelectionsDefaultLength
        ) {
            filters.manufacturer = [...filters.manufacturer, "null", "UNKNOWN"];
        }

        // Update sex filter if it contains exactly ["M", "F", "O"]
        if (filters?.sex?.length) {
            const hasAllSexOptions = ["M", "F", "O"].every(sex => filters.sex.includes(sex));
        
            if (hasAllSexOptions) {
                // Add "null" if it doesn't already exist
                if (!filters?.sex.includes("null")) {
                    filters.sex = [...filters.sex, "null"];
                }
            } else {
                // Remove "null" if it exists
                filters.sex = filters.sex.filter(sex => sex !== "null");
            }
        }

        return tempSearchRequest;
    }


    const retrieveSearchedStudies = async () => {
        // Cancel the prev existing request
        if (axiosSource.current) {
            axiosSource.current.cancel('Canceling previous request');
        }

        // Create a new CancelTokenSource for the current request
        axiosSource.current = axios.CancelToken.source();

        try {
            setIsRetrievingStudies(true);
            setShouldFetchNumberOfStudies(true)
            let fetchedStudies: SearchResult;
            let tempSearchRequest: SearchRequest | AdvancedSearchRequest;

            if ((advancedFilters && advancedFiltersEnabled) || searchPath === "studies") {
                tempSearchRequest = {
                    ...searchRequest,
                    advancedFilters,
                }
            } else {
                tempSearchRequest = searchRequest;
            }
            tempSearchRequest = {
                ...tempSearchRequest,
                project_id: selectedProject?.id,
            };
            tempSearchRequest = nullCheckingInFilters(tempSearchRequest);
            fetchedStudies = await getStudies(tempSearchRequest, token, { cancelToken: axiosSource.current.token });
      
            if (fetchedStudies.results) {
                 setStudies({
                    ...studies,
                    [searchRequest.pagination.offset]: fetchedStudies.results,
                });

                setActiveStudies(fetchedStudies.results);

                // If studies is empty, set no results found to true
                setNoResultsFound(fetchedStudies?.results?.length === 0);
            } else {
                console.log('Something went wrong fetching studies.')
            }

            setIsRetrievingStudies(false);
            setShouldFetchStudies(false);

            

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        if (searchType === 'study') {
            let tempSearchRequest: SearchRequest | AdvancedSearchRequest;

            // Create a new CancelTokenSource for the current request
            axiosCountSource.current = axios.CancelToken.source();

            if (advancedFilters && advancedFiltersEnabled) {
                tempSearchRequest = {
                    ...searchRequest,
                    advancedFilters,
                }
            } else {
                tempSearchRequest = searchRequest;
            }
            
            fetchStudiesCount(tempSearchRequest);
        }
    }, [shouldFetchNumberOfStudies, token])

    useEffect(() => {
        if (searchType == 'patient') {
            let tempFilters = patientFilters;

            // Create a new CancelTokenSource for the current request
            axiosCountSource.current = axios.CancelToken.source();

            tempFilters[0].search_summary = "";
            tempFilters[1].search_summary = "";
            tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms)
            tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms)
            if (advancedFiltersEnabled1) {
                tempFilters[0].advancedFilters = advancedFilters1;
            }
            else{
                delete tempFilters[0]?.advancedFilters;
            }
            if (advancedFiltersEnabled2) {
                tempFilters[1].advancedFilters = advancedFilters2;
            }
            else{
                delete tempFilters[1]?.advancedFilters;
            }
            fetchPatientStudiesCount(tempFilters);
        }
    }, [shouldFetchNumberOfStudies, token])

    useEffect(() => {
        filterGroups?.map((items: any) => {
          items?.filters.map((item: any) => {
            if (item.value !== "" && alldropdowns.includes(item.field)) {
              advancedCrossFilter[item.field] = item.value;
              setCrossFilterSelection(advancedCrossFilter);
            }
          });
        });
    }, [filterGroups]);

    useEffect(() => {
        const searchRequestFilterObject = Object.keys(searchRequest?.filters);
        if (searchRequestFilterObject.includes("manufacturer"))
          advancedCrossFilter["manufacturer"] =
            searchRequest?.filters["manufacturer"];
        if (searchRequestFilterObject.includes("modality"))
          advancedCrossFilter["modality"] = searchRequest?.filters["modality"];
        if (searchRequestFilterObject.includes("institution"))
          advancedCrossFilter["institution"] =
            searchRequest?.filters["institution"];
        setCrossFilterSelection(advancedCrossFilter);
    }, []);


    const defaultOptions: { [key: string]: any[] } = {
        modality: modalityOptionsDefault,
        manufacturer: manufacturerSelectionsDefault,
    };

    const fetchDropdownListData = async () => {
      if (filters.cross_filter) {
        // Cancel the prev existing request
        if (axiosCountStudy.current) {
          axiosCountStudy.current.cancel(
            "Canceling previous request of dropdown list"
          );
        }
        // Create a new CancelTokenSource for the current request
        axiosCountStudy.current = axios.CancelToken.source();

        let crossFilterData: any = {};
        let tempSearchRequest: SearchRequest | AdvancedSearchRequest;

        if ((advancedFilters && advancedFiltersEnabled) || searchPath === "studies") {
            tempSearchRequest = {
                ...searchRequest,
                advancedFilters,
            }
        } else {
            tempSearchRequest = searchRequest;
        }
        tempSearchRequest = nullCheckingInFilters(tempSearchRequest);
        // Use Promise.all to fetch data concurrently
        await Promise.all(
          filterDropdowns.map(async (value) => {
            try {
              if (Object.keys(crossFilterSelection).length > 0) {
                crossFilterData = crossFilterObjectFiltering(value);
              }
              const default_list = defaultOptions[value] || [];
              await getDropdownListData(
                value,
                token,
                Object.keys(crossFilterSelection).length > 0 &&
                  filters.cross_filter
                  ? crossFilterData
                  : {}, filters.cross_filter,
                filters.cross_filter ? tempSearchRequest : [], default_list,
                { cancelToken: axiosCountStudy.current?.token }
              );
            } catch (error) {
              if (axios.isCancel(error)) {
                console.log("Request canceled", error.message);
              } else {
                throw error;
              }
            }
          })
        );
      }
    };

      useEffect(() => {
        debouncedSetShouldFetchDropdownListData();
      }, [filters, filterGroups, filterDropdowns, searchRequest]);

      useEffect(()=>{
        setFilterDropdowns(alldropdowns);
      },[searchTerms])

      useEffect(() => {
        const fetchDropdownInitialData = async () => {
          if (!filters.cross_filter) {
            // Cancel the prev existing request
            if (axiosCountStudy.current) {
              axiosCountStudy.current.cancel(
                "Canceling previous request of dropdown list"
              );
            }
            let tempSearchRequest: SearchRequest | AdvancedSearchRequest;

            if ((advancedFilters && advancedFiltersEnabled) || searchPath === "studies") {
                tempSearchRequest = {
                    ...searchRequest,
                    advancedFilters,
                }
            } else {
                tempSearchRequest = searchRequest;
            }
            tempSearchRequest = nullCheckingInFilters(tempSearchRequest);
            // Create a new CancelTokenSource for the current request
            axiosCountStudy.current = axios.CancelToken.source();
            // Use Promise.all to fetch data concurrently
            await Promise.all(
                alldropdowns.map((value) =>{
                const default_list = defaultOptions[value] || [];
                const crossFlag = advancedFilterDropdowns.find(item => item===value) ? true : filters.cross_filter;
                getDropdownListData(value, token, {}, crossFlag, tempSearchRequest, default_list, {
                  cancelToken: axiosCountStudy.current?.token,
                })}
              )
            );
          }
        };
        fetchDropdownInitialData();
      }, [token, filters.cross_filter]);

    const handleShareButtonClick=async()=>{
        let searchParms = {}
        let path;
        if (searchType === "study") {
            searchParms = {
                search_request: searchRequest,
                search_terms: searchTerms,
                filter_groups: filterGroups,
            }
            path ="studies"
        }else{
            path = "longitudinal";
            searchParms = {
              search_request: params,
              ...(filterGroups1.length > 0 && {
                filter_groups1: filterGroups1,
              }),
              ...(filterGroups2.length > 0 && {
                filter_groups2: filterGroups2,
              }),
              timeBetween: timeBetween,
              sort: patientSortArray,
            };
        }
        if(token){
            try{
                const searchData = await createSearchParams(searchParms, path, token);
                if(searchData){
                    let searchUrl = DOMAIN_URL+"/share/"+searchData.id;
                    setsearchPath(searchData.path);
                    setCopiedSearchUrl(searchUrl)
                    navigator.clipboard.writeText(searchUrl).then(
                        () => {
                            setCopiedToastMessage("URL copied to clipboard");
                            setCopied(true);
                            setShowCopied(true)
                        },
                        (err) => setCopiedToastMessage("Error, could not get shareable URL")
                    );
                }
            }catch(error){
                console.log(error)
            }
        }
    }

    useEffect(()=>{
        debouncedSetShouldFetchStudies();
    },[selectedPageSize]);

    useEffect(()=>{
        debouncedSetShouldFetchLongitudinalStudies();
    },[selectedPatientPageSize.pageSize]);
    
  return (
    <>
        <div className='xl:pl-96'>  
            <div className={`${
                   filters.cross_filter && isRetrievingStudies
                      ? "opacity-30"
                      : "opacity-100"
                  } pl-4 pr-2 pt-10 pb-2 sm:px-6 lg:px-8 lg:pt-6 lg-pb-2`}>
                <CatalogDisplayList handleShareButtonClick={handleShareButtonClick} 
                value={copiedSearchUrl} copied={copied}
                />
            </div>
            {/* TODO: Combine with below */}
            <aside className="fixed bottom-0 hidden left-20 top-16 w-96 overflow-y-auto border-r border-gray-200 px-2 py-6 sm:px-2 lg:px-4 xl:block bg-slate-100">
                <FiltersControlPanel />
            </aside>
            <Transition.Root show={isFiltersPanelOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10 xl:hidden" onClose={setIsFiltersPanelOpen}>
                        <div className="pointer-events-none fixed ml-20 inset-y-0 flex max-w-full mt-12">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md ">
                                
                            <div className="flex h-full flex-col bg-slate-100 border-r py-6 shadow-xl overflow-y-auto">
                                
                                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                <FiltersControlPanel />
                                </div>
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                </Dialog>
            </Transition.Root>
            {showTermsOfServiceModal && (
                <TermsOfServiceModal/>
            )}
        </div>
        </>
    )
}