import React, { useEffect, useState } from "react";
import Chart from "apexcharts";

interface PieChartProps {
  data: { [key: string]: number };
  colors: string[];
  chartId: string;
}

export default function PieChart({ data, colors, chartId }: PieChartProps) {
  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    const getChartOptions = (labelsAndValues: { [key: string]: number }) => {
      const count = Object.values(labelsAndValues).reduce((acc, val) => acc + val, 0);

      return {
        series: Object.values(labelsAndValues),
        colors: colors,
        chart: {
          height: 200,
          width: "100%",
          type: "pie"
        },
        stroke: {
          colors: ["white"]
        },
        plotOptions: {
          pie: {
            labels: {
              show: true
            },
            size: "100%",
            dataLabels: {
              offset: -10
            }
          }
        },
        labels: Object.keys(labelsAndValues),
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Inter, sans-serif"
          }
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value;
            }
          }
        },
        xaxis: {
          labels: {
            formatter: function (value: any) {
              return (value / count * 100).toFixed(2) + "%";
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    };

    if (data && Object.keys(data).length > 0) {
      if (!chart) {
        const newChart = new Chart(document.getElementById(chartId), getChartOptions(data));
        setChart(newChart);
        newChart.render();
      } else {
        chart.updateOptions(getChartOptions(data));
      }
    }
  }, [data, chartId, colors, chart]);

  return (
    <div>
      <div id={chartId} />
    </div>
  );
}