import { create } from 'zustand';

interface SearchParamsStore {
    searchParamsLoaded: boolean;
    setSearchParamsLoaded: (show: boolean) => void;
}

export const useSearchParamsStore = create<SearchParamsStore>()((set) => ({
    searchParamsLoaded: false,
    setSearchParamsLoaded: (searchParamsLoaded: boolean) => set({ "searchParamsLoaded": searchParamsLoaded })
}));