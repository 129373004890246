interface UploadCsvProgressBarProps {
  addedElements: number;
  totalElements: number;
}

const UploadCsvProgressBar: React.FC<UploadCsvProgressBarProps> = ({
  addedElements,
  totalElements,
}) => {
  const progress = (addedElements / totalElements) * 100;

  return (
    <div className="h-2.5 w-40">
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          className="bg-blue-600 h-2.5 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default UploadCsvProgressBar;