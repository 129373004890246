import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { Dispatch, SetStateAction ,useEffect,useRef,useState} from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import { useFilterDropDownStore } from "../../store/filterDropDownStore";
import { useSearchStore } from "../../store/searchStore";
import { useFilterStore } from "../../store/filterStore";
interface TypeToSearchProps{
  searchText: string;
  setSearchText:Dispatch<SetStateAction<string>>;
  handleSearch: (searchValue: string) => void;
  label: string;
  field: string;
  isValidRegex: boolean;
  setIsValidRegex: Dispatch<SetStateAction<boolean>>;
  selectedOptions: string[];
}

const TypeToSearch =({
  searchText,
  setSearchText,
  handleSearch,
  label,
  field,
  isValidRegex,
  setIsValidRegex,
  selectedOptions

}:TypeToSearchProps )=>{
  const [value, setValue] = useState<number | string | string[]>();
  const clearButtonClicked =  useFilterDropDownStore(state=>state.clearButtonClicked)
  const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
  const filters = useFilterStore(state => state.filters);
  let timeoutHandler:any = useRef(null);

    useEffect(()=>{
      setValue(selectedOptions.length > 0 ? selectedOptions.toString() : searchText)
    },[searchText,selectedOptions])

  const tooltipContent = (
    <div className="w-80 z-10 h-48 overflow-y-auto scrollbar::-webkit-scrollbar z-50">
      <div className="flex flex-col justify-start items-start gap-3">
        <div>
          <p className="text-left font-semibold">1. How to match different words using the pipe (<code className="text-lime-500">|</code>)</p>
          <p className="text-left">The pipe character (|) allows you to match one or more alternatives. For example, if you want to match either "CT" or "MR":</p>
          <p className="text-left"><code className="text-lime-500">CT|MR</code></p>
          <p className="text-left"><span className="font-semibold">Example:</span> This will match lines that contain either "CT" or "MR".</p>
        </div>
  
        <div>
          <p className="text-left font-semibold">2. How to match a specific word using word boundaries (<code className="text-lime-500">\b</code>)</p>
          <p className="text-left">Word boundaries (\b) ensure that the match occurs at the start and end of a word. For example, if you want to match the exact word "CR":</p>
          <p className="text-left"><code className="text-lime-500">\bCR\b</code></p>
          <p className="text-left"><span className="font-semibold">Example:</span> This will match the word "CR" exactly, without matching words like "CR123" or "123CR".</p>
        </div>
  
        <div>
          <p className="text-left font-semibold">3. How to match any character using dot (<code className="text-lime-500">.</code>) and any number of characters using asterisk (*)</p>
          <p className="text-left">The dot (<code className="text-lime-500">.</code>) matches any single character except a newline, and the asterisk (<code className="text-lime-500">*</code>) matches zero or more of the preceding element. For example, if you want to match any modality that starts with "C" and ends with "R" with any characters in between:</p>
          <p className="text-left"><code className="text-lime-500">C.*R</code></p>
          <p className="text-left"><span className="font-semibold">Example:</span> This will match "CR", "CTMR", "CMR", etc.</p>
        </div>

        <div>  
          <p className="text-left font-semibold">4. How to match digits using \d</p>
          <p className="text-left">The <code className="text-lime-500">\d</code> character class matches any digit. For example, if you want to match modalities with numeric values:</p>
          <p className="text-left"><code className="text-lime-500">\d</code></p>
          <p className="text-left"><span className="font-semibold">Example:</span> This will match any digit in the list of modalities.</p>
        </div>

        <div>
          <p className="text-left font-semibold">5. How to match specific patterns using parentheses (<code className="text-lime-500">()</code>) + (<code className="text-lime-500">\d+</code>)</p>
          <p className="text-left">Parentheses group together patterns. For example, if you want to match "CT" followed by any number of digits:</p>
          <p className="text-left"><code className="text-lime-500">(CT\d+)</code></p>
          <p className="text-left"><span className="font-semibold">Example:</span> This will match "CT123", "CT1", "CT9999", etc.</p>
        </div>

      </div>
    </div>
  );
  
  const changeHandler = (e: any) => {
    let newValue = e.target.value;
    setValue(newValue);
    setIsValidRegex(true);
    if (timeoutHandler.current === null || newValue) {
      clearTimeout(timeoutHandler.current);
      const handler = setTimeout(() => {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = null;
        setSearchText(newValue);
        handleSearch(newValue);
      }, 200);
      timeoutHandler.current = handler;
    }
  };

  useEffect(() => {
    setValue("");
  }, [clearButtonClicked]);

  return (
    <>
    <label className="relative block sticky">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
         <Tippy
          content={tooltipContent}
          arrow={true}
          delay={500}
          interactive={true}
          placement="top"
          maxWidth="none"
        >
            <svg className="h-4 w-4 fill-slate-300" viewBox="0 0 20 20">
              <InformationCircleIcon className="h-4 w-4 text-blue-600 cursor-pointer" />
            </svg>
        </Tippy>
      </span>
      <input
         className={`placeholder:bold ${filters.cross_filter && isRetrievingStudies
        ?"placeholder-text-gray-300 text-gray-300" : "placeholder:text-gray-400 text-gray-900"}
          block bg-white w-full ${
          !isValidRegex ? "border-red-500" : "border-slate-300"
        } shadow-sm block w-full rounded-md border-0 py-1.5  pl-7 pr-3 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed`}
        placeholder={" Search "+`${label}`}
        type="text"
        value=  {value}
        onChange={(e) => changeHandler(e)}
        disabled={filters.cross_filter && isRetrievingStudies? true: false}
      />
      <span className="absolute inset-y-0 flex justify-end items-center pl-2 	right-2.5">
        <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
          <MagnifyingGlassIcon className="h-12 w-12 mx-auto text-gray-400" />
        </svg>
      </span>
    </label>
     {!isValidRegex && (
        <p className="text-xs text-red-500 italic text-left pt-2">Invalid regex content</p>
      )}
    </>
    
  );
}

export default TypeToSearch;