import { useState } from "react";
import SearchBadge from "./searchbadge";
import SearchBar from "./searchbar";
import { useFilterStore } from "../../store/filterStore";
import { SearchTerm } from "../../types/search";
import { usePatientStore } from '../../store/patientStore'

type SearchProps = {
    id?: number;
}

export default function Search({id}: SearchProps) {
    const [ searchOperator, setSearchOperator ] = useState<'+' | '-'| 'OR'>('+');
    const searchTerms = useFilterStore(state => state.searchTerms);
    const setSearchTerms = useFilterStore(state => state.setSearchTerms);
    const setPatientSearchTerms = usePatientStore(state => state.setSearchTerms);
    const searchFilters = useFilterStore(state => state.filters);
    const patientFilters = usePatientStore(state => state.filters);
    const handleRemoveSearchTerm = (searchTermToRemove: SearchTerm) => {
        let updatedSearchTerms;
        if (id != null) {
        updatedSearchTerms = patientFilters[id].filters.searchTerms.filter((searchTerm: SearchTerm) => searchTerm.term !== searchTermToRemove.term);
        setPatientSearchTerms(updatedSearchTerms, id);
        } else {
        updatedSearchTerms = searchFilters?.searchTerms?.filter((searchTerm: SearchTerm) => searchTerm.term !== searchTermToRemove.term) ?? [];
        setSearchTerms(updatedSearchTerms);
        }
    
        if (updatedSearchTerms.length === 0) {
        setSearchOperator('+');
        }
    };
    return (
    <>
        <div className="flex flex-col">
        <SearchBar id={id} searchOperator={searchOperator} setSearchOperator={setSearchOperator} />
            <div className="py-1 flex flex-row flex-wrap gap-x-2 gap-y-2">
                {id != null ? patientFilters[id].filters.searchTerms.map((searchTerm: SearchTerm, index: number) => {
                    return <SearchBadge searchTerm={searchTerm} key={index} badgeNumber={index} onRemove={(searchTerm) => handleRemoveSearchTerm(searchTerm)}/>
                }) : searchFilters?.searchTerms?.map((searchTerm: SearchTerm, index: number) => {
                    return <SearchBadge searchTerm={searchTerm} key={index} badgeNumber={index} onRemove={(searchTerm) => handleRemoveSearchTerm(searchTerm)}/>
                })}
            </div>
        </div>
    </>
    )
}