import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAgreementModalStore } from "../../../store/agreementModalStore";

export default function TermsOfServiceModal() {
  // Local State
  const [userName, setUserName] = useState("");
  const [focus, setFocus] = useState(false);
  const [showEmptyUserNameError, setShowEmptyUserNameError] = useState(false);

  // Terms and condition Modal store
  const showTermsOfServiceModal = useAgreementModalStore((state) => state.showTermsOfServiceModal);
  const setShowTermsOfServiceModal = useAgreementModalStore((state) => state.setShowTermsOfServiceModal);
  const setShowAgreementModal = useAgreementModalStore((state) => state.setShowAgreementModal);
  const ScrollToTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showTermsOfServiceModal) {
      ScrollToTopRef.current && ScrollToTopRef.current.scrollTo(0, 0);
      ScrollToTopRef.current && ScrollToTopRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showTermsOfServiceModal, ScrollToTopRef.current]);

  const handleAcceptTermsOfService = () => {
    if (userName !== "") {
        setShowTermsOfServiceModal(false);
        setShowAgreementModal(true);
    } else {
      setShowEmptyUserNameError(true);
    }
  };

  const onOpen = () => {
    setShowTermsOfServiceModal(true);
  };
  const isOpen = showTermsOfServiceModal;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onOpen}>
        <div className="min-h-screen px-4 text-center max-w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="inline-block w-full max-w-5xl min-h-64 p-6 my-8 scroll-smooth overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              style={{ maxHeight: "80vh", overflowY: "scroll" }}
              ref={ScrollToTopRef}>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Terms of Service
              </Dialog.Title>
              <article className="mt-2">
                <p className="text-sm text-gray-700 text-justify">
                  Gradient Health, Inc. maintains a database of aggregated, de-identified medical imagery and related information (the
                  <b>“Atlas Data”</b>), which it makes available through a web-based platform (the <b>“Gradient Platform”</b>). The{" "}
                  <b>“Service”</b> includes the Gradient Platform, Atlas Data, and all other services, tools, technology, documentation,
                  content, and features provided by Gradient (including anything provided free of charge during the Trial Period). Gradient
                  offers limited trial access to the Atlas Data and Gradient Platform, as well as a paid service that allows you to view and
                  download Atlas Data from the Gradient Platform. By registering for an account to use the Gradient Platform, you are
                  expressly agreeing to the following Trial Use Agreement, as well as the Gradient Health Atlas Terms of Service as posted
                  and updated periodically at
                  <a
                    href="https://docs.google.com/document/d/1J0080LdzPExeIceKL3o-CY76ddnN6PjDRt6yifO4JuU/edit?usp=sharing"
                    className="text-blue-500 outline-none ml-2">
                    Atlas Platform - Terms of Service Public
                  </a>
                  . If you do not agree to the following Trial Use Agreement terms, you must not use the Service.
                </p>
                <div>
                  <h2 className="mt-3 font-medium	">TRIAL USE AGREEMENT</h2>
                  <ol className="list-decimal mt-2 text-sm text-gray-700 pl-5 text-justify">
                    <li>
                      In consideration for your agreement to the terms and conditions contained here, Gradient Health grants you permission
                      to view and use the Atlas Data for up to seven (7) business days (the <b>“Trial Period”</b>) without charge for
                      personal, non-commercial research. You may not otherwise copy, reproduce, retransmit, distribute, publish,
                      commercially exploit or otherwise transfer any material. You may use Atlas Data for legal purposes only. Any
                      commercial use, sale, or other monetization is prohibited. Other than the rights granted herein, Gradient Health, Inc.
                      retains all rights, title, and interest in the Atlas Data and Gradient Platform.
                    </li>
                    <li>
                      You may download up to ten (10) total data studies (<b>“Trial Download Limit”</b>) from the Atlas Data during the
                      Trial Period and make a copy of such Atlas Data for personal, non-commercial research use as permitted in this Trial
                      Use Agreement. If another user within your organization wishes to use the Gradient Platform, they must register as an
                      individual user and comply with all the terms of this Trial User Agreement. You may not register multiple trial
                      accounts in order to circumvent or otherwise exceed the Trial Download Limit during the Trial Period. Gradient Health
                      reserves the right to charge fees if you exceed the Trial Download Limit, and you agree to pay such fees in accordance
                      with the Terms of Service.
                    </li>
                    <li>
                      YOU SHALL NOT REPRODUCE, DISTRIBUTE, PUBLISH, OR PROVIDE A COPY of any portion or all of the Atlas Data to others
                      without specific prior written permission from Gradient Health.
                    </li>
                    <li>
                      You shall not modify, reverse engineer, decompile, or create derivative works from the Gradient Platform or Atlas
                      Data. You must not remove or alter any copyright or other proprietary notices in the Atlas Data.
                    </li>
                    <li>
                      The Atlas Data has not been reviewed or approved by the Food and Drug Administration. The Atlas Data is for
                      non-clinical, research use only. Under no circumstances shall data or images generated through the use of the Atlas
                      Data be used or relied upon in the diagnosis or provision of patient care.
                    </li>
                    <li className="break-keep">
                      THE ATLAS DATA IS PROVIDED "AS IS," AND GRADIENT HEALTH AND ITS REPRESENTATIVES DO NOT MAKE ANY WARRANTY, EXPRESS OR
                      IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                      SECURITY, ACCURACY, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
                      GRADIENT HEALTH DOES NOT ASSUME ANY LIABILITY OR RESPONSIBILITY FOR THE USE OF THE ATLAS DATA.
                      <br />
                      GRADIENT MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE OR ANY ATLAS DATA, OR RESULTS
                      OF THE USE THEREOF, WILL MEET A USER’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE
                      COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICE, OR BE SECURE, ACCURATE, COMPLETE, OR ERROR FREE. YOU AS
                      THE USER ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USE OF THE
                      SERVICE, ATLAS DATA, OR ANY COMPONENT THEREOF.
                    </li>
                    <li>
                      You shall not make any attempt to re-identify any of the individual data subjects or otherwise attempt to ascertain
                      any Protected Health Information removed from the Atlas Data. Re-identification of individuals is strictly prohibited.
                      Any re-identification of any individual data subject shall be immediately reported to Gradient Health. You must also
                      immediately report to Gradient Health any Protected Health Information you discover that has not been removed from the
                      Atlas Data.
                    </li>
                    <li>
                      You agree to indemnify and hold Gradient Health harmless from any claims, losses or damages, including legal fees,
                      arising out of or resulting from your use of the Atlas Data; your products, technology, or services; or your violation
                      or role in violation of this Trial Use Agreement, Gradient Health’s Terms of Service, or any applicable law. You agree
                      to fully cooperate in Gradient Health’s defense against any such claims. These Terms shall be governed by and
                      interpreted in accordance with the laws of North Carolina.
                    </li>
                    <li>
                      Any violation of this Trial Use Agreement or other impermissible use shall be grounds for immediate termination of use
                      of the Gradient Platform and Atlas Data. In the event that Gradient Health determines that a user has violated this
                      Trial Use Agreement or the Gradient Health Atlas Terms of Service, or other impermissible use has been made, Gradient
                      Health may direct that the user immediately return all copies of the Atlas Data and retain no copies thereof even if
                      you did not cause the violation or impermissible use
                    </li>
                  </ol>
                </div>
              </article>
              <div className="mt-4">
                <form className="mt-5 sm:flex flex-col sm:items-center gap-4">
                  <div className="mt-6 flex items-center">
                    <div className="w-56">
                      <input
                        type="text"
                        name="username"
                        id="projectName"
                        autoFocus={focus}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
                          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm  ${
                            showEmptyUserNameError && userName === "" && "bg-gray-200"
                          }`}
                        placeholder="Type your name here to agree"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                      />
                    </div>

                    <label htmlFor="agreement" className="ml-2 block text-sm text-gray-700">
                      I have read, understand, and agree to all of the above terms and conditions of the Gradient Health Trial Use
                      Agreement.
                    </label>
                  </div>
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      onClick={handleAcceptTermsOfService}
                      disabled={userName.length === 0}
                      className={`mt-3 inline-flex  items-center justify-center rounded-md px-5 py-2 text-sm font-semibold shadow-sm ${
                        userName.length === 0 ? "bg-gray-400 cursor-not-allowed text-gray-300" : "bg-blue-600 text-white hover:bg-blue-500"
                      }`}>
                        Agree
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
