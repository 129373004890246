import axios from 'axios';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;
export async function getSaveSearchResult( offset:number,path: string,  jwt_token: string, ) {

    const response = await axios.get(`${server_url}/save_search_filters`, {
        params: {
            'query_params': JSON.stringify({
                'pagination': {
                    'pageSize': 10,
                    'offset': offset
                 },
                 path,
            }),
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    });
    return response;
}

export async function createSaveSearchName(searchParams: any, path: string, searchName: string, jwt_token: string,) {
    const response = await axios.post(`${server_url}/save_search_filters`, {
        search_params: searchParams,
        path: path,
        search_name: searchName
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response;
}

export async function updateSaveSearchName(id: number,searchName: string, jwt_token: string,) {
    const response = await axios.put(`${server_url}/save_search_filters?id=${id}`, {
        search_name: searchName
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response;
}

export async function deleteSaveSearch(id: number, jwt_token: string,) {
    const response = await axios.delete(`${server_url}/save_search_filters?id=${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response;
}