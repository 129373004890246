import React, { useEffect, useState } from "react";
import SortByAscDsc from "./SortByAscDsc";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { SortLists } from "./SortingList";
import { useSortStore } from "../../../store/sortStore";
import { debounce } from "../../../utils/debounce";
import { useFilterDropDownStore } from "../../../store/filterDropDownStore";
import { useSearchTypeStore } from "../../../store/searchTypeStore";

interface CheckedItems {
  [value: string]: boolean;
}

interface SortObject {
  sort_field: string;
  sort_method: string;
}

export default function Sorting() {
  const [selectSortDropDownOpen, setSelectSortDropDownOpen] = useState<boolean>(false);
  const [studyCheckedItems, setStudyCheckedItems] = useState<CheckedItems>({ positive_entities: true });
  const [patientCheckedItems, setPatientCheckedItems] = useState<CheckedItems>({ positive_entities: true });
  const [SortStudyListItems, setStudySortListItems] = useState(SortLists);
  const [SortPatientListItems, setPatientSortListItems] = useState(SortLists);

  const studySortArray = useSortStore((state) => state.studySortArray);
  const updateStudySortArray = useSortStore((state) => state.newStudySortArray);

  const patientSortArray = useSortStore((state) => state.patientSortArray);
  const updatePatientSortArray = useSortStore((state) => state.newPatientSortArray);

  const clearButtonClicked = useFilterDropDownStore((state) => state.clearButtonClicked);

  const searchType = useSearchTypeStore(state => state.searchType);
  const isStudy = searchType === "study";
  const isPatient = searchType === "patient";

  let sortArray:SortObject[];
  sortArray = searchType === "study" ? studySortArray : searchType === "patient" ? patientSortArray : [];
  
  useEffect(() => {
    if (Array.isArray(sortArray)) {
      const checkedItems: CheckedItems = {};
  
      sortArray.forEach((sortObj) => {
        checkedItems[sortObj.sort_field] = true;
      });
  
      if (isStudy) {
        setStudyCheckedItems(checkedItems);
      } else if (isPatient) {
        setPatientCheckedItems(checkedItems);
      }
    }
  }, [clearButtonClicked, studySortArray, patientSortArray]);

  const handleDropDown = (value: boolean) => {
    setSelectSortDropDownOpen(value);
  };

  const handleChange = (value: string) => {
    const checkedItems = isStudy ? studyCheckedItems : isPatient ? patientCheckedItems : {};
    const sortArray = isStudy ? studySortArray : isPatient ? patientSortArray : [];
    const updateSortArray = isStudy ? updateStudySortArray : isPatient ? updatePatientSortArray : ((array: SortObject[]) => {});
    const sortListItems = isStudy ? SortStudyListItems : isPatient ? SortPatientListItems : [];

    const isChecked = !checkedItems[value];
    const newCheckedItems = { ...checkedItems, [value]: isChecked };

    if (isStudy) {
        setStudyCheckedItems(newCheckedItems);
    } else if (isPatient) {
        setPatientCheckedItems(newCheckedItems);
    }

    let updatedSortArray: SortObject[];
    if (!isChecked) {
        updatedSortArray = sortArray.filter((sortObject) => sortObject.sort_field !== value);
    } else {
        const newSortObject = { sort_field: value, sort_method: "ASC" };
        updatedSortArray = [...sortArray, newSortObject];
    }

    const sortedArray = sortListItems
        .filter(item => newCheckedItems[item.value] || item.value === value)
        .map(item => updatedSortArray.find((sortItem) => sortItem.sort_field === item.value))
        .filter((item): item is { sort_field: string, sort_method: string } => !!item);

    updateSortArray(sortedArray);
};

  const handleDragEndDebounced = debounce((result: DropResult) => {
    handleDragEnd(result);
  }, 100); 

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    let newListItems;
    let setListItems;
    let sortArray: SortObject[];
    let updateSortArray: (array:SortObject[]) => void;

    if (isStudy) {
      newListItems = Array.from(SortStudyListItems);
      setListItems = setStudySortListItems;
      sortArray = studySortArray;
      updateSortArray = updateStudySortArray;
    } else if (isPatient) {
      newListItems = Array.from(SortPatientListItems);
      setListItems = setPatientSortListItems;
      sortArray = patientSortArray;
      updateSortArray = updatePatientSortArray;
    } else {
      return; 
    }
 
    const [reorderedItem] = newListItems.splice(result.source.index, 1);
    newListItems.splice(result.destination.index, 0, reorderedItem);
    setListItems(newListItems);

    let tempSortArray: SortObject[] = [];
    newListItems.forEach((item) => {
      const sortObject = sortArray?.find((sortItem) => sortItem?.sort_field === item.value);
      if (sortObject) {
        tempSortArray.push(sortObject);
      }
    });
    updateSortArray(tempSortArray);
  };
  let sortList = isStudy ? SortStudyListItems : isPatient ? SortPatientListItems :[];
  return (
    <DragDropContext onDragEnd={handleDragEndDebounced}>
      <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
        <button
          className="bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center"
          type="button">
          Sort
          <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
          </svg>
        </button>

        <div id="dropdownHover" className={`${selectSortDropDownOpen ? "absolute" : "hidden"} w-auto`}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul
                className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2"
                aria-labelledby="dropdownHoverButton"
                ref={provided.innerRef}
                {...provided.droppableProps}>
                {sortList?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          transition: "all 0.1s ease",
                        }}>
                        <div className="block px-2 py-2 group/item hover:bg-gray-500  dark:hover:bg-gray-600 bg-gray-800 text-white ">
                          <div className="flex items-center gap-2 justify-between">
                            <div className="flex items-center">
                              <span className="">
                                {index + 1}
                                {"."}
                              </span>
                              <div className="group/edit invisible group-hover/item:visible">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z"
                                    fill="#ffffff"
                                  />
                                </svg>
                              </div>
                              <div className="cursor-pointer">
                              <input
                                type="checkbox"
                                checked={isStudy ? studyCheckedItems[item.value] : isPatient ? patientCheckedItems[item.value] : false}
                                onChange={() => handleChange(item.value)}
                                className="h-4 w-4 rounded-sm mr-3 cursor-pointer"
                              />
                              </div>
                              <span className="py1">{item.title}</span>
                            </div>
                            {item.value === "positive_entities" && (
                              <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 text-xs font-medium text-blue-500 ring-1 ring-inset ring-indigo-700/10">
                                beta
                              </span>  
                            )}
                              <div className="mr-2">
                                {item.value !== "positive_entities" && 
                                    <SortByAscDsc sortField={item.value} />               
                                }
                              </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
}
