import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface NewsModalProps {
  isOpen: boolean;
  onClose: () => void;
  content:any
}
interface ReleaseNote {
  body: string;
  title: string;
  type: string;
}

interface Release {
  date: string;
  releaseNotes: ReleaseNote[];
}

interface GroupedRelease {
  type: string;
  releases: Release[];
}

const NewsModal: React.FC<NewsModalProps> = ({ isOpen, onClose, content }) => {



   // Function to group releases by type
  const groupReleasesByType = (releases: Release[]): GroupedRelease[] => {
    const grouped: { [key: string]: Release[] } = {};

    releases?.forEach(release => {
      release?.releaseNotes?.forEach(note => {
        if (!grouped[note.type]) {
          grouped[note.type] = [];
        }
        grouped[note.type].push(release);
      });
    });

    // Convert the grouped object to an array
    return Object?.entries(grouped)
      .map(([type, releases]) => ({ type, releases }))
      .filter(group => group.releases.length > 0);
  };

  // Grouped and filtered data
  const groupedReleases = groupReleasesByType(content);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h5" className="text-2xl font-bold leading-6 text-gray-900 flex justify-between">
                  What's New
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </Dialog.Title>
                <div className="mt-4 flex flex-col space-y-5 text-sm">
                {groupedReleases?.map(group => (
                <div key={group.type }>
                <div className="text-gray-900 font-bold text-base pb-2.5">{`${group.type}`}</div>
                    <div className="flex flex-col space-y-2">
                      {group?.releases?.map((release, index)=>(
                        <div key={index}>
                          {release?.releaseNotes?.map((note, i) => (
                            <div key={i}>
                              <div className="text-blue-700 font-medium">{note.title}</div>
                              <div>{note.body}</div>
                            </div>
                          ))} 
                        </div>
                      ))} 
                    </div>
                  </div>
                ))} 
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewsModal;
