import axios from 'axios';
const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;
export async function getTeamProjects(jwt_token: string) {
    const response = await axios.get(`${server_url}/user/organization/all_users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response;
}
export async function getTeamsCohortNumber(project_id: number, cohort_id: number, email: string, jwt_token: string) {
    const response = await axios.get(`${server_url}/cohort/all`, {
        params: {
            'cohort_id': cohort_id,
            'project_id': project_id,
            'email': email
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response.data;
}