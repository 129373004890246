import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import  TypeToSearch  from "../common/TypeToSearch"
import { useFilterDropDownStore } from '../../store/filterDropDownStore';
import memoizeOne from 'memoize-one';
import { useSearchStore } from '../../store/searchStore';
import { useFilterStore } from '../../store/filterStore';
import {crossDataFiltering} from '../../utils/cross_filtering_in_dropdowns'
import { alldropdowns , advancedFilterDropdowns} from '../filterControls/dropdownList'


interface AdvancedFilterDropDownProps{
  label: string;
  field:string;
  selectedOptions: string[];
  advancedFilterListData?: any;
  allData : string[];
  setDropdownListData : Dispatch<SetStateAction<string[]>>;
  allFilterData: string[];
  setAllFilterData: Dispatch<SetStateAction<string[]>>;
  setSelectedOptions: Dispatch<SetStateAction<any>>;
}

export default function AdvancedFilterDropDown({
  label,
  field,
  advancedFilterListData,
  allData,
  setDropdownListData,
  allFilterData,
  setAllFilterData,
  setSelectedOptions,
  selectedOptions
}:Readonly<AdvancedFilterDropDownProps>){
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isValidRegex, setIsValidRegex] = useState(true);
  
  const startIndex =  useFilterDropDownStore(state => state.startIndex);
  const setStartIndex =  useFilterDropDownStore(state => state.setStartIndex);
  const endIndex =  useFilterDropDownStore(state => state.endIndex);
  const setEndIndex =  useFilterDropDownStore(state => state.setEndIndex);
  let matchedItems: any = [];
  let umMatchedItem: any = [];
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const {crossFilterObjectFiltering } = crossDataFiltering();

  const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
  const crossFilterSelection =useFilterDropDownStore(state => state.crossFilterSelection);
  const setCrossFilterSelection =useFilterDropDownStore(state => state.setCrossFilterSelection);
  const setFilterDropdowns = useFilterDropDownStore(state=>state.setFilterDropdowns);
  const tempCrossFilterData = crossFilterSelection;
  const filters = useFilterStore(state => state.filters);

  useEffect(() => {
    function handleMouseDown(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    }
  })

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const scrollPosition = event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    const threshold = event.currentTarget.scrollHeight - 100; 
    if (scrollPosition >= threshold) {
        const allListData = searchText ? [...allFilterData] : [...allData]
        if(startIndex + 500 < allListData.length) {
          setDropdownListData((prevData:any) => [...prevData, ...allListData.slice(startIndex+500,endIndex+500)])
          setStartIndex(startIndex+500);
            setEndIndex(endIndex + 500);
      }
    }
  };

  function hasNoSpecialCharacters(input: string): boolean {
    // Regex pattern to allow only alphanumeric characters and underscore
    const regex = /^[a-zA-Z0-9_]+$/;
    return regex.test(input);
  }

  function validateRegex(pattern:any) {
    let parts = pattern.split('/'),
        regex =  pattern.replace(/\*/g, '.*'),
        options = "";
    if (parts.length > 1) {
        regex = parts[1];
        options = parts[2];
    }
    if (pattern.endsWith('|')) {
      return false;
    }
    try {
      const regexPattern = `^${regex}$, ${options}`
      const allowedRegex = /^[a-zA-Z0-9|()*+?.,[\]\\^$ -]+$/;
      if (!allowedRegex.test(regexPattern)) {
        setIsValidRegex(true);
      } else if (regexPattern.endsWith("|")) {
        setIsValidRegex(true);
      } else {
        if (hasNoSpecialCharacters(regexPattern) || regexPattern.startsWith("\\d")) {
          new RegExp(regexPattern, "i");
        } else {
          let adjustedPattern = regexPattern.replace(/(?<!\.)\*/g, ".*");
          if (regexPattern.startsWith("\\b") && regexPattern.endsWith("\\b")) {
            adjustedPattern = regexPattern.replace(/\\b/g, "");
          }
          new RegExp(`^${adjustedPattern}$`, "i");
        }
        return true;
      }
    }catch(e) {
        return false;
    }
  }

  const handleSearch = (searchValue: string) => {
    setStartIndex(0);
    setEndIndex(499);
    let isValidRegexp = validateRegex(searchValue);
    if (isValidRegexp !== undefined) {
      setIsValidRegex(isValidRegexp);
    }
    if (isValidRegexp) {
      if (searchValue?.length > 0) {
        setSelectedOptions([searchValue]);
      } else {
        setSelectedOptions([]);
        setSearchText("");
      }
      let regExp:any;
      try {
        const allowedRegex = /^[a-zA-Z0-9|()*+?.,[\]\\^$ -]+$/;
        if (!allowedRegex?.test(searchValue)) {
          setIsValidRegex(true);
        } else if (searchValue.endsWith("|")) {
          setIsValidRegex(true);
        } else {
          if (hasNoSpecialCharacters(searchValue) || searchValue.startsWith("\\d")) {
            regExp = new RegExp(searchValue, "i");
          } else {
            let adjustedPattern = searchValue.replace(/(?<!\.)\*/g, ".*");
            if (searchValue?.startsWith("\\b") && searchValue.endsWith("\\b")) {
              adjustedPattern = searchValue.replace(/\\b/g, "");
            }
            regExp = new RegExp(`^${adjustedPattern}$`, "i");
          }
        }
      } 
      catch(e) {
        console.log(e,"error");
      }
      const memoizedFilter = memoizeOne((data: any[], value: string) => {
        data?.filter((item) => {
          if (regExp?.test(item?.grouping_field)) {
            item.isChecked = true;
            matchedItems.push(item);
          } else {
            item.isChecked = false;
            umMatchedItem.push(item);
          }
        });
        const sortedItems = [
          ...matchedItems.sort(
            (a: any, b: any) => b.grouping_field - a.grouping_field
          ),
          ...umMatchedItem.sort(
            (a: any, b: any) => b.grouping_field - a.grouping_field
          ),
        ];
        return sortedItems;
      });
  
      const sortedItems = memoizedFilter(allData, searchValue);
  
      setAllFilterData(sortedItems);
      if (searchValue?.length > 0) {
        setDropdownListData(sortedItems?.slice(0, 499));
      } else {
        allData?.forEach((option: any) => (option.isChecked = false));
  
        setDropdownListData(allData?.slice(0, 499));
      }
    }
  };

  useEffect(() => {
    if (searchText.length > 0) {
      setSelectedOptions([searchText]);
      tempCrossFilterData[field] = searchText;
      setCrossFilterSelection(tempCrossFilterData);
      setFilterDropdowns(alldropdowns.filter((value) => value !== field));
    } else {
      if(advancedFilterDropdowns.includes(field)){
        setSelectedOptions([]);
        const updatedCrossFilter = crossFilterObjectFiltering(field);
        setCrossFilterSelection(updatedCrossFilter);
      }
      advancedFilterListData?.forEach((option: any) => (option.isChecked = false));
      setDropdownListData(advancedFilterListData?.slice(0, 499));
    }
  }, [searchText]);

  return (
    <div className="flex flex-row justify-between py-2">
      <div className="w-full" ref={dropdownRef}>
        <Listbox as="div" className="space-y-1 w-full" disabled={filters.cross_filter ? isRetrievingStudies : false}>
          {() => (
            <div className="relative">
              <span className="text-sm cursor-default relative w-full focus:none focus:none  focus:outline-none  transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <TypeToSearch
                      handleSearch={handleSearch}
                      setSearchText={setSearchText}
                      searchText={searchText}
                      label={label}
                      field={field}
                      isValidRegex={isValidRegex}
                      setIsValidRegex={setIsValidRegex}
                      selectedOptions={selectedOptions}
                />
              </span>

              <Transition
                unmount={false}
                show={isOpen}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
              >             
                {isValidRegex && advancedFilterListData?.length > 0 && (
                  <Listbox.Options
                    onScroll={handleScroll}
                    static
                    className="text-base max-h-60 rounded-md py-1 leading-6 shadow-xs overflow-x-hidden overflow-y-auto no-scrollbar focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {advancedFilterListData?.length > 0 &&
                      advancedFilterListData?.map((study: any, key: number) => {
                        if (selectedOptions.length > 0) {
                          let regExp
                          try{
                            validateRegex(selectedOptions[0]);
                            const allowedRegex = /^[a-zA-Z0-9|()*+?.,[\]\\^$ -]+$/;
                            if (!allowedRegex?.test(selectedOptions[0])) {
                              setIsValidRegex(true);
                            } else if (selectedOptions[0].endsWith("|")) {
                              setIsValidRegex(true);
                            } else {
                              if (hasNoSpecialCharacters(selectedOptions[0]) || selectedOptions[0].startsWith("\\d")) {
                                regExp = new RegExp(selectedOptions[0], "i");
                              } else {
                                let adjustedPattern = selectedOptions[0].replace(/(?<!\.)\*/g, ".*");
                                if (selectedOptions[0].startsWith("\\b") && selectedOptions[0].endsWith("\\b")) {
                                  adjustedPattern = selectedOptions[0].replace(/\\b/g, "");
                                }
                                regExp = new RegExp(`^${adjustedPattern}$`, "i");
                              }
                            }
                          }catch(e){
                            console.log(e,"error");
                          }
                          if (regExp?.test(study?.grouping_field)) {
                            study.isChecked = true;
                          } else {
                            study.isChecked = false;
                          }
                        }
                        if (study.isChecked === undefined) {
                          study.isChecked = false;
                        }
                        return (
                          <Listbox.Option key={key} value={key}>
                            {({ active }) => (
                              <div className="flex flex-row">
                                <div
                                   className={`${
                                    active
                                     ? "text-white bg-blue-600" 
                                     : "text-gray-900"} ${
                                    selectedOptions.includes(
                                      (study.grouping_field !== null && study.grouping_field.toLowerCase()) || study.grouping_field
                                    ) ||
                                    (selectedOptions.includes("null") && study.grouping_field === null) ||
                                    (study.isChecked && true)
                                      ? "text-white bg-blue-600"
                                      : ""                                
                                  } group/item cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-28 flex flex-row items-center justify-start`}
                                >
                                  <div className="flex flex-row w-full justify-start items-center">
                                    <div
                                      className="flex flex-row w-full justify-start items-center"
                                      style={{
                                        width: "210px",
                                        maxWidth: "210px",
                                        minWidth: "210px",
                                      }}
                                    >                              
                                      <span
                                        title={study.grouping_field}
                                        className={`${
                                          selectedOptions.includes(
                                            (study.grouping_field !== null &&
                                              study.grouping_field.toLowerCase()) ||
                                              study.grouping_field
                                          ) ||
                                          (selectedOptions.includes("null") &&
                                            study.grouping_field === null) ||
                                          ((study.isChecked) &&
                                            true)
                                            ? "font-semibold"
                                            : "font-normal"
                                        } text-left truncate text-ellipsis text-sm w-full`}
                                      >
                                        {study.grouping_field === null
                                          ? "null"
                                          : study.grouping_field}
                                      </span>
                                    </div>
                                    <span
                                      className="text-right ml-3 text-sm"
                                      title={study.count_of_field}
                                    >
                                      {study.count_of_field > 1000 ? (
                                        !isNaN(study.count_of_field) &&
                                        (study.count_of_field / 1000).toFixed(
                                          1
                                        ) + "k"
                                      ) : (
                                        study.count_of_field
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                  </Listbox.Options>
                )}
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
}