import { useState } from "react";
import { useAuthStore } from "../../../store/authStore";
import { deleteSaveSearch, getSaveSearchResult } from "../../../utils/request_save_searches";
import { useSavedSearchStore } from "../../../store/savedSeachStore";

export default function DeleteSavedSearch(props:any) {
const  {currentSelectedSearchName,setShowDeleteSearchModal, isSelected}=props;
// Auth State
    const token = useAuthStore(state => state.token);
//saved search state
    const savedSearchPaginationOffset = useSavedSearchStore(state => state.savedSearchPaginationOffset);
    const setSavedSearchList = useSavedSearchStore(state =>state.setSavedSearchList);
// Local State
    const [isLoading, setIsLoading] = useState(false);
    const [textForDeleteProject, setTextForDeleteProject] = useState<string>('');
   
    const handleDeleteSearch=async ()=>{
        try {
            if (token) {
                setIsLoading(true);
                const response = await deleteSaveSearch(currentSelectedSearchName?.id, token);
                if (response.status === 200) {
                const fetchSearchResult= await getSaveSearchResult(savedSearchPaginationOffset,isSelected,token);
                if(fetchSearchResult.status===200){
                    const savedSearches = fetchSearchResult.data;
                        setSavedSearchList(savedSearches);
                }
                    setIsLoading(false);
                    setShowDeleteSearchModal(false);
                    setTextForDeleteProject('');
                }
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setShowDeleteSearchModal(false);
            setTextForDeleteProject('');
        }
    }

    return(
        <div className="px-4 py-5 sm:p-6">
        <h3 className="font-bold leading-6 text-gray-900 text-2xl whitespace-nowrap">Sure you want to delete ?</h3>
        <p className='text-sm text-gray-500 mt-2'>
            You're about to delete your search.Deleted records can't be restored.
        </p>
        <form className="mt-5 sm:flex sm:items-center flex-col" onSubmit={handleDeleteSearch}>
            <div className="w-full sm:max-w-xs">
                <p className='whitespace-nowrap font-semibold'>Type "delete" to delete this search</p>
                <input
                    type="text"
                    disabled={isLoading}
                    name="projectName"
                    id="projectName"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:text-gray-500 disabled:bg-gray-100 disabled:border-gray-200 disabled:shadow-none mt-2"
                    value={textForDeleteProject}
                    placeholder='Type " delete " '
                    onChange={(e) => setTextForDeleteProject(e.target.value)}
                />
            </div>
            <div className='flex mt-5 w-full'>
                <button
                    disabled={textForDeleteProject.toLowerCase() !== "delete"}
                    type='button'
                    onClick={handleDeleteSearch}
                    className={`mt-3 inline-flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-24  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed ${isLoading ? 'text-white bg-red-600 cursor-not-allowed pointer-events-none': ''}`}
                >
                    {isLoading ? "Deleting.." : "Delete"}
                </button>
                <button
                    type='button'
                    onClick={()=>setShowDeleteSearchModal(false)}
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-24  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                    Cancel
                </button>
            </div>
        </form>
    </div>
    )
}