import RangeSlider from '../../common/RangeSlider';
import { useFilterStore } from '../../../store/filterStore';
import { usePatientStore } from '../../../store/patientStore';
import { useSearchTypeStore } from '../../../store/searchTypeStore';
type AgeFilterProps = {
    id?: number;
}

export default function AgeFilter({id}: AgeFilterProps) {
  const ageSelections = useFilterStore(state => state.ageSelections);
  const setAgeSelections = useFilterStore(state => state.setAgeSelections);
  const setPatientAgeSelections = usePatientStore(state => state.setAgeSelections);
  const patientFilters = usePatientStore(state => state.filters);
  const searchType = useSearchTypeStore(state => state.searchType);
  
  return (
    <>
    <div className='flex flex-row justify-between py-4 z-0'>
        <p className='text-base'>Age</p>
        <div className="w-3/5">
            <RangeSlider 
              className='w-40 h-8' 
              //we are using  patientFilters[0] for both filters in patient search 
              value={searchType === "patient" ? [patientFilters[0].filters.age.min, patientFilters[0].filters.age.max ] : ageSelections} 
              defaultValue={[0,90]}
              max={90}
              updateFilter={(values: number[]) => {
                searchType === "patient" ? setPatientAgeSelections(values) : setAgeSelections(values)
              }}
            />
        </div>
    </div>
    </>
  );
};

