export function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout | null = null;

    return function executedFunction(...args: any[]): void {
        const later = () => {
            if (timeout) {
                clearTimeout(timeout);
                func(...args);
            }
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(later, wait);
    }
}