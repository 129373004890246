import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useUserStore } from "../../store/userStore";
import { useAdvancedFilterStore } from "../../store/advancedFiltersStore";
import {
  AdvancedSearchRequest,
  SearchRequest,
  SearchResult,
} from "../../types/search";
import { useSearchStore } from "../../store/searchStore";
import { getCsvDownloadLimit, getPatientsWithDownloadLimit, getStudiesWithDownloadLimit } from "../../utils/request_studies";
import { useAuthStore } from "../../store/authStore";
import { saveAs } from "file-saver";
import { LoadingSpinner } from "../common/loadingSpinner";
import {
  whichGeoIsSource,
  whichStateIsSource,
  whichTypeIsSource,
} from "../../utils/data_formatting";
import DownloadLimitModal from "../modals/DownloadLimitModal";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { usePatientStore } from "../../store/patientStore";
import { usePatient1AdvancedFiltersStore } from "../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../store/patient2AdvancedFiltersStore";
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";
import { downloadStudiesInCohort } from "../../utils/request_cohort";
import { ALLOWED_EMAIL_DOMAINS } from "../../utils/allowed_email_domains";

interface StudyData {
  row_id: string;
  institution: string;
  patient_id: string;
  accession_number: string;
  study_instance_uid?: string;
  deid_english_report: string;
  patient_sex: string;
  study_date: string;
  parsed_patient_age: string;
  country_of_residence: string;
  series_description: string;
  modality: string;
  slice_thickness: string;
  manufacturer: string;
  slices: string;
  study_count: number;
  state: string;
  service_type: string;
  imputed_ethnicity: string;
  imputed_race: string;
}
interface DownloadCsvProps{
  module?: string;
}

const COUNTOPTIONS =[2000,4000,8000]
const OFFSETCOUNTOPTIONS =[0,1000,2000,4000,5000,6000,7000,8000]
const STRATEGYOPTIONS = ["First","Random"]
export default function DownloadCsv({
  module,
}:Readonly<DownloadCsvProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);
  const selectedProject = useUserStore((state) => state.selectedProject);
  const gradientUser = useUserStore((state) => state.gradientUser);
  const selectedProjectOwnerEmail = useUserStore((state) => state.selectedProjectOwnerEmail);
  const searchType = useSearchTypeStore((state) => state.searchType);
  const searchRequest = useSearchStore((state) => state.searchRequest);
  const patientFilters = usePatientStore((state) => state.filters);

  // Advanced filter state
  const advancedFiltersEnabled = useAdvancedFilterStore((state) => state.advancedFiltersEnabled);
  const advancedFilters = useAdvancedFilterStore((state) => state.advancedFilters);

  // Patient 1 Advanced filter state
  const advancedFiltersEnabled1 = usePatient1AdvancedFiltersStore((state) => state.advancedFiltersEnabled);
  const advancedFilters1 = usePatient1AdvancedFiltersStore((state) => state.advancedFilters);

  // Patient 2 Advanced filter state
  const advancedFiltersEnabled2 = usePatient2AdvancedFiltersStore((state) => state.advancedFiltersEnabled);
  const advancedFilters2 = usePatient2AdvancedFiltersStore((state) => state.advancedFilters);
  const timeBetween = usePatientStore((state) => state.timeBetween);

  const token = useAuthStore((state) => state.token);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [downloadCount, setDownloadCount] = useState(0);
  const [count, setCount] = useState(2000);
  const [offset, setOffset] = useState(0);
  const [strategy, setStrategy] = useState("First");
  const [isDownloadLimitExceeded, setIsDownloadLimitExceeded] = useState(false);
  const [isDownloadLimitModalOpen, setIsDownloadLimitModalOpen] = useState(false);
  const [studyDownloadLimitMessage, setStudyDownloadLimitMessage] = useState<string>("");
  const STANDARD_DAILY_NUMBER_OF_CSV_DOWNLOADS = 3;
  let fetchedStudies: SearchResult;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const { data } = await getCsvDownloadLimit(token);
          setDownloadCount(data.n_downloads_today);
        }
      } catch (error) {
        console.log("Error generating csv : ", error);
      }
    };

    fetchData();
  }, [token]);
  const handleDropDown = async (value: boolean) => {
    if (!isLoading) {
      setSelectVolumeOpen(value);
    }
  };

  const isAllowedEmail = ALLOWED_EMAIL_DOMAINS?.find(domain => gradientUser?.email?.includes(domain));

  const handleDownloadClick = async () => {
    const { data } = await getCsvDownloadLimit(token);
    setDownloadCount(data.n_downloads_today);
    if (data.n_downloads_today >= STANDARD_DAILY_NUMBER_OF_CSV_DOWNLOADS && !isAllowedEmail) {
      setIsDownloadLimitExceeded(true);
      setIsDownloadLimitModalOpen(true);
    } else {
      module === "main" ? setModalIsOpen(true) : handleDownloadCohortStudies();
    }
  };

  const handleDownload = async () => {
    if (selectedProject !== null) {
      setIsLoading(true);
      let fileName = selectedProject?.project_name;

      let tempStudySearchRequest: any;
      let tempPatientSearchRequest: any;

      if (searchType === "study") {
        if (advancedFilters && advancedFiltersEnabled) {
          tempStudySearchRequest = {
            ...searchRequest,
            advancedFilters
          };
        } else {
          tempStudySearchRequest = searchRequest;
        }
        tempStudySearchRequest = {
          ...tempStudySearchRequest,
          pagination: {
            pageSize: count,
            offset: offset
          },
          no_thumbnails: true
        };
      } else if (searchType === "patient") {
        let tempFilters = patientFilters;

        tempFilters[0].search_summary = "";
        tempFilters[1].search_summary = "";
        tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms);
        tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms);
        if (advancedFiltersEnabled1) {
          tempFilters[0].advancedFilters = advancedFilters1;
        }
        if (advancedFiltersEnabled2) {
          tempFilters[1].advancedFilters = advancedFilters2;
        }
        if ((advancedFilters && advancedFiltersEnabled1) || (advancedFilters && advancedFiltersEnabled2)) {
          tempPatientSearchRequest = {
            filters: [...tempFilters],
            advancedFilters
          };
        } else {
          tempPatientSearchRequest = patientFilters;
        }

        tempPatientSearchRequest = {
          filters: [...tempFilters],
          pagination: {
            pageSize: count,
            offset: offset
          },
          timeBetween: timeBetween
        };
      }


      try {
        if (token) {
          if (searchType === "study") {
            const { data: studyData } = await getStudiesWithDownloadLimit(tempStudySearchRequest, token, strategy);
            fetchedStudies = studyData;
          } else if (searchType === "patient") {
            const { data: patientData } = await getPatientsWithDownloadLimit(tempPatientSearchRequest, token, strategy);
            fetchedStudies = patientData;
          }
          setDownloadCount(downloadCount + 1);

          // Convert the grouped data into an array
          let mergedCSVData = buildCsvdata(fetchedStudies);

          try {
            buildCsv(mergedCSVData,fileName);
            setModalIsOpen(false);
          } catch (error) {
            console.log("Error generating csv : ", error);
          }
        }
      } catch (error: any) {
        console.log("error : ", error);
      }
      setIsLoading(false);
    }else{
      setIsLoading(false);
      setModalIsOpen(true);
    }
  };

  const buildCsvdata = (studayDatas: any) => {
    let groupedData: { [key: string]: StudyData } = {};
    let studies = module === "main" ? studayDatas.results : studayDatas;
    studies.forEach((study: any) => {
      study.series_metadata.forEach((series: any) => {
        const csvData = {
          row_id: `'${study.row_id}'`,
          institution: study.institution,
          state: whichStateIsSource(study.institution),
          patient_id: study.study_metadata.patient_id,
          accession_number: study.accession_number,
          study_instance_uid: study.study_instance_uid,
          deid_english_report: study.text,
          patient_sex: study.study_metadata.patient_sex,
          study_date: study.study_metadata.study_date,
          parsed_patient_age: study.study_metadata.parsed_patient_age,
          country_of_residence: whichGeoIsSource(study.institution),
          service_type: whichTypeIsSource(study.institution),
          modality: series.modality,
          series_description: series.series_description,
          slice_thickness: series.slice_thickness,
          manufacturer: series.manufacturer,
          slices: series.number_of_frames,
          study_count: study.study_count,
          imputed_ethnicity: study?.imputed_ethnicity,
          imputed_race: study?.imputed_race,
          sequence_name: series?.sequence_name,
          diffusion_bvalue: series?.diffusion_bvalue,
          positive_entities: study?.positive_entities,
        };

        const rowId = csvData.row_id;
        if (!groupedData[rowId]) {
          groupedData[rowId] = { ...csvData };
        } else {
          groupedData[rowId].modality += `, ${csvData.modality}`;
          groupedData[
            rowId
          ].series_description += `, ${csvData.series_description}`;
          groupedData[rowId].slice_thickness += `, ${csvData.slice_thickness}`;
          groupedData[rowId].manufacturer += `, ${csvData.manufacturer}`;
          groupedData[rowId].slices += `, ${csvData.slices}`;
        }
      });
    });

    let mergedData = Object.values(groupedData);
    return mergedData;
  };

  const buildCsv = async (mergedCSVData: StudyData[], fileName: string) => {
    try {
      let converter = require("json-2-csv");
      const csv = await converter.json2csv(mergedCSVData, {});
      const blob = new Blob([csv], { type: "text/csv" });
      saveAs(blob, `${fileName}.csv`);
    } catch (error) {
      console.log("Error generating csv : ", error);
    }
  };

  const handleDownloadCohortStudies = async () => {
    if (selectedProject !== null) {
      setIsLoading(true);
      let fileName = selectedProject.project_name;

      if (token) {
        try {
          if(selectedProject?.cohort?.studies?.length > 8000){
            setStudyDownloadLimitMessage("Cohort with more than 8000 studies cannot be downloaded");
            setModalIsOpen(true);
            setIsLoading(false);
          }else{
            const cohortData = await downloadStudiesInCohort(selectedProjectOwnerEmail,selectedProject?.cohort.id,selectedProject?.id,token);
            const studies = cohortData.studies;
            let mergedCSVData = buildCsvdata(studies);
            buildCsv(mergedCSVData, fileName);
            setDownloadCount(downloadCount + 1);
            setModalIsOpen(false);
            setIsLoading(false);
          }
        } catch (error) {
          console.log("error : ", error);
        }
      }
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
        <button
          disabled={isLoading || (isDownloadLimitExceeded && !isAllowedEmail)}
          className={`border-gray-700 rounded-lg text-sm px-4 py-1.5 text-center inline-flex items-center ${
            isDownloadLimitExceeded && !isAllowedEmail ? "opacity-50 cursor-not-allowed" : ""
            } ${module==="cohort" ? "rounded-lg py-2 bg-white text-blue-600 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-300" : "bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white"}`}
          type="button"
          onClick={handleDownloadClick}>
          Download { module === "cohort" && "CSV"} 
          {isLoading && module === "cohort" && (
            <span className="px-1">
              <LoadingSpinner message="" size="sm" flex="row" />
            </span>
          )}
        </button>
        <div
          id="dropdownHover"
          className={`absolute ${
            selectVolumeOpen ? "" : "hidden"
            } rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-2 whitespace-nowrap w-auto ${
              module === "cohort" ? "" : "right-0 left-auto"
              }`}>
          <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
            <li>
              {!isAllowedEmail ? (
                <span className="block px-2 dark:hover:bg-gray-600 bg-gray-800 text-white">
                  CSV Studies - {downloadCount} /{STANDARD_DAILY_NUMBER_OF_CSV_DOWNLOADS} Today
                </span>
              ) : (
                <span className="block px-2 dark:hover:bg-gray-600 bg-gray-800 text-white">
                  CSV Studies - {downloadCount} Today</span>
              )}
            </li>
          </ul>
        </div>
      </div>
      {isDownloadLimitExceeded && (
        <DownloadLimitModal isOpen={isDownloadLimitModalOpen} onClose={() => setIsDownloadLimitModalOpen(false)} />
      )}
      <Transition appear show={modalIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Download CSV
                  </Dialog.Title>
                  {module === "main" ?
                  <>
                    <div className="mt-4">
                      <label htmlFor="count" className="block text-sm font-medium text-gray-700">
                        Count
                      </label>
                      <select
                        id="count"
                        name="count"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={count}
                        onChange={(e) => setCount(parseInt(e.target.value))}>
                        {COUNTOPTIONS?.map((item, index) => (
                          <option key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="count" className="block text-sm font-medium text-gray-700">
                        Offset
                      </label>
                      <select
                        id="offset"
                        name="offset"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={offset}
                        onChange={(e) => setOffset(parseInt(e.target.value))}>
                        {OFFSETCOUNTOPTIONS?.map((item, index) => (
                          <option key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-4">
                      <label htmlFor="strategy" className="block text-sm font-medium text-gray-700">
                        Strategy
                      </label>
                      <select
                        id="strategy"
                        name="strategy"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={strategy}
                        onChange={(e) => setStrategy(e.target.value)}
                      >
                        {STRATEGYOPTIONS?.map((item, index) => (
                          <option key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                  :
                  selectedProject && selectedProject?.cohort?.studies?.length > 8000 &&
                  <div className="mt-4">
                    <label htmlFor="study count download limit" className="block text-sm font-medium text-gray-700">
                        {studyDownloadLimitMessage}
                      </label>
                  </div>
                  }
                  <div className="mt-4">
                    {module === "main" ? 
                    <>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleDownload} disabled={isLoading}>
                        Download
                        {isLoading && (
                          <span className="px-1">
                            <LoadingSpinner message="" size="sm" flex="row" />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 ml-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </>
                     :
                     selectedProject && selectedProject?.cohort?.studies?.length > 8000 && 
                     <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                     Close
                   </button>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
