import { useState, useEffect } from "react";
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useSortStore } from "../../../store/sortStore";
import { useSearchTypeStore } from "../../../store/searchTypeStore";

interface SortByAscDscProps {
  sortField: string;
}
export default function SortByAscDsc({ sortField }: SortByAscDscProps) {
  const [activeIcon, setActiveIcon] = useState("none");
  const studySortArray = useSortStore((state) => state.studySortArray);
  const updateStudySortArray = useSortStore((state) => state.updateStudySortArray);
  const newStudySortArray = useSortStore((state) => state.newStudySortArray);

  const patientSortArray = useSortStore((state) => state.patientSortArray);
  const updatePatientSortArray = useSortStore((state) => state.updatePatientSortArray);
  const newPatientSortArray = useSortStore((state) => state.newPatientSortArray);

  const searchType = useSearchTypeStore(state => state.searchType);
  const isStudy = searchType === "study";
  const isPatient = searchType === "patient";

  useEffect(() => {
    let sortItem;
    const sortArray = isStudy ? studySortArray : isPatient ? patientSortArray : [];
    if(Array.isArray(sortArray)){
      sortItem = sortArray.find((item) => item.sort_field === sortField);
    }

    if (sortItem) {
      setActiveIcon(sortItem.sort_method === "ASC" ? "up" : "down");
    } else {
      setActiveIcon("none");
    }
  }, [studySortArray, patientSortArray, sortField, searchType]);

  const handleSort = async (UpdatedActiveIcon: string) => {
    let sortMethod: string = "";
  
    if (UpdatedActiveIcon === "up") {
      sortMethod = "ASC";
    } else if (UpdatedActiveIcon === "down") {
      sortMethod = "DESC";
    }
  
    setActiveIcon(UpdatedActiveIcon);
    let sortedArray = {
      sort_field: sortField,
      sort_method: sortMethod
    }
    if (sortMethod) {
      const updateSortArray = isStudy ? updateStudySortArray : isPatient ? updatePatientSortArray : undefined;
      if (updateSortArray) {
        updateSortArray(sortedArray);
      }
    } else {
      const updatedSortArray = isStudy
        ? studySortArray.filter(sortObject => sortObject.sort_field !== sortField)
        :
        isPatient ? patientSortArray.filter(sortObject => sortObject.sort_field !== sortField)
        :
        [];

      const newSortArray = isStudy ? newStudySortArray : isPatient ? newPatientSortArray : undefined;
      if (newSortArray) {
        newSortArray(updatedSortArray);
      }
    }
  };

  return (
    <div className="flex justify-start items-center text-white text-center  h-full rounded-r-lg">
      {activeIcon === "up" && <ArrowUpIcon className="h-4 w-5 hover:cursor-pointer" onClick={() => handleSort("down")} />}
      {activeIcon === "down" && <ArrowDownIcon className="h-4 w-5 hover:cursor-pointer" onClick={() => handleSort("up")} />}
      {activeIcon === "none" && <MinusIcon className="h-4 w-5 hover:cursor-not-allowed"/>}
    </div>
  );
}


