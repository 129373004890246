import React from 'react';
import PieChart from './common/PieChart';
import { useChartsStore } from '../../../../../store/chartsStore';
import { addOtherValueToChartData } from '../../../../../utils/data_formatting';

export default function InstitutionPieChart() {
  // Charts Store
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { institution: institutionDescriptionData } = cohortsChartsData;

  let ranges: { [key: string]: number } = {};
  if (institutionDescriptionData) {
    Object.keys(institutionDescriptionData || {}).forEach((key) => {
      const label = key.split('-')[0];
      if (ranges[label] == null) {
        ranges[label] = 0;
      }
      ranges[label] += (institutionDescriptionData?.[key] as unknown as number) || 0;
    });

    let sortedEntries = Object.entries(ranges).sort(([, valueA], [, valueB]) => (valueB as number) - (valueA as number));
    const otherIndex = sortedEntries.findIndex(([key]) => key === "Other");

    if (otherIndex !== -1 && otherIndex < 10) {
      sortedEntries.splice(otherIndex, 1);
    }

    let filteredRanges = Object.fromEntries(sortedEntries.slice(0, 10));
    addOtherValueToChartData(sortedEntries,filteredRanges)
    ranges = filteredRanges;
  }

  return (
    <PieChart
      data={ranges}
      colors={["#60A5FA", "#3B82F6", "#14B8A6", "#34D399", "#84CC16", "#E11D48", "#A78BFA", "#F472B6", "#10B981", "#6366F1", "#0000FF"]}
      chartId="institution-pie-chart"
    />
  );
}