import { useOrganization } from "@clerk/clerk-react";
import { useState } from "react";
import { useToastStore } from "../../store/toastStore";

type MembershipRole = 'admin' | 'basic_member' | 'guest_member';

export default function InviteUserPanel() {
    const { organization } = useOrganization();

    // Toast Store
    const setShow = useToastStore(state => state.setShowSuccessfulInvitationToast)

    // Local State
    const [role, setRole] = useState<MembershipRole>('basic_member');
    const [email, setEmail] = useState('');
    const [ disabled, setDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ isValid, setIsValid ] = useState(false);

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      };

    const handleSubmit = async(e: any) => {
        e.preventDefault();
        if(isValid) {
            setLoading(true);
            setDisabled(true);
            await organization?.inviteMember({
                emailAddress: email,
                role: role
            })
            setShow(true);
            setEmail('');
            setLoading(false);
            setDisabled(false);
            setRole('basic_member');
        }
    }

    const handleChange = (e: any) => {
        setEmail(e.target.value);
        setIsValid(validateEmail(e.target.value));
    }


    return (
        <div className="bg-white shadow sm:rounded-lg border w-1/3 mt-4">
                <div className="px-4 py-2 sm:p-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Invite a team member</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>We will send an email to your team member to get setup with a Gradient Health Account.</p>
                    </div>
                    <form className="mt-5 flex flex-col sm:items-center" onSubmit={handleSubmit}>
                    <div className="space-y-4 mr-auto sm:flex sm:items-center sm:space-x-10 sm:space-y-0 justify-start">
                        <div className="flex items-center">
                            <input
                                id="member"
                                name="notification-method"
                                type="radio"
                                checked={role === 'basic_member'}
                                onChange={() => setRole('basic_member')}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            />
                            <label htmlFor="member" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Member
                            </label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="admin"
                                name="notification-method"
                                type="radio"
                                checked={role === 'admin'}
                                onChange={() => setRole('admin')}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            />
                            <label htmlFor="admin" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Admin
                            </label>
                        </div>
                    </div>
                    <div className="flex w-full mt-4">
                        <div className="w-full sm:max-w-xs">
                            <label htmlFor="email" className="sr-only">
                            Email
                            </label>
                            <input
                            type="email"
                            name="email"
                            id="email"
                            disabled={disabled}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="you@example.com"
                            />
                            {email.length > 0 && !isValid && <p className="text-red-500 text-xs italic">Please enter a valid email.</p>}
                        </div>
                        <button
                            type="submit"
                            disabled={!isValid || disabled}
                            className="disabled:bg-gray-500 mt-3 inline-flex w-1/2 max-h-9 items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 "
                        >
                            {loading ? 'Sending...' : 'Send Invite'}
                        </button>
                    </div>
                    </form>
                </div>
            </div>
    )
}