import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"

export default function EmptyCatalogState() {
  return (
    <div className="text-center mt-32">
      <MagnifyingGlassIcon className="h-12 w-12 mx-auto text-gray-400" />
      <h3 className="mt-2 text-lg font-semibold text-gray-900">Search the Gradient Health Catalog</h3>
      <p className="mt-1 text-md text-gray-500">Get started by searching for a body part or keyword in the search bar above.</p>
    </div>
  )
}
