import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSavedSearchStore } from '../store/savedSeachStore';


export default function SavedSearchPagination() {
    // saved search state
    const savedSearchPaginationOffset = useSavedSearchStore(state => state.savedSearchPaginationOffset);
    const setSavedSearchPaginationOffset = useSavedSearchStore(state => state.setSavedSearchPaginationOffset);
    const savedSearchList = useSavedSearchStore(state => state.savedSearchList);
    const loader = useSavedSearchStore(state => state.loader);

    //local state
    const [pageOptions, setPageOptions] = useState<number[]>([1, 2, 3, 4, 5]);
    const [currentPage, setCurrentPage] = useState<number>(savedSearchPaginationOffset + 1);

    const handleNextPage = async () => {
        setSavedSearchPaginationOffset(savedSearchPaginationOffset + 1);
        setPageOptions(pageOptions.map(page => page + 1));
        setCurrentPage(currentPage + 1);
    }

    const handlePreviousPage = async () => {
        setSavedSearchPaginationOffset(savedSearchPaginationOffset - 1);
        setCurrentPage(currentPage - 1);
    }

    const handlePageNumber = (pageNumber: number) => {
        setSavedSearchPaginationOffset(pageNumber - 1);
        setCurrentPage(pageNumber);

    }

    useEffect(() => {
        setCurrentPage(savedSearchPaginationOffset + 1);
    }, [savedSearchPaginationOffset]);

    return (
        <div className="sticky bottom-0 right-0 ml-auto  ">
            <nav aria-label="Page navigation example">
                <ul className="flex items-center -space-x-px h-8 text-base">
                    <li>
                        <button
                            onClick={handlePreviousPage}
                            disabled={savedSearchPaginationOffset === 0}
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-l-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">
                            <span className="sr-only">Previous</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </button>
                    </li>
                    <li >
                        <button
                            onClick={() => handlePageNumber(currentPage)}
                            className={cn(
                                "flex items-center justify-center px-3 h-8 leading-tight  border hover:bg-gray-700 hover:text-white disabled:cursor-not-allowed",
                                { "text-gray-300 border-gray-600 bg-gray-600": currentPage === currentPage }, { "text-gray-400 border-gray-700 bg-gray-800": currentPage !== currentPage }
                            )}>
                            {currentPage}
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={handleNextPage}
                            disabled={savedSearchList?.length === 0 || loader || savedSearchList.length < 10 || savedSearchList.message }                    
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="sr-only">Next</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}