import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAuthStore } from "../../../store/authStore";
import { createUserAgreementStatus } from "../../../utils/request_user";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { useAgreementModalStore } from "../../../store/agreementModalStore";
import { AgreementLists } from "./agreementLists";
import { useNewsModalStore } from "../../../store/newsModalStore";

interface AgreementModalProps {
  contractVersion: string;
  agreementStatus: any;
  latestVersion: string;
}
export default function AgreementModal({ contractVersion, agreementStatus, latestVersion }: AgreementModalProps) {
  // Auth state
  const token = useAuthStore((state) => state.token);

  // Modal store
  const showAgreementModal = useAgreementModalStore((state) => state.showAgreementModal);
  const setShowAgreementModal = useAgreementModalStore((state) => state.setShowAgreementModal);

  //News modal state
  const setIsNewsOpen =  useNewsModalStore(state => state.setIsNewsOpen);
  const newsContent =  useNewsModalStore(state => state.newsContent);

  const [agreements, setAgreements] = useState(AgreementLists);
  const [isLoading, setIsLoading] = useState(false);

  const handleAgreementChange = (id: string) => {
    setAgreements((prevAgreements) =>
      prevAgreements.map((agreement) => (agreement.id === id ? { ...agreement, checked: !agreement.checked } : agreement))
    );
  };

  const handleAcceptTerms = async () => {
    // if user accepts all the checkboxes accept button is enabled
    const agreement = agreementStatus === 404 ? true : agreementStatus;
    const latest_contract_version = latestVersion;
    try {
      setIsLoading(true);
      if (token) {
        let contract_version;
        if (agreementStatus === 404) {
          contract_version = latest_contract_version;
        } else if (contractVersion !== latest_contract_version) {
          contract_version = latest_contract_version;
        } else {
          contract_version = contractVersion;
        }
        const fetchData = await createUserAgreementStatus(token, agreement, contract_version);
        if (fetchData.status === 200) {
          onClose();
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user agreement status:", error);
    }
  };

  const onOpen = () => {
    setShowAgreementModal(true);
  };
  const onClose = () => {
    setShowAgreementModal(false);
    newsContent.length > 0 && setIsNewsOpen(true);
  };

  const isOpen = showAgreementModal;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onOpen}>
        <div className="min-h-screen px-4 text-center max-w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            ​
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Agreement
              </Dialog.Title>
              <div className="mt-4 flex flex-col gap-2">
                {agreements?.map((agreement) => (
                  <div className="flex items-start" key={agreement.id}>
                    <input
                      type="checkbox"
                      id={agreement.id}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded mt-1.5 cursor-pointer"
                      checked={agreement.checked || false}
                      onChange={() => handleAgreementChange(agreement.id)}
                    />
                    <label htmlFor={agreement.id} className="text-gray-700 ml-2">
                      {agreement.label}
                    </label>
                  </div>
                ))}
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  disabled={!!agreements.find((agreement) => !agreement.checked)}
                  onClick={handleAcceptTerms}
                  className={`mt-3 inline-flex items-start justify-center rounded-md px-5 py-2 text-sm font-semibold shadow-sm ${
                    agreements.find((agreement) => !agreement.checked)
                      ? "bg-gray-400 cursor-not-allowed text-gray-300"
                      : "bg-blue-600 text-white hover:bg-blue-500"
                  }`}>
                  {isLoading ? (
                    <span className="px-1">
                      <LoadingSpinner message="" size="sm" flex="row" />
                    </span>
                  ) : (
                    "Agree"
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
