import { 
  FolderIcon,
  HomeIcon,
  ArrowUpOnSquareIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import { useLocation, Link } from 'react-router-dom';
import { useUserStore } from '../../store/userStore';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import { usePolicyTermsModalStore } from '../../store/policyTermsStore';
import { useProjectsStore } from '../../store/projectsStore';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
const DIGIT_LIMIT_FIVE = 5;
const DIGIT_LIMIT_FOUR = 4;
 
const StudiesInProjectBadge = ({ count }: { count: number | null }) => {
  if (count !== null && count <= 0) return null;
  const translationClass = count === null ? 'translate-x-4': count.toString().length > DIGIT_LIMIT_FIVE ? '-translate-x-2': count.toString().length > DIGIT_LIMIT_FOUR ? '-translate-x-0.5': 'translate-x-4';
  return (
    <div className={`absolute top-0 left-0 transform -translate-y-1/2 ${translationClass} rounded-full bg-blue-500 text-white text-xs px-2 py-1`}>
      {count !== null && count}
    </div>
  );
};

export default function Sidebar() {
    const location = useLocation();
    const currentPath = location.pathname;

    const selectedProject = useUserStore(state => state.selectedProject);
    const setShowPolicyTermsModal = usePolicyTermsModalStore(state => state.setShowPolicyTermsModal)
    const studiesCountInCohort = useProjectsStore(state => state.studiesCountInCohort);
  
    const navigation = [
      { name: 'Dashboard', to: '/', icon: HomeIcon, current: '/' === currentPath },
      { name: 'Cohorts', to: '/projects', icon: FolderIcon, current: '/cohorts' === currentPath },
      { name: 'Upload CSV', to: '/upload', icon: ArrowUpOnSquareIcon, current: '/cohorts' === currentPath },
      //{ name: 'Organization Settings', to: '/org_settings', icon: UserCircleIcon, current: '/settings/organization' === currentPath }
  ];
    
    return (
        <>
        {/* Static sidebar for desktop */}
        <div className="hidden max-h-dvh lg:fixed lg:inset-y-0 lg:left-0 lg:z-10 lg:block lg:w-20 overflow-visible lg:bg-gray-900 lg:pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center">
            <img
              className="h-8 w-auto"
              src="/gh_logo.png"
              alt="Gradient"
            />
          </div>
          <nav className="xl:mt-8 lg:mt-2 flex flex-col justify-between h-screen lg:h-[calc(100%-18vh)]  xl:h-[calc(100%-5rem)] lg:pb-3 xl:pb-4">
            <ul className="flex flex-col items-center space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.to}
                    className={classNames(
                      item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                      'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <Tippy content={item.name} placement="right">
                      <div className='relative'>
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        {item.name === "Cohorts" && <StudiesInProjectBadge count={studiesCountInCohort} />}
                        <span className="sr-only">{item.name}</span>
                      </div>
                    </Tippy>
                  </Link>
                </li>
              ))}
            </ul>
                <p className='text-slate-300 text-xs text-center hover:text-violet-600 active:text-gray-400 cursor-pointer' onClick={() => setShowPolicyTermsModal(true)}>Legal</p>
          </nav>
        </div>
        </>
    )
}