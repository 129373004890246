import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { usePolicyTermsModalStore } from "../../../store/policyTermsStore";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function PolicyTermsModal() {
  // Modal store
  const showPolicyTermsModal = usePolicyTermsModalStore((state) => state.showPolicyTermsModal);
  const setShowPolicyTermsModal = usePolicyTermsModalStore((state) => state.setShowPolicyTermsModal);

  const onClose = () => {
    setShowPolicyTermsModal(false);
  };

  const isOpen = showPolicyTermsModal;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
        aria-labelledby="policy-terms-dialog-title"
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-4xl max-h-screen p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-4">
                <iframe
                  src="https://docs.google.com/document/d/e/2PACX-1vSGTKNKOScFDYLkt1D1hSkCmGbZ-b_RvpuBPDjwL5zXA0ooL4RuyURTMOa1vEC2w6EJDFhfxCIxj0Cf/pub?embedded=true"
                  className="w-full"
                  title="Policy Terms"
                  height="450"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
