export const modalityOptions = [
    { value: 'MR', label: 'Magnetic Resonance', checked: true },
    { value: 'CT', label: 'Computed Tomography', checked: true },
    { value: 'CR', label: 'Computed Radiography', checked: true },
    { value: 'DX', label: 'Digital X-Ray', checked: true },
    { value: 'DR', label: 'Digital Radiography', checked: true },
    { value: 'US', label: 'Ultrasound', checked: true },
    { value: 'MG', label: 'Mammography', checked: true },
    { value: 'XA', label: 'X-Ray Angiography', checked: true },
    { value: 'NM', label: 'Nuclear Medicine', checked: true },
    { value: 'PT', label: 'PET', checked: true }, 
    { value: "IVUS", label: "Intravascular Ultrasound", checked: true},
    { value: "DG", label: " Diaphanography", checked: true},
    { value: "DO", label: "DEXA", checked: true},
    { value: "RF", label: "Radio Fluoroscopy", checked: true},
    { value: "ECG", label: "Electrocardiography", checked: true},
    { value: "RG", label: "Radiographic imaging", checked: true},
    { value: "IO", label: "Intra-Oral Radiography", checked: true},
    { value: "OT", label: "Other", checked: true},
    { value: "AU", label: "Audio ECG", checked: true},
    { value: "KO", label: "Key Object Selection", checked: true},
];

export const modalityOptionsDefault = modalityOptions.reduce((result, option) => {
    return result.concat(option.value);
  }, [] as string[]);

export const modalityOptionsDefaultLength =  modalityOptionsDefault.length; 