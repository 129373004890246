import { create } from "zustand";

type StatsOption = { key: string | number; value: string | number };

type CohortsChartsData = {
  institution?: { [key: string]: StatsOption };
  manufacturer?: { [key: string]: StatsOption };
  parsed_patient_age?: { [key: string]: number | string };
  patient_sex?: { M?: number; F?: number; O?: number };
  body_part_examined?: { key: string | number; value: string | number };
  study_description?: { key: string | number; value: string | number };
  series_description?: { key: string | number; value: string | number };
};

interface ChartsState {
  cohortsChartsData: CohortsChartsData;
  setCohortsChartsData: (cohortsChartsData: CohortsChartsData) => void;
  statCheckedItems: { [value: string]: boolean };
  setStatCheckedItems: (checkedItems: { [value: string]: boolean }) => void;
}

export const useChartsStore = create<ChartsState>()((set) => ({
  cohortsChartsData: {},
  setCohortsChartsData: (cohortsChartsData: CohortsChartsData) => set({ cohortsChartsData }),
  statCheckedItems: {},
  setStatCheckedItems: (statCheckedItems) => set({ statCheckedItems })
}));