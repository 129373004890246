import AdvancedFilter from "./Filter/AdvancedFilter";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    AdvancedFilterComponent as AdvancedFilterComponentType,
} from '../../../../types/advancedFilters';
import AllOrAnySelectFilters from "./AllOrAny/AllOrAnySelectFilters";
import { useEffect } from "react";
import { useAdvancedFilterStore } from "../../../../store/advancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../../../store/patient2AdvancedFiltersStore";

import AddNewFilterButton from "./Filter/AddFilter";


type AdvancedFilterGroupsContainerProps = {
    id?: number;
}

export default function AdvancedFilterGroupsContainer({id}: AdvancedFilterGroupsContainerProps) {

    // AdvancedFilter state
    const groupsMatch = useAdvancedFilterStore(state => state.groupsMatch);
    const setGroupsMatch = useAdvancedFilterStore(state => state.setGroupsMatch);
    const removeFilterGroup = useAdvancedFilterStore(state => state.removeFilterGroup);
    const filterGroups = useAdvancedFilterStore(state => state.filterGroups);
    const removeFilter = useAdvancedFilterStore(state => state.removeFilter);
    const updateFilter = useAdvancedFilterStore(state => state.updateFilter);

    // Advanced filter 1 state
    const groupsMatch1 = usePatient1AdvancedFiltersStore(state => state.groupsMatch);
    const setGroupsMatch1 = usePatient1AdvancedFiltersStore(state => state.setGroupsMatch);
    const removeFilterGroup1 = usePatient1AdvancedFiltersStore(state => state.removeFilterGroup);
    const filterGroups1 = usePatient1AdvancedFiltersStore(state => state.filterGroups);
    const removeFilter1 = usePatient1AdvancedFiltersStore(state => state.removeFilter);
    const updateFilter1 = usePatient1AdvancedFiltersStore(state => state.updateFilter);

    // Advanced filter 2 state
    const groupsMatch2 = usePatient2AdvancedFiltersStore(state => state.groupsMatch);
    const setGroupsMatch2 = usePatient2AdvancedFiltersStore(state => state.setGroupsMatch);
    const removeFilterGroup2 = usePatient2AdvancedFiltersStore(state => state.removeFilterGroup);
    const filterGroups2 = usePatient2AdvancedFiltersStore(state => state.filterGroups);
    const removeFilter2 = usePatient2AdvancedFiltersStore(state => state.removeFilter);
    const updateFilter2 = usePatient2AdvancedFiltersStore(state => state.updateFilter);
    

    useEffect(() => {
        if ( id == null || id == undefined ){
            if(filterGroups.length > 1 && groupsMatch === 'NOOP') setGroupsMatch('AND');
            
            for(var i =0; i < filterGroups.length; i++) {
                const group = filterGroups[i];
                if (group.filters.length === 0 ) {
                    removeFilterGroup(group.group_id);
                }
            }
        }  else if (id == 0) {
            if(filterGroups1.length > 1 && groupsMatch1 === 'NOOP') setGroupsMatch1('AND');
            
            for(var i =0; i < filterGroups1.length; i++) {
                const group = filterGroups1[i];
                if (group.filters.length === 0 ) {
                    removeFilterGroup1(group.group_id);
                }
            }
        } else if (id == 1) {
           if(filterGroups2.length > 1 && groupsMatch2 === 'NOOP') setGroupsMatch2('AND');
            
            for(var i =0; i < filterGroups2.length; i++) {
                const group = filterGroups2[i];
                if (group.filters.length === 0 ) {
                    removeFilterGroup2(group.group_id);
                }
            }
        }

    }, [filterGroups, filterGroups1, filterGroups2])


    return (
            <ul className="space-y-3">
                {( 
                    (id == null || id == undefined) ? filterGroups : (
                        id == 0 ? filterGroups1 : filterGroups2
                    )
                ).map((group, index) => (
                    <>
                    <li key={group.group_id} className="rounded-md bg-white p-2 shadow flex flex-col space-y-2">
                        <div className="flex flex-row justify-between">
                            <p className="text-sm font-medium">Filter Group {index + 1}</p>
                            <button
                                type="button"
                                onClick={() => {
                                    (id == null || id == undefined) ? removeFilterGroup(group.group_id)  : (
                                        id == 0 ? removeFilterGroup1(group.group_id) : removeFilterGroup2(group.group_id)
                                    )
                                }}
                            >
                                <XMarkIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                            </button>
                        </div>
                        {group.filters && group.filters.length > 1 ? (
                            <div className="flex flex-row">
                                <p className='text-sm text-gray-700 mt-1 mr-2'>Match</p>
                                <AllOrAnySelectFilters id={id} key={index} groupId={group.group_id} />
                                <p className='text-sm text-gray-700 mt-1 ml-2'>filters in this group</p>
                            </div>
                        )
                        : null}
                        {
                            group.filters.map((filter: any, index: number) => {
                                return(
                                    <>
                                    <AdvancedFilter
                                        updateFilter={(updatedFilter: AdvancedFilterComponentType) => {
                                            (id == null || id == undefined) ? updateFilter(group.group_id, updatedFilter) : (
                                                id == 0 ? updateFilter1(group.group_id, updatedFilter) : updateFilter2(group.group_id, updatedFilter)
                                            )
                                        }} 
                                        filter={filter}
                                        key={filter.filter_id} 
                                        removeFilter={() => {
                                            (id == null || id == undefined) ? removeFilter(group.group_id, filter.filter_id) : (
                                                id == 0 ? removeFilter1(group.group_id, filter.filter_id) : removeFilter2(group.group_id, filter.filter_id)
                                            )
                                        }}
                                    />
                                    {index !== group.filters.length - 1 ? (
                                        <div className="relative my-4">
                                            {/* The Line */}
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-300"></div>
                                            </div>
                                            {/* The OR Text */}
                                            <div className="relative flex justify-start">
                                                <span className="px-2 bg-white text-gray-500">{group.logic_operator}</span>
                                            </div>
                                        </div>
                                    ) : null}
                                    </>
                                )
                            }
                        )}
                        

                        <div className="flex flex-row">
                            <AddNewFilterButton id={id} groupId={group.group_id}/>
                        </div>
                    </li>
                    {
                        (
                            (
                                (id == null || id == undefined) && (index !== filterGroups.length - 1)
                            ) ||
                            (id == 0 && (index !== filterGroups1.length - 1)) ||
                            (id == 1 && (index !== filterGroups2.length - 1))
                        ) ? (
                            <div className="relative my-4">
                                {/* The Line */}
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                {/* The OR Text */}
                                <div className="relative flex justify-start">
                                    <span className="px-2 bg-slate-100 text-gray-500">{
                                        (id == null || id == undefined) ? groupsMatch : (
                                            id == 0 ? groupsMatch1 : groupsMatch2
                                        )}</span>
                                </div>
                            </div>
                        ) : null
                    }
                    </>
                ))}
            </ul>
    );
}


