import { Listbox, Transition } from '@headlessui/react';
import { useState, Fragment, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';

type ValueInputGroupProps = {
  setFilterValue: (value: string) => void;
  filterValue: string | number | string[];
  filterType: string;
  defaultFilterValue: string | number | string[];
  id?: number;
  options?: any[]
}

export default function ValueInputGroup({
  setFilterValue,
  filterValue,
  filterType,
  defaultFilterValue,
  options
}: ValueInputGroupProps) {

    useEffect(() => {
      if (options && options.length) {
        setFilterValue(options[0].value);
      }
    }, [options]);

    return (
      <div>
        <label htmlFor="email" className="sr-only">
          Email
        </label>
        {
          (filterType === 'select' && options) ? <Listbox onChange={(e) => setFilterValue(e)} defaultValue={options[0]}>
      {({ open }) => (
        <>
          <div>
            <Listbox.Button className="relative w-4/9 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{options.filter((o: any) => {console.log(o.value === filterValue, o.value, filterValue);return o.value === filterValue}).length ? options.filter((o: any) => {return o.value === filterValue})[0].label : ""}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-1/2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option: any) => (
                  <Listbox.Option
                    key={option.label}
                    value={option.value}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                  >
                    {option.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox> : <input
            type={filterType === 'date' ? 'date' : (filterType === 'number' ? 'number' : 'text')}
            name="input"
            id="input"
            defaultValue={defaultFilterValue}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Enter value..."
            onChange={(e) => setFilterValue(e.target.value)}
          />
        }
      </div>
    )
  }