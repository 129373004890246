import { useEffect, useState, useRef } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useFilterStore } from '../../../store/filterStore'
import { usePatientStore } from '../../../store/patientStore'
import { useFilterDropDownStore } from '../../../store/filterDropDownStore'
import {crossDataFiltering} from '../../../utils/cross_filtering_in_dropdowns'
import { useSearchStore } from '../../../store/searchStore'
import { baseDropDowns, alldropdowns } from '../../filterControls/dropdownList'
import { urlSearchId } from "../../../utils/url_search_id";


type ManufacturerOptions = {
  value: string[];
  label: string;
  checked: boolean;
  count_of_field?: number; // Optional because it's not initially present
}

type ManufacturerFilterProps = {
    id?: number;
}

export default function ManufacturerFilter({id}: ManufacturerFilterProps) {
    const manufacturerSelections = useFilterStore(state => state.manufacturerSelections);
    const setManufacturerSelections = useFilterStore(state => state.setManufacturerSelections);
    const setPatientManufacturerSelections = usePatientStore(state => state.setManufacturerSelections);
    const clearButtonClicked = useFilterDropDownStore(state => state.clearButtonClicked);
    const [isOpen, setIsOpen] = useState(false);
    const patientFilters = usePatientStore(state => state.filters);
    const manufacturerDropdownOptions = [
      { value: ["Philips", "Volcano"], label: 'Philips', checked: true },
      { value: ["Siemens", "ACUSON", "Varian", "InfiMed"], label: 'Siemens', checked: true },
      { value: ["Hitachi"], label: 'Hitachi', checked: true },
      { value: ["HOLOGIC", "R2 Technology", "FAXITRON"], label: 'Hologic', checked: true },
      { value: ["GE", "G.E.", "OEC Medical Systems"], label: 'GE', checked: true },
      { value: ["FUJI"], label: 'Fuji', checked: true },
      { value: ["Carestream"], label: 'Carestream', checked: true },
      { value: ["Agfa"], label: 'Agfa', checked: true },
      { value: ["KODAK"], label: 'Kodak', checked: true },
      { value: ["TOSHIBA"], label: 'Toshiba', checked: true },
      { value: ["Pixeon"], label: 'Pixeon', checked: true },
      { value: ["Canon", "Vital"], label: 'Canon', checked: true },
      { value: ["Konica", "Konica Minolta"], label: 'Konica Minolta', checked: true },
      { value: ["ESAOTE"], label: 'Esaote', checked: true },
      { value: ["PNMS"], label: 'PNMS', checked: true },
      { value: ["DRTECH"], label: 'DRTECH', checked: true },
      { value: ["SOREDEX"], label: 'SOREDEX', checked: true },
      { value: ["Instrumentarium dental", "Instrumentarium"], label: 'Instrumentarium Dental', checked: true },
      { value: ["Imex"], label: 'Imex', checked: true },
      { value: ["iRay Technology Company Limited"], label: 'iRay', checked: true },
      { value: ["LOTUS"], label: 'Lotus', checked: true },
      { value: ["VIEWORKS"], label: 'VIEWORKS', checked: true },
      { value: ["Imaging Dynamics Company Ltd."], label: 'Imaging Dynamics Company Ltd.', checked: true },
      { value: ["IIS"], label: 'IIS', checked: true },
      { value: ["Oehm und Rehbein GmbH"], label: 'Oehm und Rehbein GmbH', checked: true },
      { value: ["Lonwin Medical"], label: 'Lonwin Medical', checked: true },
      { value: ["Marconi"], label: 'Marconi', checked: true },
      { value: ["Planmeca"], label: 'Planmeca', checked: true },
      { value: ["Dabi Atlante"], label: 'Dabi Atlante', checked: true },
      { value: ["DicomVCL"], label: 'DicomVCL', checked: true },
      { value: ["Sirona"], label: 'Sirona', checked: true },
      { value: ["iCRco"], label: 'iCRco', checked: true },
      { value: ["ONI"], label: 'ONI', checked: true },
      { value: ["ISCHEMAVIEW"], label: 'IschemaView', checked: true },
      { value: ["NMS"], label: 'NMS', checked: true },
      { value: ["Imaging Sciences International"], label: 'Imaging Sciences International', checked: true },
      { value: ["Vatech Company Limited"], label: 'VATECH', checked: true },
      { value: ["J.Morita.Mfg.Corp."], label: 'J.Morita.Mfg.Corp.', checked: true },
      { value: ["Neusoft Medical Systems", "NMS"], label: 'Neusoft Medical Systems', checked: true },
      { value: ["Ramsoft"], label: 'Ramsoft', checked: true },
      { value: ["Shimadzu"], label: 'Shimadzu', checked: true},
      { value: ["MXR"], label: 'MXR', checked: true},
      { value: ["Mediso"], label: 'Mediso', checked: true},
      { value: ["ADAC"], label: 'ADAC', checked: true},
      { value: ["Spectrum-Dynamics"], label: 'Spectrum-Dynamics', checked: true},
      { value: ["DDD"], label: 'DDD Diagnostics', checked: true},
      { value: ["Fischer"], label: 'Fischer Imaging', checked: true},
      { value: ["Bayer"], label: 'Bayer', checked: true},
      { value: ["Mevis"], label: 'Mevis', checked: true},
      { value: ["Bioptics"], label: 'Bioptics', checked: true},
      { value: ["LORAD"], label: 'LORAD', checked: true},
      { value: ["VMI"], label: 'VMI', checked: true},
      { value: ["Ziehm"], label: 'Ziehm', checked: true},
      { value: ["Samsung", "Neurologica", "SONOACE"], label: 'Samsung', checked: true},
      { value: ["Careview"], label: 'Careview', checked: true},
      { value: ["CMT"], label: 'CMT Medical', checked: true},
      { value: ["Blackford"], label: 'Blackford Analysis', checked: true},
      { value: ["Sonoscape"], label: 'Sonoscape', checked: true},
      { value: ["Sonosite"], label: 'Sonosite', checked: true},
      { value: ["Segami"], label: 'Segami', checked: true},
      { value: ["Orthoscan"], label: 'Orthoscan Inc.', checked: true},
      { value: ["BRACCO"], label: 'Bracco', checked: true},
      { value: ["RF System Lab"], label: 'Viewtech', checked: true},
      { value: ["Trophy"], label: 'Trophy', checked: true},
      { value: ["Osteosys"], label: 'Osteosys', checked: true},
      { value: ["Elscint"], label: 'Elscint', checked: true},
      { value: ["Sedecal"], label: 'Sedecal', checked: true},
      { value: ["Kretztechnik"], label: 'Kretztechnik', checked: true},
      { value: ["ATL"], label: 'ATL', checked: true},
      { value: ["Invivo"], label: 'Invivo', checked: true},
      { value: ["Capintec"], label: 'Captintec', checked: true},
      { value: ["Matakina"], label: 'Matakina', checked: true},
      { value: ["BK Medical"], label: 'BK Medical', checked: true},
      { value: ["Mobius", "Stryker"], label: 'Stryker', checked: true},
      { value: ["iCAD"], label: 'iCAD', checked: true},
      { value: ["Perceptive Software"], label: 'Perceptive Software', checked: true},
      { value: ["PaloDEx Group"], label: 'PaloDEx Group', checked: true},
      { value: ["Digirad"], label: 'Digirad', checked: true},
      { value: ["Orex"], label: 'OREX', checked: true},
      { value: ["Terarecon"], label: 'TERARECON', checked: true},
      { value: ["Aurora Imaging Technology"], label: 'Aurora Imaging Technology', checked: true},
      { value: ["WDM"], label: 'WDM', checked: true},
      { value: ["DEL MEDICAL"], label: 'DEL MEDICAL', checked: true},
      { value: ["Zonare"], label: 'Zonare', checked: true},
      { value: ["Fonar"], label: 'Fonar', checked: true},
      { value: ["Medlink"], label: 'Medlink Imaging', checked: true},
      { value: ["Varex"], label: 'Varex', checked: true},
      { value: ["iRay"], label: 'iRay', checked: true},
      { value: ["North American Imaging"], label: 'North American Imaging', checked: true},
      { value: [" NMD"], label: 'NMD', checked: true},
      { value: ["Lexmark"], label: 'Lexmark', checked: true},
      { value: ["BARD"], label: 'BARD', checked: true},
      { value: ["AS Software"], label: 'AS Software', checked: true},
      { value: ["E-COM"], label: 'E-COM', checked: true}
    ];
    const listData = useFilterDropDownStore(state => state.listAllData.find(filter=> filter.groupingField === "manufacturer")?.listData)??[];
    const filters = useFilterStore(state => state.filters);
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    const { crossFilterObjectFiltering } = crossDataFiltering();
    const crossFilterSelection = useFilterDropDownStore(
      (state) => state.crossFilterSelection
    );
    const setCrossFilterSelection = useFilterDropDownStore(
      (state) => state.setCrossFilterSelection
    );
    const setFilterDropdowns = useFilterDropDownStore(
      (state) => state.setFilterDropdowns
    );
    const tempCrossFilterData = crossFilterSelection;
    const defaultListOptions = manufacturerDropdownOptions.reduce((result, option) => {
      return result.concat(option.value);
    }, [] as string[]);
    const [ manufacturerOptionState, setManufacturerOptionState ] = useState<ManufacturerOptions[]>(manufacturerDropdownOptions);
    
    function usePrevious(value: any) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    };

    let prevOptions = usePrevious({manufacturerOptionState});

    const [selectionsCount, setSelectionsCount] = useState<number>(defaultListOptions.length);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [manuFacturerListWithCount, setManufacturerListWithCount] = useState<ManufacturerOptions[]>(manufacturerDropdownOptions);

    const isPatientFilterActive = id !== null && id !== undefined;
    const isManufacturerFullySelected = isPatientFilterActive
      ? patientFilters[id].filters.manufacturer.length ===
        defaultListOptions.length
      : manufacturerSelections.length === defaultListOptions.length;

    const isAnyManufacturerSelected = isPatientFilterActive
      ? patientFilters[id].filters.manufacturer.length > 0
      : manufacturerSelections.length > 0;

    const textClass = isManufacturerFullySelected
      ? "text-gray-400"
      : isAnyManufacturerSelected
      ? "text-gray-900"
      : "text-gray-400";
    
    useEffect(()=>{
      const updatedManufacturerFilterOptions = manufacturerOptionState.map(option => {
        // Sum count_of_field for matching grouping_field values
        const count = listData
          .filter((item:any) => option.value.includes(item.grouping_field))
          .reduce((acc, item:any) => acc + item.count_of_field, 0);
      
        return {
          ...option,
          count_of_field: count, // Add the total count to the option
        };
      }).sort((a,b)=>b.count_of_field - a.count_of_field);
      setManufacturerListWithCount(updatedManufacturerFilterOptions);
    },[listData,manufacturerOptionState])
    useEffect(() => {
      setManufacturerOptionState(manufacturerDropdownOptions)
  }, [clearButtonClicked]);

    useEffect(() => {
      function handleMouseDown(event: MouseEvent) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleMouseDown);

      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      }
    })

    useEffect(() => {
        // Count the number of checked === true in the manufacturerOptionState array
        const count = manuFacturerListWithCount.filter((option) => option.checked).length;
        setSelectionsCount(count);

        id != null ? setPatientManufacturerSelections(manufacturerOptionState.filter((option) => option.checked).map((option) => option.value).flat(), id) : setManufacturerSelections(manufacturerOptionState.filter((option) => option.checked).map((option) => option.value).flat());
      }, [manufacturerOptionState])

    useEffect(() => {
      let newManufacturerOptionState = [...manufacturerOptionState];
      newManufacturerOptionState = newManufacturerOptionState.map((option) => {
        option.checked = manufacturerSelections.includes(option.value[0]);
        return option
      });
      setSelectionsCount(newManufacturerOptionState.filter((option) => { return option.checked }).length)
      if (JSON.stringify(newManufacturerOptionState) === JSON.stringify(manufacturerOptionState)) {
        return;
      }
      setManufacturerOptionState(newManufacturerOptionState);
    }, [manufacturerSelections])

    function toggleOption(option: ManufacturerOptions) {
      const newManufacturerOptionState = [...manufacturerOptionState];
      const index = newManufacturerOptionState.findIndex((el) => el.value[0] === option.value[0]);
      newManufacturerOptionState[index].checked = newManufacturerOptionState[index].checked ? false : true;
      setManufacturerOptionState(newManufacturerOptionState);
    }
  


    const handleSelectAll = () => {
      const newManufacturerOptionState = [...manufacturerOptionState];
      newManufacturerOptionState.forEach((option) => option.checked = true);
      setManufacturerOptionState(newManufacturerOptionState);
    }

    const handleUnselectAll = () => {
      const newManufacturerOptionState = [...manufacturerOptionState];
      newManufacturerOptionState.forEach((option) => option.checked = false);
      setManufacturerOptionState(newManufacturerOptionState);
    }


    let checkSubset = (subsetArray: string[]) => {
      if (id != null) {
        return subsetArray.every((el) => {
          return patientFilters[id].filters.manufacturer.includes(el);
        });
      }
    };

    useEffect(() => {
      const selectedValues = manufacturerOptionState
        .filter((option) => option.checked)
        .map((option) => option.value)
        .flat();
      if (selectedValues.length > 0) {
        setFilterDropdowns(
          alldropdowns.filter((value) => value !== "manufacturer")
        );
        tempCrossFilterData["manufacturer"] = selectedValues;
        setCrossFilterSelection(tempCrossFilterData);
      } else {
        if (baseDropDowns.includes("manufacturer")) {
          const updatedCrossFilter = crossFilterObjectFiltering("manufacturer");
          setFilterDropdowns(
            alldropdowns.filter((value) => value !== "manufacturer")
          );
          setCrossFilterSelection(updatedCrossFilter);
        }
      }
    }, [manufacturerOptionState]);

  return (
    <div className='flex flex-row justify-between py-4'>
        <div className='w-full' ref={dropdownRef}>
        <Listbox
          as="div"
          className="space-y-1  w-full"
          disabled={filters.cross_filter  ? isRetrievingStudies : false}
        >
          {() => (
        
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    className="text-sm cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:ring-inset focus:border focus:ring-blue-600 focus:ring-1 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className= {`block truncate ${textClass}`}>
                      {/* Multi-select here */}
                      {(id != null && id != undefined) ?  
                          (patientFilters[id].filters.manufacturer.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length === defaultListOptions.length  ? 
                              "Select Manufacturer" : (
                                patientFilters[id].filters.manufacturer.filter((item: string)=> item!== "null" && item !== "UNKNOWN").length > 0 ? patientFilters[id].filters.manufacturer.filter((item: string)=> item!== "null" && item !== "UNKNOWN").join(", ") : "Select manufacturers"
                              )
                          ) : 
                          (
                            manufacturerSelections.length === defaultListOptions.length  ? 
                              "Select Manufacturer" : (
                                manufacturerSelections.length > 0 ? manufacturerSelections.join(", ") : "Select Manufacturer"
                              )
                        )}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                >
                  <Listbox.Options
                    static
                    className="text-base max-h-60 rounded-md py-1 leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                  >
                    <Listbox.Option value='all'>
                    {({ active }) => (
                        <div
                            className={`${
                                active
                                    ? "text-white bg-blue-600"
                                    : "text-gray-900"
                            } cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-4 flex flex-row items-center justify-start`}
                            onClick={() =>
                              selectionsCount === manuFacturerListWithCount.length
                                    ? handleUnselectAll()
                                    : handleSelectAll()
                            }
                        >
                            <input
                                type="checkbox"
                                checked={selectionsCount === manuFacturerListWithCount.length}
                                className="h-4 w-4 rounded-sm mr-2"
                                readOnly
                            />
                            <span className="block truncate">
                                {selectionsCount === manuFacturerListWithCount.length ? 'Unselect All' : 'Select All'}
                            </span>
                        </div>
                    )}
                </Listbox.Option>
                    {manuFacturerListWithCount.map((manufacturer) => {
                      return (
                        <Listbox.Option key={manufacturer.value[0]} value={manufacturer.value}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white bg-blue-600"
                                  : "text-gray-900"
                              } cursor-default hover:cursor-pointer select-none relative py-2 pl-2 pr-4 flex flex-row items-center justify-start`}
                              onClick={() => toggleOption(manufacturer)}
                            >
                                <input
                                    type="checkbox"
                                    checked={manufacturer.checked ||((urlSearchId !== "" &&  urlSearchId !== undefined ) && checkSubset(manufacturer.value))}
                                    className="h-4 w-4 rounded-sm mr-2"
                                    readOnly
                                />
                                <div
                                      className="flex flex-row w-full justify-between items-center"
                                      style={{
                                        width: "280px",
                                        maxWidth: "280px",
                                        minWidth: "280px",
                                      }}
                                    >
                                <span
                                    title={manufacturer.label}
                                    className={`${
                                      manufacturer.checked ? "font-semibold" : "font-normal"
                                    } block truncate`}
                                >
                                    {manufacturer.label}
                                </span>
                                <span
                                      className="text-right ml-3 text-sm"
                                    >
                                      {manufacturer?.count_of_field && manufacturer?.count_of_field > 1000 ? (
                                        !isNaN(manufacturer?.count_of_field) &&
                                        (manufacturer?.count_of_field / 1000).toFixed(
                                          1
                                        ) + "k"
                                      ) : (
                                        manufacturer?.count_of_field
                                      )}
                                    </span>
                                  </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
          )}
        </Listbox>
        </div>
    </div>
  );
}
