import { useRef, useEffect } from "react";
import { VideoCameraIcon } from "@heroicons/react/24/outline";

interface VideoComponentProps {
    video: string;
}

export default function VideoComponent({
    video,
}: VideoComponentProps) {

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.defaultMuted = true;
            videoRef.current.muted = true;
        }

        return () => {
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }
    }, [videoRef])


    const handleMouseOver = async () => {
        if(videoRef.current) {
            videoRef.current.playbackRate = 3;
            // Prevents play() being called while a pause() is being executed
            var isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended &&
                videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
            
            console.log(isPlaying, 
                videoRef.current, videoRef.current.paused, 
                videoRef.current.ended, videoRef.current.readyState, 
                videoRef.current.HAVE_CURRENT_DATA)
            if (videoRef.current && !isPlaying) {
                try {
                    await videoRef.current.play();
                } catch (e) {
                    console.log('play failed: ', e)
                }
            }
        }
    }

    const handleMouseOut = () => {
        if (videoRef.current) {
            var isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended && 
                videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
            if(videoRef.current && isPlaying) {
                videoRef.current.pause();
            }
        }
    }

    return (
        <div className="relative"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        key={video}
        >
            <video
                key={video}
                ref={videoRef}
                width="100"
                height="100"
                loop
                muted
                playsInline
            >
                    <source src={video + "#t=0.001"} type="video/mp4"/>
            </video>
            <div className="absolute bottom-0 right-0 w-full h-full">
                <VideoCameraIcon  className="h-5 w-5 text-gray-300"/>
            </div>
        </div>
    )
}