import { useFilterDropDownStore } from "../store/filterDropDownStore";

export function crossDataFiltering() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const crossFilterSelection = useFilterDropDownStore(
    (state) => state.crossFilterSelection
  );
  const crossFilterObjectFiltering = (val: any) => {
    const filteredObject = Object.fromEntries(
      Object.entries(crossFilterSelection).filter(([key]) => key !== val)
    );
    return filteredObject;
  };
  return { crossFilterObjectFiltering };
}
