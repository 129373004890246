import { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useModalStore } from '../../store/modalStore';
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ExpandedThumbnailModal() {

 // Modal State
    const showExpandedThumbnailModal = useModalStore(state => state.showExpandedThumbnailModal);
    const setShowExpandedThumbnailModal = useModalStore(state => state.setShowExpandedThumbnailModal);
    const showPatientStudiesModal = useModalStore(state => state.showPatientStudiesModal);
    const setShowPatientStudiesModal = useModalStore(state => state.setShowPatientStudiesModal);
    const videoSource = useModalStore(state => state.expandedThumbnailModalSource);
    const fileType = useModalStore(state => state.expandedThumbnailFiletype);

    const videoRef = useRef<HTMLVideoElement>(null);
    const cancelButtonRef = useRef(null)
    const modalRef = useRef<any>(null);

    const handleClose = () => {
        setShowExpandedThumbnailModal(false);
        if (showPatientStudiesModal) {
            return;
        }
        if (!showExpandedThumbnailModal && showPatientStudiesModal) {
            setShowPatientStudiesModal(false);
        }
    };

    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleClose();
            }
        }
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        }
    }, [modalRef])

    return (
        <Transition.Root show={showExpandedThumbnailModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>null} onClick={()=>null}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel  ref={modalRef} className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg pt-[0px]" id="study-model">
                <div className="w-[490px] flex justify-end pt-[3px] pb-[2px]">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                    {
                        fileType === 'video' ? (
                        <video
                            ref={videoRef}
                            width="500"
                            height="500"
                            muted
                            controls
                            autoPlay
                            playsInline
                                    >
                                    <source src={videoSource} type="video/mp4"/>
                                </video>
                        )
                        :
                        <img src={videoSource} className="w-full h-full" alt="Expanded Thumbnail" />
                    }     
                        
                            
                </Dialog.Panel>                
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
