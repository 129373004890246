/* eslint-disable react-hooks/rules-of-hooks */

import { useAdvancedFilterStore } from "../store/advancedFiltersStore";
import { useFilterDropDownStore } from "../store/filterDropDownStore";
import { useFilterStore } from "../store/filterStore";
import {getStudyCountByGroupingField} from "./request_studies";

export function fetchAllStudyByGroup(){
const setAllListData = useFilterDropDownStore(state => state.setAllListData);
const setAllData = useFilterDropDownStore(state => state.setAllData);
const setAllFilterData = useFilterDropDownStore(state => state.setAllFilterData);
const startIndex =  useFilterDropDownStore(state => state.startIndex);
const endIndex =  useFilterDropDownStore(state => state.endIndex);
const setAllDropdownListData = useAdvancedFilterStore(state => state.setAllDropdownListData);
const filters = useFilterStore(state => state.filters);

const getDropdownListData = async (groupingField: any,token: any,cross_filtering_parameter: any, crossFlag : boolean, searchRequest:any, default_list: any, cancelToken: any) => {

  if(!filters.cross_filter){
    searchRequest.search_query=""; 
  }

  if(token){
    try{
      const numberOfStudiesByGroup = await getStudyCountByGroupingField(groupingField,token, cross_filtering_parameter, crossFlag , searchRequest, default_list, cancelToken);

      if(groupingField === "modality" || groupingField === "manufacturer" ){
        setAllListData(groupingField,numberOfStudiesByGroup.results?.slice(startIndex,endIndex))
        setAllData(groupingField,numberOfStudiesByGroup.results);
        setAllFilterData(groupingField,numberOfStudiesByGroup.results);
      }else{
        setAllDropdownListData(groupingField,numberOfStudiesByGroup.results)
      }
    }catch(error){
      console.log(error)
    }
}
};
return { getDropdownListData };
}