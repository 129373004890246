import { create } from "zustand";

interface SortObject {
  sort_field: string;
  sort_method: string;
}

interface SortStore {
  studySortArray: SortObject[];
  updateStudySortArray: (newSort: SortObject) => void;
  newStudySortArray: (newSort: SortObject[]) => void;
  patientSortArray: SortObject[];
  updatePatientSortArray: (newSort: SortObject) => void;
  newPatientSortArray: (newSort: SortObject[]) => void;
  sortClearButtonClicked: boolean;
  setSortClearButtonClicked: (sortClearButtonClicked: boolean)=>void;
}

export const useSortStore = create<SortStore>((set) => ({
  studySortArray: [  
    {
      sort_field: "positive_entities",
      sort_method: "ASC" 
    }
  ],
  updateStudySortArray: (newSort: SortObject) => {
    set((state) => {
      const prevIndex = state.studySortArray.findIndex(
        (sortObject: SortObject) => sortObject.sort_field === newSort.sort_field
      );
      if (prevIndex !== -1) {
        if (state.studySortArray[prevIndex].sort_method === newSort.sort_method) {
          return {
            ...state,
            studySortArray: state.studySortArray.filter(
              (sortObject: SortObject) => sortObject.sort_field !== newSort.sort_field
            ),
          };
        }
        return {
          ...state,
          studySortArray: state.studySortArray.map((sortObject: SortObject) => {
            if (sortObject.sort_field === newSort.sort_field) {
              return {
                sort_field: newSort.sort_field,
                sort_method: newSort.sort_method,
              };
            }
            return sortObject;
          })
        };
      } else {
        return {
          ...state,
          studySortArray: [
            ...state.studySortArray,
            {
              sort_field: newSort.sort_field,
              sort_method: newSort.sort_method
            }
          ]
        };
      }
    });
  },
  newStudySortArray: (newSort: SortObject[]) => {
    set((state) => {
      return {
        ...state,
        studySortArray: newSort
      };
    });
  },
  patientSortArray: [  
    {
      sort_field: "positive_entities",
      sort_method: "ASC" 
    }
  ],
  updatePatientSortArray: (newSort: SortObject) => {
    set((state) => {
      const prevIndex = state.patientSortArray.findIndex(
        (sortObject: SortObject) => sortObject.sort_field === newSort.sort_field
      );
      if (prevIndex !== -1) {
        if (state.patientSortArray[prevIndex].sort_method === newSort.sort_method) {
          return {
            ...state,
            patientSortArray: state.patientSortArray.filter(
              (sortObject: SortObject) => sortObject.sort_field !== newSort.sort_field
            ),
          };
        }
        return {
          ...state,
          patientSortArray: state.patientSortArray.map((sortObject: SortObject) => {
            if (sortObject.sort_field === newSort.sort_field) {
              return {
                sort_field: newSort.sort_field,
                sort_method: newSort.sort_method,
              };
            }
            return sortObject;
          })
        };
      } else {
        return {
          ...state,
          patientSortArray: [
            ...state.patientSortArray,
            {
              sort_field: newSort.sort_field,
              sort_method: newSort.sort_method
            }
          ]
        };
      }
    });
  },
  newPatientSortArray: (newSort: SortObject[]) => {
    set((state) => {
      return {
        ...state,
        patientSortArray: newSort
      };
    });
  },
  sortClearButtonClicked: false,
  setSortClearButtonClicked: (sortClearButtonClicked:boolean)=> set({sortClearButtonClicked}),
}));
