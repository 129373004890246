import React from "react";
import PieChart from "./common/PieChart";
import { useChartsStore } from "../../../../../store/chartsStore";
import { addOtherValueToChartData } from "../../../../../utils/data_formatting";
export default function StudyDescriptionChart() {
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { study_description: studyDescriptionData } = cohortsChartsData;

  const sortedEntries = Object.entries(studyDescriptionData || {})
  .sort(([, valueA], [, valueB]) => Number(valueB) - Number(valueA)); 
  
  const otherIndex = sortedEntries.findIndex(([key]) => key === "Other");

  if (otherIndex !== -1 && otherIndex < 10) {
    sortedEntries.splice(otherIndex, 1);
  }

  const filteredStudyDescriptionData = sortedEntries
    .slice(0, 10)
    .reduce((obj, [key, value]) => {
      obj[key] = value as number;
      return obj;
    }, {} as { [key: string]: number });

    addOtherValueToChartData(sortedEntries as [string, number][], filteredStudyDescriptionData);

  return (
    <PieChart
      data={filteredStudyDescriptionData}
      colors={["#A78BFA", "#F59E0B", "#E11D48", "#84CC16", "#EC4899", "#22D3EE", "#F472B6", "#6366F1", "#10B981", "#F97316","#0000FF"]}
      chartId="study-description-pie-chart"
    />
  );
}
