import React from "react";
import PieChart from "./common/PieChart";
import { useChartsStore } from "../../../../../store/chartsStore";
import { addOtherValueToChartData } from "../../../../../utils/data_formatting";

export default function ManufacturerPieChart() {
  // Charts Store
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { manufacturer: manufacturerDescriptionData } = cohortsChartsData;

  const sortedEntries = Object.entries(manufacturerDescriptionData || {}).sort(([, valueA], [, valueB]) => {
    const numA = typeof valueA === 'number' ? valueA : 0;
    const numB = typeof valueB === 'number' ? valueB : 0;
    return numB - numA;
  });
  const otherIndex = sortedEntries.findIndex(([key]) => key === "Other");
  if (otherIndex !== -1 && otherIndex < 10) {
    sortedEntries.splice(otherIndex, 1);
  }

  const filteredManufacturerDescriptionData = sortedEntries.slice(0, 10).reduce((obj, [key, value]) => {
    obj[key] = typeof value === 'number' ? value : 0;
    return obj;
  }, {} as { [key: string]: number });

  addOtherValueToChartData(sortedEntries.map(([key, value]) => [key, typeof value === 'number' ? value : 0] as [string, number]), filteredManufacturerDescriptionData);

  return (
    <PieChart
      data={filteredManufacturerDescriptionData}
      colors={["#60A5FA", "#3B82F6", "#14B8A6", "#34D399", "#84CC16", "#A78BFA", "#8B5CF6", "#e6194b", "#3cb44b", "#ffe119", "#0000FF"]}
      chartId="manufacturer-pie-chart"
    />
  );
}
