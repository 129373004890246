import InviteUserPanel from "./InviteUser";
import InvitationsTable from "./InvitationsAndMembersTable";

export default function OrgSettings() {

    return (
        <div className="px-8 flex flex-col mt-8">
            <h1 className="text-2xl font-semibold">
                Organizational Settings
            </h1>
            <div className="flex items-center">
                <InviteUserPanel />
            </div>
            <InvitationsTable />
        </div>
    )
}