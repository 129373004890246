import { useState, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";

interface CustomSelectProps {
  options: number[];
  currentPage: number;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function CustomSelect({ options, currentPage, handleChange }: CustomSelectProps) {
  const [selectedOption, setSelectedOption] = useState(currentPage);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOption(currentPage);
  }, [currentPage]);

  const loadMoreOptions = () => {
    if (endIndex < options.length) {
      setEndIndex((prev) => Math.min(prev + 10, options.length));
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setIsAtBottom(window.innerHeight - rect.bottom < 150);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  });

  const handleDropdownChange = (value: number) => {
    setSelectedOption(value);
    handleChange({ target: { value: value.toString() } } as React.ChangeEvent<HTMLSelectElement>);
  };

  const handleDropDownScroll = (event: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const threshold = scrollHeight - clientHeight - 20;
    // if we've scrolled to near the bottom of option
    if (scrollTop >= threshold) {
      loadMoreOptions();
    }
    
    if (scrollTop === 0 && startIndex > 0) {
      setStartIndex((prev) => Math.max(prev - 10, 0));
      setEndIndex((prev) => Math.max(prev - 10, 10));
    }
  };

  return (
    <div className="relative">
      <Listbox value={selectedOption} onChange={handleDropdownChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                ref={buttonRef}
                className={`w-auto h-8 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-200 text-sm px-4 flex justify-center items-center ${options.length === 0 && "cursor-not-allowed" }`}>
                {selectedOption}
                <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
              </Listbox.Button>
            </div>
            {options.length !== 0 &&
            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={`absolute mt-1 w-full rounded-md bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                isAtBottom ? "bottom-full mb-1" : "top-full mt-1"
              }`}>
              <Listbox.Options
                ref={dropdownRef}
                onScroll={handleDropDownScroll}
                static
                className="max-h-36 no-scrollbar rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                {options?.slice(startIndex, endIndex)?.map((option, index) => (
                  <Listbox.Option key={index} value={option}>
                    {({ active, selected }) => (
                      <li
                        className={`cursor-pointer select-none relative py-1 px-2 pl-6 text-sm ${
                          active
                            ? "  hover:bg-gray-400 hover:text-black dark:hover:bg-gray-400 bg-gray-400 text-white"
                            : "text-gray-200 bg-gray-800"
                        } ${
                          selected ? "dark:bg-gray-400 font-medium  hover:bg-gray-100 hover:text-black dark:hover:bg-gray-300 text-white" : ""
                        }`}>
                        {option}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            }
          </>
        )}
      </Listbox>
    </div>
  );
}

export default CustomSelect;
