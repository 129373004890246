import CustomSelect from "./CustomSelect";

interface ResultsPerPageProps {
  handleSelectPageSize: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedPageSize: number;
}

export default function ResultsPerPage({
  handleSelectPageSize,
  selectedPageSize,
}: Readonly<ResultsPerPageProps>) {
  const options = [10, 25, 50];

  return (
    <div className="flex flex-row gap-2 items-center border-r-2 border-gray-300 pr-3">
      <div className="inline-flex items-center	text-gray-600 text-sm">
        Results per page
      </div>
      <CustomSelect
        options={options}
        currentPage={selectedPageSize}
        handleChange={handleSelectPageSize}
      />
    </div>
  );
}