import { useEffect, useRef, useState } from "react";
import CustomModal from "../modals/CustomModal";
import CreateNewSearch from "../modals/modalContents/CreateNewSearch";
import { initialSearchRequest, useSearchStore } from "../../store/searchStore";
import { InitialPatientFilter, usePatientStore } from "../../store/patientStore";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { useAdvancedFilterStore } from "../../store/advancedFiltersStore";
import { AdvancedFilterSearchRequest } from "../../types/advancedFilters";
import { useSavedSearchStore } from "../../store/savedSeachStore";
import { usePatient2AdvancedFiltersStore } from "../../store/patient2AdvancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../store/patient1AdvancedFiltersStore";
import { manufacturerSelectionsDefault } from "./manufacturerFilter/manufacturerOptions";

export default function SaveSearchButton() {
    //local state
    const [showNewSearchModal, setShowNewSearchModal] = useState(false);
    const isSearchChanged = useSavedSearchStore(state => state.isSearchChanged);
    const setIsSearchChanged = useSavedSearchStore(state => state.setIsSearchChanged);

    const searchType = useSearchTypeStore((state) => state.searchType);
    const searchRequest = useSearchStore((state) => state.searchRequest);
    const patientFilters = usePatientStore((state) => state.filters);
    const advancedFilters = useAdvancedFilterStore(state => state.advancedFilters);

    const advancedFilters1 = usePatient1AdvancedFiltersStore(state => state.advancedFilters);
    const advancedFilters2 = usePatient2AdvancedFiltersStore(state => state.advancedFilters);

    const prevAdvancedFiltersRef = useRef<AdvancedFilterSearchRequest | null>(advancedFilters);    
    const prevAdvancedFilters1Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters1);
    const prevAdvancedFilters2Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters2);

    useEffect(() => {
        let studiesSearchRequestChanged = false;
        let patientFiltersChanged = false;
    
        if (searchType === "study") {
    
        const initialSearch = {
            ...initialSearchRequest,
            sort: [{ sort_field: "positive_entities", sort_method: "ASC" }]
        };
        const hasSearchRequestChanged = (
            JSON.stringify(initialSearch) !== JSON.stringify(searchRequest) ||
            JSON.stringify(prevAdvancedFiltersRef.current) !== JSON.stringify(advancedFilters)
        );
            
        studiesSearchRequestChanged = hasSearchRequestChanged;
        setIsSearchChanged(studiesSearchRequestChanged)
        } else {
        const initialPatient = InitialPatientFilter?.filters?.map((value) => {
            const { search_summary, search_query, ...restInitialPatientFilter } = value;
            return {
            ...restInitialPatientFilter,
            filters: {
                ...value.filters,
                manufacturer: manufacturerSelectionsDefault
            }
            };
        });
    
        const hasPatientFiltersChanged = (
            JSON.stringify(Object.keys(initialPatient)) !== JSON.stringify(Object.keys(patientFilters)) ||
            JSON.stringify(initialPatient) !== JSON.stringify(patientFilters) ||
            JSON.stringify(prevAdvancedFilters1Ref.current) !== JSON.stringify(advancedFilters1) ||
            JSON.stringify(prevAdvancedFilters2Ref.current) !== JSON.stringify(advancedFilters2)           
        )
        patientFiltersChanged = hasPatientFiltersChanged;
    
        setIsSearchChanged(patientFiltersChanged);
        }
    }, [searchRequest, patientFilters, searchType, advancedFilters, advancedFilters1, advancedFilters2]);
    const handleClick = () => {
        setShowNewSearchModal(true);
    };

    return (
        <>
            <div className="flex justify-center pt-3">
                <button
                onClick={handleClick}
                type="button"
                disabled={!isSearchChanged}
                className={`w-10/12 rounded ${isSearchChanged ? 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600' : 'bg-gray-500'} px-4 py-1 text-sm font-semibold text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed `}>
                Save Search
                </button>
            </div>
            {showNewSearchModal && <CustomModal showCustomModal={showNewSearchModal} setCustomModal={setShowNewSearchModal} >
                <CreateNewSearch setShowNewSearchModal={setShowNewSearchModal} />
            </CustomModal>}
        </>
    );
}
