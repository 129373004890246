import { useState, useEffect } from "react";
import { useFilterStore } from "../../../store/filterStore";
import { usePatientStore } from '../../../store/patientStore'
import { useSearchTypeStore } from "../../../store/searchTypeStore";

const sexOptions = [
    { value: 'M', label: 'Male', checked: true },
    { value: 'F', label: "Female", checked: true },
    { value: 'O', label: "Other", checked: true },
]


export default function SexFilter() {
    const sexSelections = useFilterStore(state => state.sexSelections);
    const setSexSelections = useFilterStore(state => state.setSexSelections);
    const setPatientSexSelections = usePatientStore(state => state.setSexSelections);

    const [ sexOptionState, setSexOptionState ] = useState(sexOptions);
    const searchType = useSearchTypeStore(state => state.searchType);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        // Update sex option state to reflect the change
        const newSexOptionState = [...sexOptionState];
        newSexOptionState[index].checked = newSexOptionState[index].checked ? false : true;
        setSexOptionState(newSexOptionState);
        const selected = sexOptionState
        .filter((item: any) => item.value !== value && item.checked)
        .map((item: any) => item.value);

        if(e.target.checked) {
            searchType === "patient" ? setPatientSexSelections([...selected, value]) : setSexSelections([...selected, value])
        } else {
            searchType === "patient" ? setPatientSexSelections(selected) : setSexSelections(selected)
        }
    }

    useEffect(() => {
        setSexOptionState(sexOptions.map((sexOption) => {
            sexOption.checked = sexSelections.includes(sexOption.value);
            return sexOption;
        }));
    }, [sexSelections])

    return (
        <div className="flex flex-row justify-between py-4">
            <p className="text-base">Sex</p>
            <div className="flex flex-row gap-x-4">
                {sexOptionState.map((option, index) => (
                    <div key={index} className="flex flex-row">
                        <input
                            className="mr-2 mt-[3px]"
                            type="checkbox"
                            value={option.value}
                            checked={option.checked}
                            onChange={(e) => handleCheckboxChange(e, index)}
                        />
                        <label>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}