import { useChartsStore } from "../../../store/chartsStore";
import { chartsData, ChartTitles, getChartComponent } from "./charts/ChartOptions";

export default function OrderCharts() {
  // Charts Store
  const statCheckedItems = useChartsStore((state) => state.statCheckedItems);
  return (
    <div className="rounded-lg bg-white shadow border py-4">
      {/* <h3 className="text-lg font-medium text-gray-800 ml-8">
          Project Statistics
        </h3> */}
      <div className="overflow-x-auto">
        <div className={`grid grid-flow-col ${chartsData?.filter((chart) => statCheckedItems[chart.title]).length < 5 ? 'auto-cols-max' : 'auto-cols-[minmax(250px,_1fr)]'}`}>
          {chartsData?.filter((chart) => statCheckedItems[chart.title])?.map((chart, index) => (
              <div className="flex flex-col" key={index}>
                <p className="text-sm text-center font-medium">{chart.title} Distribution</p>
                {getChartComponent(chart.title as ChartTitles)}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}