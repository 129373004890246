import { useState, useEffect } from "react";
import { useFilterStore } from "../../../store/filterStore";
import { usePatientStore } from '../../../store/patientStore'

const timeBetweenOptions = [
    { value: [-1000000, 1000000], label: 'Whenever'},
    { value: [-1, 1], label: 'Within 1 day'},
    { value: [-4, 4], label: 'Within 4 days'},
    { value: [-30, 30], label: 'Within 30 days'},
    { value: [-60, 60], label: 'Within 60 days'},
    { value: [-90, 90], label: "Within 90 days"},
    { value: [-180, 180], label: "Within 180 days"},
    { value: [-270, 270], label: "Within 270 days"},
    { value: [-365, 365], label: "Within 1 year"},
    { value: [-1095, 1095], label: "Within 3 years"},
    { value: [-1825, 1825], label: "Within 5 years"},
    { value: [0, 1], label: "1 day later"},
    { value: [0, 4], label: "4 days later"},
    { value: [0, 30], label: "30 days later"},
    { value: [0, 60], label: "60 days later"},
    { value: [0, 90], label: "90 days later"},
    { value: [0, 180], label: "180 days later"},
    { value: [0, 270], label: "270 days later"},
    { value: [0, 365], label: "1 year later"},
    { value: [0, 1095], label: "3 years later"},
    { value: [0, 1825], label: "5 years later"},
    { value: [1, 1000000], label: "More than 1 day later"},
    { value: [30, 1000000], label: "More than 30 days later"},
    { value: [60, 1000000], label: "More than 60 days later"},
    { value: [90, 1000000], label: "More than 90 days later"},
    { value: [180, 1000000], label: "More than 180 days later"},
    { value: [270, 1000000], label: "More than 270 days later"},
    { value: [365, 1000000], label: "More than 1 year later"},
    { value: [1095, 1000000], label: "More than 3 year later"},
    { value: [1825, 1000000], label: "More than 5 years later"},
]

export default function TimeBetweenFilter() {

    const setTimeBetween = usePatientStore(state => state.setTimeBetween);
    const timeBetween = usePatientStore(state => state.timeBetween);

    const onSelect = (event: any) => {
        setTimeBetween(JSON.parse("[" + event.target.value + "]"));
    };

    return (
        <div className="flex flex-row justify-between rounded-md">
            <p className="mt-[7px] text-base">Within</p>
            <select id="timeBetween" className="rounded border border-gray-300 h-9 sm:text-sm sm:leading-5 block truncate text-sm focus:border-blue-100 focus:border-1 " onChange={onSelect}>
              {timeBetweenOptions.map((option: any, idx: number) => {
                    const isSelected = JSON.stringify(option.value) === JSON.stringify(timeBetween);
                return <option className="text-sm" value={option.value} selected={isSelected}>{option.label}</option>
              })}
            </select>
        </div>
    )
}