import { create } from "zustand";
import { AdvancedFilterGroupComponent, AdvancedFilterComponent, AdvancedFilterSearchRequest } from "../types/advancedFilters";
import { filterOptions } from "../components/filterControls/advancedFilters/FilterGroup/Filter/FilterBy/FilterByOptions";


interface AdvancedFilterStore {
    filterGroups: AdvancedFilterGroupComponent[];
    setFilterGroups: (filterGroups: AdvancedFilterGroupComponent[]) => void;
    groupsMatch: 'AND' | 'OR' | 'NOOP';
    setGroupsMatch: (groupsMatch: 'AND' | 'OR' | 'NOOP') => void;
    advancedFiltersEnabled: boolean;
    advancedFilters: AdvancedFilterSearchRequest | null;
    setAdvancedFilters: (advancedFilters: AdvancedFilterSearchRequest | null) => void;
    setAdvancedFiltersEnabled: (advancedFiltersEnabled: boolean) => void;
    removeFilterGroup: (groupId: number) => void;
    addFilterGroup: () => void;
    updateLogicOperatorForGroup: (groupId: number, logicOperator: 'AND' | 'OR') => void;
    addFilter: (groupId: number) => void;
    removeFilter: (groupId: number, filterId: number) => void;
    updateFilter: (groupId: number, updatedFiler: AdvancedFilterComponent) => void;
}

export const usePatient1AdvancedFiltersStore = create<AdvancedFilterStore>()((set) => ({
    filterGroups: [],
    setFilterGroups: (filterGroups: AdvancedFilterGroupComponent[]) => set({ filterGroups }),
    


    advancedFiltersEnabled: false,
    setAdvancedFiltersEnabled: (advancedFiltersEnabled: boolean) => {console.log('patient1', advancedFiltersEnabled); set({ advancedFiltersEnabled })},
    advancedFilters: null,
    setAdvancedFilters: (advancedFilters: AdvancedFilterSearchRequest | null) => set({ advancedFilters }),
    groupsMatch: 'AND',
    setGroupsMatch: (groupsMatch: 'AND' | 'OR' | 'NOOP') => set({ groupsMatch }),

    removeFilterGroup: (groupId: number) => set(state => {
        const filterGroups = state.filterGroups.filter(filterGroup => filterGroup.group_id !== groupId);
        return { filterGroups }
    }),

    addFilterGroup: () => set(state => {
        const maxGroupId = Math.max(...state.filterGroups.map(group => group.group_id));
        const newFilterGroup: AdvancedFilterGroupComponent = {
            group_id: maxGroupId + 1,
            logic_operator: 'NOOP',
            filters: [{
                filter_id: 1,
                field: filterOptions[0].value,
                operator: filterOptions[0].operatorOptions[0],
                value: ""
            }]
        }
        return {
            filterGroups: [...state.filterGroups, newFilterGroup]
        }
    }),
    updateLogicOperatorForGroup: (groupId: number, logicOperator: 'AND' | 'OR') => set(state => {
        const newGroups = state.filterGroups.map(group => {
            if (group.group_id === groupId) {
                return {
                    ...group,
                    logic_operator: logicOperator
                };
            }
            return group;
        })
        return { filterGroups: newGroups }
    }),
    addFilter: (groupId: number) => set(state => {
        const newGroups = state.filterGroups.map(group => {
            if (group.group_id === groupId) {
                const maxFilterId = Math.max(...group.filters.map(filter => filter.filter_id), 0);
                const newFilter: AdvancedFilterComponent = {
                    filter_id: maxFilterId + 1,
                    field: filterOptions[0].value,
                    operator: filterOptions[0].operatorOptions[0],
                    value: ""
                }
                return {
                    ...group,
                    logic_operator: group.logic_operator === 'NOOP' ? 'AND' : group.logic_operator, // Only update if logic_operator is NOOP
                    filters: [...group.filters, newFilter]
                };
            }
            return group;
        })
        return { filterGroups: newGroups }
    }),
    removeFilter: (groupId: number, filterId: number) => set(state => {
        let newGroups = state.filterGroups.map(group => {
            if (group.group_id === groupId) {
                const filters = group.filters.filter(filter => filter.filter_id !== filterId);
                return {
                    ...group,
                    filters
                };
            }
            return group;
        })
        const currentGroup=newGroups.find(g => g.group_id === groupId);
        if(!currentGroup?.filters?.length || currentGroup?.filters?.length === 0){
        newGroups=state.filterGroups.filter(group => group.group_id !== groupId);
        }
        return { filterGroups: newGroups }
    }),
    updateFilter: (groupId: number, updatedFilter: AdvancedFilterComponent) => set(state => {
        const newGroups = state.filterGroups.map(group => {
            if (group.group_id === groupId) {
                const newFilters = group.filters.map(filter => {
                    if (filter.filter_id === updatedFilter.filter_id) {
                        return updatedFilter;
                    }
                    return filter;
                })

                return {
                    ...group,
                    filters: newFilters
                }
            }
            return group;
        })
        return { filterGroups: newGroups }
    })

}))