import axios from 'axios';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;

export async function createNewProject(project_name: string, jwt_token: any) {
    const response = await axios.post(`${server_url}/project`, {
        project_name: project_name,
        is_deleted:false,
    },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })


    return response.data;
    
}

export async function createOrder(project_id: number, cohort_id: number, jwt_token: any) {
    const response = await axios.get(`${server_url}/cohort/export`, {
        params: {
            cohort_id: cohort_id,
            project_id: project_id,
        }, 
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${jwt_token}`
        }
    })

    return response.data;
}
export async function updateProjectName(jwt_token: any, project_id: number, editedName: any) {
    const response = await axios.put(`${server_url}/project?id=${project_id}`,
        editedName, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    });

    return response.data;
}