import { create } from "zustand";

interface NewsModalStore {
  releaseDate: string;
  setReleaseDate: (releaseDate: string) => void;
  isNewsOpen: boolean;
  setIsNewsOpen:(isNewsOpen: boolean) => void;
  newsContent: string[]
  setNewsContent: (newsContent:string[]) => void;
}

export const useNewsModalStore = create<NewsModalStore>()((set) => ({
  releaseDate:'',
  setReleaseDate: (releaseDate:string)=> set({releaseDate}),
  isNewsOpen: false,
  setIsNewsOpen: (isNewsOpen: boolean)=> set({isNewsOpen}),
  newsContent:[],
  setNewsContent: (newsContent: string[])=> set({newsContent}),
}))