import {useClearAllFilters}  from '../../utils/clearFilter';

const ClearAllFiltersButton =()=>{
  const {clearFilter} = useClearAllFilters();

  return(
    <div className="flex flex-row justify-between py-2">
        <button
            type="button"
            onClick={clearFilter}
            className="rounded bg-white px-2 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300  text-center  text-red-600 hover:text-red-800  "
        >
          Clear All Filters
        </button>     
    </div>
  )
}
export default ClearAllFiltersButton;