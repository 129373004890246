import axios from 'axios';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;

export async function bulkAddToCohort(
    cohort_id: number, 
    project_id: number, 
    studies: any[],
    jwt_token: string) {
    const response = await axios.post(`${server_url}/cohort/add_public`, studies, {
        params: {
            cohort_id: cohort_id,
            project_id: project_id
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response.data;
}

export async function addToCohort(
    cohort_id: number, 
    project_id: number, 
    row_id: string,
    institution_name: string,
    jwt_token: string) {
    const response = await axios.post(`${server_url}/cohort/add_public`, {
        row_id: row_id,
        institution_name: institution_name
    }, {
        params: {
            cohort_id: cohort_id,
            project_id: project_id
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })
    return response.data;
}

export async function removeFromCohort(
    cohort_id: number, 
    project_id: number, 
    row_id: string,
    institution_name: string,
    jwt_token: string) {
    const response = await axios.delete(`${server_url}/cohort/remove_public`, {
        params: {
            cohort_id: cohort_id,
            project_id: project_id,
            row_id: row_id,
            institution_name: institution_name
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}

export async function requestStudiesInCohort(email:string, cohort_id: number, pageSize:number, offset: number,project_id: number,jwt_token: string, config = {}) {
    const response = await axios.get(`${server_url}/cohort_public`, {
        ...config,
        params: {
            'cohort_id': cohort_id,
            'project_id': project_id,
            'email': email,
            'query_params': JSON.stringify({
            'pagination': {
                'pageSize': pageSize,
                'offset': offset
             }
            })
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}
export async function requestStudiesCountInCohort(email:string,project_id: number, cohort_id: number, jwt_token: string) {
    const response = await axios.get(`${server_url}/cohort_count`, {
        params: {
            'email': email,
            'cohort_id': cohort_id,
            'project_id': project_id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}
export async function requestCohortChartsData(email:string,project_id: number, cohort_id: number,chart_fields: string[],jwt_token: string) {
    const response = await axios.get(`${server_url}/cohort_charts_data`, {
        params: {
            'email': email,
            'chart_fields': JSON.stringify({ chart_fields }),
            'cohort_id': cohort_id,
            'project_id': project_id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}

export async function downloadStudiesInCohort(
  email:string,
  cohort_id: number,
  project_id: number,
  jwt_token: string,
  config = {}
) {
  const requestData = {
    email,
    cohort_id,
    project_id,
  };

  const response = await axios.post(
    `${server_url}/download_cohort_studies`,
    JSON.stringify(requestData),
    {
      ...config,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );

  return response.data;
}

export async function removeBulkStudiesFromCohort(
    cohort_id: number, 
    project_id: number, 
    jwt_token: string) {
    const response = await axios.delete(`${server_url}/cohort/remove_bulk`, {
        params: {
            cohort_id: cohort_id,
            project_id: project_id,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}