import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useModalStore } from '../../store/modalStore'
import { useAuthStore } from '../../store/authStore'
import { useState } from 'react'
import { useUserStore } from '../../store/userStore'
import { deleteProject } from '../../utils/request-project'
import { useToastStore } from '../../store/toastStore'
import { projectExportedWarningDeleteMessage, projectExportInitiatedWarningDeleteMessage, successfulProjectDeletionMessage } from '../common/Constants'

interface WarningMessage {
    show: boolean;
    warningMessage: string;
}

export default function DeleteProjectModal() {

    // Auth State
    const token = useAuthStore(state => state.token);

    //model store
    const showDeleteProjectModal = useModalStore(state => state.showDeleteProjectModal);
    const setShowDeleteProjectModal = useModalStore(state => state.setShowDeleteProjectModal);

    //project store
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;

    // user store
    const selectedProject = useUserStore(state => state.selectedProject);
    const refreshUser = useUserStore(state => state.refreshUser);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const projects = useUserStore(state => state.projects);

    // Toast Store
    const setShowCustomToast = useToastStore(state => state.setShowCustomToast);
    const setToasterCreationMessage = useToastStore(state => state.setToasterCreationMessage);

    // Local State
    const [isLoading, setIsLoading] = useState(false);
    const [textForDeleteProject, setTextForDeleteProject] = useState<string>('');
    const [warningMessage, setWarningMessage] = useState<WarningMessage>({ show: false, warningMessage: '' });

    const handleDeleteProject = async () => {
        try {
            if (token && selectedProject) {
                setIsLoading(true);
                const response = await deleteProject(selectedProject?.id, selectedProject?.cohort?.id, token);
                if (response.status === 200) {
                    await refreshUser(token);
                    setSelectedProjectById(projects?.[0]?.id);
                    setIsLoading(false);
                    setShowDeleteProjectModal(false);
                    setTextForDeleteProject('');
                    setShowCustomToast(true);
                    setToasterCreationMessage(successfulProjectDeletionMessage);
                }
            }
        } catch (err) {
            console.log(err);
            setShowDeleteProjectModal(false);
            setTextForDeleteProject('');
        }
    }

    useEffect(() => {
        setTextForDeleteProject('');
        if (downloadStatus === 'INITIATED') {
            setWarningMessage(projectExportInitiatedWarningDeleteMessage);
        }
        else if (downloadStatus === 'CLOSED') {
            setWarningMessage(projectExportedWarningDeleteMessage);
        }
        return () => setWarningMessage({ show: false, warningMessage: '' });
    }, []);

    const handleClose = () => {
        setShowDeleteProjectModal(false);
        setTextForDeleteProject('');
    }

    return (
        <Transition.Root show={showDeleteProjectModal} as={Fragment}>
            <Dialog as="div" className="relative z-100" onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={handleClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="font-bold leading-6 text-gray-900 text-2xl whitespace-nowrap">Sure you want to delete ?</h3>
                                    <p className='text-sm text-gray-500 mt-2'>
                                        You're about to delete your project.Deleted records can't be restored.
                                    </p>
                                    <form className="mt-5 sm:flex sm:items-center flex-col" onSubmit={handleDeleteProject}>
                                        <div className="w-full sm:max-w-xs">
                                            <p className='whitespace-nowrap font-semibold'>Type "delete" to delete this project</p>
                                            <input
                                                type="text"
                                                disabled={downloadStatus !== "OPEN" || isLoading}
                                                name="projectName"
                                                id="projectName"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:text-gray-500 disabled:bg-gray-100 disabled:border-gray-200 disabled:shadow-none mt-2"
                                                value={textForDeleteProject}
                                                placeholder='Type " delete " '
                                                onChange={(e) => setTextForDeleteProject(e.target.value)}
                                            />
                                        </div>
                                        <div className="pointer-events-none flex items-center mt-2">
                                            {warningMessage?.show && (<><ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                <p className="text-sm text-red-600 ml-2" id="email-error">
                                                    {warningMessage?.warningMessage}
                                                </p></>)}
                                        </div>
                                        <div className='flex mt-5 w-full'>
                                            <button
                                                disabled={textForDeleteProject.toLowerCase() !== "delete" || downloadStatus !== "OPEN"}
                                                type='button'
                                                onClick={handleDeleteProject}
                                                className={`mt-3 inline-flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-24  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed ${isLoading ? 'text-white bg-red-600 cursor-not-allowed pointer-events-none': ''}`}
                                            >
                                                {isLoading ? "Deleting.." : "Delete"}
                                            </button>
                                            <button
                                                type='button'
                                                onClick={() => setShowDeleteProjectModal(false)}
                                                className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-24  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
