import React from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import cn from 'classnames';

type GradientSliderProps<T extends number | readonly number[]> = ReactSliderProps<T> & {
    updateFilter: (values: number[]) => void;
}

const RangeSlider = <T extends number | readonly number[]>(props: GradientSliderProps<T>) => {
    return (
        <ReactSlider
            {...props}
            onAfterChange={(value) => props.updateFilter(value as number[])}
            renderThumb={(props, state) => (
                <div
                    {...props}
                    className={cn({
                        "h-full": true,
                        "aspect-square rounded-full bg-blue-500 text-xs text-white flex items-center justify-center cursor-grab": true
                    })}
                >
                    {state.valueNow}
                </div>  
            )}
            renderTrack={(props, state) => {
                const points = Array.isArray(state.value) ? state.value.length: null;
                const isMulti = points && points > 0;
                const isLast = isMulti ? state.index === points: state.index === 1;
                const isFirst = state.index === 0;
                return (
                    <div
                        {...props}
                        className={cn({
                            "h-1/4 top-1/2 -translate-y-1/2" : true,
                            "w-1/4 left-1/2 -translate-x-1/2": false,
                            "rounded-full": true,
                            "bg-gray-200": isMulti ? isFirst || isLast : isLast,
                            "bg-blue-500": isMulti ? !isFirst || !isLast : isFirst,
                        })}
                    />
                )
            }}
            className='w-full h-full z-0'
        />
    )

}


export default RangeSlider;