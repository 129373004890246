import { useEffect, useState } from 'react';
import { useProjectsStore } from '../../../store/projectsStore';
import CustomSelect from '../../../components/common/CustomSelect';
interface SelectedPageSize{
    selectedPageSize: number;
}

export default function ProjectsPagination(props:SelectedPageSize) {
    const selectedPageSize = props?.selectedPageSize;

    const projectPaginationOffset = useProjectsStore(state => state.projectPaginationOffset);
    const setProjectPaginationOffset = useProjectsStore(state => state.setProjectPaginationOffset);
    const studiesCountInCohort = useProjectsStore(state => state.studiesCountInCohort);

    const [ currentPage, setCurrentPage ] = useState<number>(projectPaginationOffset.offset + 1);
    const [ maxPageLimit, setMaxPageLimit ] = useState<number>(0);

    useEffect(() => {
        if(studiesCountInCohort) {
            setMaxPageLimit(Math.ceil(studiesCountInCohort / selectedPageSize));
        }
    }, [studiesCountInCohort,selectedPageSize])

    const handleNextPage = () => {
        if (currentPage < maxPageLimit) {
            setProjectPaginationOffset(projectPaginationOffset.offset + 1);
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePreviousPage = () => {
        if(projectPaginationOffset.offset > 0) {
            setProjectPaginationOffset(projectPaginationOffset.offset - 1);
            setCurrentPage(currentPage - 1);
        }
    }

    const handlePageNumber = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPage = Number(event.target.value);
        setProjectPaginationOffset(selectedPage - 1);
        setCurrentPage(selectedPage);
    }

    useEffect(() => {
        setCurrentPage(projectPaginationOffset.offset + 1);
    }, [projectPaginationOffset.offset]);

    const options = Array.from({ length:  studiesCountInCohort && studiesCountInCohort > 0 ? maxPageLimit : 0 }, (_, index) => index + 1);

    return (
        <div className="sticky bottom-0 right-0">
            <nav aria-label="Page navigation example">
                <ul className="flex items-center gap-1">
                    <li>
                        <button 
                            onClick={handlePreviousPage}
                            disabled={projectPaginationOffset.offset === 0}
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">
                            <span className="sr-only">Previous</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <CustomSelect 
                            options={options} 
                            currentPage={currentPage} 
                            handleChange={handlePageNumber} />
                    </li>
                    <li>
                        <button 
                            onClick={handleNextPage}
                            disabled={projectPaginationOffset.offset === maxPageLimit - 1}
                            className="disabled:cursor-not-allowed flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span className="sr-only">Next</span>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>

        </div>
    )
}