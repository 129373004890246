import { InformationCircleIcon } from "@heroicons/react/24/outline"

export default function NoResultsCatalog() {
    return (
        <div className="flex flex-col items-center justify-center h-full mt-32">
            <InformationCircleIcon className="h-12 w-12 mx-auto text-gray-700" />
            <h2 className="text-xl font-semibold text-gray-700 mb-4">No Results Found</h2>
            <p className="text-gray-500">No results were found with those search parameters. Please try again with different parameters.</p>
        </div>
    )
}

