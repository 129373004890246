import { Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useToastStore } from '../../store/toastStore'

export default function ProjectCreationToast() {
  // Toast Store
  const showCustomToast = useToastStore(state => state.showCustomToast);
  const setShowCustomToast = useToastStore(state => state.setShowCustomToast)
  const ToasterCreationMessage = useToastStore(state => state.ToasterCreationMessage);

  useEffect(() => {
    const TimeOut = ToasterCreationMessage?.type === 'warning' ? 8000 : 3000;
    if (showCustomToast) {
      setTimeout(() => {
        setShowCustomToast(false)
      }, TimeOut)
    }
  })

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-999 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-16"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showCustomToast}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex">
                  <div className="flex justify-between w-full">
                    <div className='flex'>
                      {ToasterCreationMessage?.type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
                      {ToasterCreationMessage?.type === 'info' && <ExclamationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />}
                      {ToasterCreationMessage?.type === 'warning' && <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
                      <p className="text-sm font-medium text-gray-900 ml-1 mt-0.5">{ToasterCreationMessage?.headerMessage}</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShowCustomToast(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full flex-1 mt-1 ml-1">
                  <p className="mt-1 text-sm text-gray-500">{ToasterCreationMessage?.bodyMessage}</p>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
