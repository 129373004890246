import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import cn from 'classnames';



type LogicDropdownProps = {
  setLogic: (logic: any) => void;
  selectedFilter: any;
  operator: string;
  id?: number;
}

function capitalizeFirstLetterEachWord(str: string): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export default function LogicDropdown({
  setLogic,
  selectedFilter,
  operator,
  id
}: LogicDropdownProps) {
  console.log(operator, id);


  
  const [selected, setSelected] = useState(operator || selectedFilter.operatorOptions[0])

  const handleSelect = (selected: any) => {
    setSelected(selected)
    setLogic(selected)
  }

  useEffect(() => {
    setSelected(operator || selectedFilter.operatorOptions[0]);
    setLogic(operator || selectedFilter.operatorOptions[0]);
  }, [selectedFilter.type])

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <>
          <div>
            <Listbox.Button className="relative w-4/9 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-1/2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {selectedFilter.operatorOptions.map((option: string) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-blue-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={cn(selected ? 'font-semibold' : 'font-normal', 'block')}>
                          {capitalizeFirstLetterEachWord(option)}
                        </span>

                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
