import React from "react";
import PieChart from "./common/PieChart";
import { useChartsStore } from "../../../../../store/chartsStore";
import { addOtherValueToChartData } from "../../../../../utils/data_formatting";
export default function SeriesDescriptionChart() {
  // Charts Store
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { series_description: seriesDescriptionData } = cohortsChartsData;

  const sortedEntries = Object.entries(seriesDescriptionData || {}).sort(([, valueA], [, valueB]) => Number(valueB) - Number(valueA));
  const otherIndex = sortedEntries.findIndex(([key]) => key === "Other");
  if (otherIndex !== -1 && otherIndex < 10) {
    sortedEntries.splice(otherIndex, 1);
  }
  const filteredSeriesDescriptionData = sortedEntries.slice(0, 10).reduce((obj, [key, value]) => {
        obj[key] = Number(value);
    return obj;
  }, {} as { [key: string]: number });

  addOtherValueToChartData(sortedEntries as [string, number][], filteredSeriesDescriptionData);

  return (
    <PieChart
      data={filteredSeriesDescriptionData}
      colors={["#A78BFA", "#F472B6", "#10B981", "#6366F1", "#F59E0B", "#E11D48", "#84CC16", "#3B82F6", "#EC4899", "#22D3EE", "#0000FF"]}
      chartId="series-description-pie-chart"
    />
  );
}
