import { SearchRequest, AdvancedSearchRequest } from "../types/search";
import axios from 'axios';
import { manufacturerSelectionsDefault } from "../components/filterControls/manufacturerFilter/manufacturerOptions";
import { modalityOptionsDefault } from "../components/filterControls/modalityFilter/modalityOptions";

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;


export async function getStudies(search: SearchRequest, jwt_token: string, config = {}) {
    const response = await axios.post(`${server_url}/search_public`, 
             JSON.stringify(search)
        ,{
            ...config,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt_token}`
            }
        })

    return response.data;
}


export async function getStudiesWithDownloadLimit(search: SearchRequest, jwt_token: string,strategy:string, config = {}) {
    const requestData = {
        ...search,
        strategy:strategy
    };
    const response = await axios.post(`${server_url}/study_download_limit`, 
             JSON.stringify(requestData)
        ,{
            ...config,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt_token}`
            }
        })
        return response;
}
export async function getPatientsWithDownloadLimit(search: SearchRequest, jwt_token: string, strategy: string, config = {}) {
  const requestData = {
    ...search,
    strategy: strategy
  };
  const response = await axios.post(`${server_url}/download_patient_result`, JSON.stringify(requestData), {
    ...config,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt_token}`
    }
  });
  return response;
}

export async function getLongitudinalStudies(search: any[], selectedPageSize:number, offset: number,timeBetween: number[],sortArray:any[], project_id: number,jwt_token: string, config = {}) {
    const response = await axios.get(`${server_url}/search_public_longitudinal`, {
        ...config,
        params: {
            'json': JSON.stringify({
                "filters": search,
                'pagination': {
                    'pageSize': selectedPageSize,
                    'offset': offset
                },
                'timeBetween': timeBetween,
                'sort': sortArray,
                'project_id' : project_id,
            }) // encode the json string to be passed as a query param to the sea
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}

export async function getBulkLongitudinalStudies(search: any[], offset: number, pageSize: number, jwt_token: string, config = {}) {
    const response = await axios.get(`${server_url}/search_public_longitudinal`, {
        ...config,
        params: {
            'json': JSON.stringify({
                "filters": search,
                'pagination': {
                    'pageSize': pageSize,
                    'offset': offset
                }
            }) // encode the json string to be passed as a query param to the sea
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}

// TODO: Rather make a request for each individual thumbnail, 
// we should probably batch the request to a set of thumbnails as an array.
export async function signThumbnail(thumbnail_uri: string, jwt_token: string) {
    const response = await axios.post(`${server_url}/thumbnail/sign`, {
        thumbnail_uri: thumbnail_uri
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        },
    });

    if (response.status === 429) {
        throw new Error("Too Many Requests: 429");
    }

    return response;
}


export async function getPatientStudyCount(search: any[], offset: number, timeBetween: number[], jwt_token: string, config = {}) {
    const response = await axios.get(`${server_url}/count_public_longitudinal`, {
        ...config,
        params: {
            'json': JSON.stringify({
                "filters": search,
                'pagination': {
                    'pageSize': 10,
                    'offset': offset
                },
                timeBetween
            }) // encode the json string to be passed as a query param to the sea
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).catch((err: any) => {
        console.log(err)
    });

    if (response) {
        return response.data;
    } else {
        return 
    } 
}



export async function getStudyCount(searchRequest: SearchRequest, jwt_token: string, config = {}) {
    const response = await axios.post(`${server_url}/count_public`, 
    JSON.stringify(searchRequest),
    {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).catch((err: any) => {
        console.log(err)
    });

    if (response) {
        return response.data;
    } else {
        return 
    }
}

export async function getPatientStudies(patientId: string, jwt_token: string) {
    const patientIdOnlySearchRequest = {
        pagination: {
            pageSize: 100,
            offset: 0
        },
        advancedFilters: {
            operator: 'NOOP',
            value: [
                {
                    operator: 'NOOP',
                    value: [
                        {
                            "field": "patient_id",
                            "operator": "EQUALS",
                            "value": patientId
                        }
                    ]
                }
            ]
        }
    }

    const response = await axios.post(`${server_url}/search_public`, 
        JSON.stringify(patientIdOnlySearchRequest)
        ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt_token}`
            }
        })

    return response.data;
}
export async function getStudyCountByGroupingField(groupingField: string, jwt_token: string, crossFilteringParameter: string[], crossFilter: boolean, searchRequest:any, default_list:any, config={}
){
    const response = await axios.get(`${server_url}/count_study`, {
        ...config,
        params: {
            'json': JSON.stringify({
                "grouping_field": groupingField,
                cross_filtering_parameter: crossFilteringParameter,
                cross_filter_only:crossFilter,
                searchRequest: searchRequest,
                default_list
            }) // encode the json string to be passed as a query param to the search
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    });

    return response.data;  
}

export async function getCsvDownloadLimit(jwt_token: string) {
    const response = await axios.get(`${server_url}/organization/csv_limit`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt_token}`
      }
    });
    return response;
  }

export async function getPatientExamsResults(search: SearchRequest, jwt_token: string, config = {}) {
    const response = await axios.get(`${server_url}/search_public_longitudinal_exams_highlight`, {
        ...config,
        params: {
            'json': JSON.stringify({
                ...search
            }) // encode the json string to be passed as a query param to the search
        },
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt_token}`
    }
    });
    return response;
}