import { create } from 'zustand';

interface ProjectsStore {
savedSearchList: any;
setSavedSearchList: (savedSearchList: any) => void;
updateSavedSearchList: (savedSearchList: any) => void;
savedSearchPaginationOffset: number;
setSavedSearchPaginationOffset: (savedSearchPaginationOffset: number) => void;
showSavedSearchTab: boolean;
setShowSavedSearchTab: (showSavedSearchTab: boolean) => void;
loader: boolean;
setLoader: (loader: boolean) => void;
isSearchChanged: boolean;
setIsSearchChanged: (loader: boolean) => void;
}

export const useSavedSearchStore = create<ProjectsStore>()((set) => ({
    savedSearchList: [],
    setSavedSearchList: (savedSearchList) => set({ savedSearchList }),
    updateSavedSearchList: (savedSearchList) => set((state)=>{
        return {
            savedSearchList: [...state.savedSearchList, savedSearchList]
        }
    }),
    savedSearchPaginationOffset:0,
    setSavedSearchPaginationOffset: (savedSearchPaginationOffset:number) => set({ savedSearchPaginationOffset }),
        showSavedSearchTab: false,
    setShowSavedSearchTab: (showSavedSearchTab: boolean) =>set({showSavedSearchTab}),
    loader: false,
    setLoader: (loader: boolean) => set({loader}),
    isSearchChanged: false,
    setIsSearchChanged: (search) => set({ isSearchChanged: search })
}));

